import React, { Fragment, useState, useEffect } from "react";
import "./CutBox.css";
import NavBar from '../../components/navBar/NavBar';
import Asidebar from '../../components/asidebar/Asidebar';
import Footer from '../../components/footer/Footer';
import axios from 'axios';
import { saveAs } from 'file-saver';
import swal from 'sweetalert';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import moment from "moment-timezone";



const cookies = new Cookies();


const CutBox = () => {

    const baseUrl = window.$baseUrl;

    let correo = cookies.get('correo');
    let id_sucural = cookies.get('id_sucursal');
    let id_usuario = cookies.get('id_usuario');
    let permisos = cookies.get('id_permiso_usuario');
    let nombreperfil = cookies.get('nombre_completo_usuario') || "user";
    let primernombre = nombreperfil.split(" ", 1);

    // console.log("permisos", permisos !== "628c0a94718beafc1c5ca269")

    const [menu, setMenu] = useState(true);
    const [cargando, setCargando] = useState(false);
    const [inicial, setInicial] = useState(null);
    const [final, setFinal] = useState(null);




    const abrirmenu = () => {
        setMenu(!menu);

    }


    const styleagrandardashboard = {
        transitionDuration: "0.3s"
    };

    const stylencogerdashboard = {};

    // const [existUser, setExistUser] = useState([]);

    // const comprobarUser = async () => {
    //     await axios.get(baseUrl + "/api/usuarios/tipou/" + correo)
    //         .then(response => {
    //             setExistUser(response.data);
    //         }).catch(error => {
    //             console.log(error);
    //         })
    // }


    const abrirCaja = () => {

        swal({
            title: "¿Abrir caja?",
            text: "¿Estás seguro(a) que deseas iniciar caja?",
            icon: "warning",
            buttons: ["Cancelar", "Si, ¡abrir la caja!"],
            dangerMode: true,
        })
            .then((willDelete) => {


                if (willDelete) {

                    swal({
                        title: "Abrir caja",
                        text: "Ingresa dinero para iniciar caja",
                        content: "input",
                        buttons: ["Cancelar", "Aceptar"],
                        dangerMode: true,
                    })
                        .then((value) => {


                            let ingreso = value;

                            if (ingreso > 0) {
                                swal("¡El corte de caja se ha iniciado correctamente!", {
                                    icon: "success",
                                    button: "Finalizar",
                                    dangerMode: true,
                                })


                                peticionPost(ingreso);


                            } else swal("¡No se pudo iniciar caja!", {
                                icon: "error",
                                button: "Aceptar",
                                dangerMode: true,
                            })
                        })
                } else swal("¡El inicio de caja fue cancelado!", {
                    icon: "error",
                    button: "Aceptar",
                    dangerMode: true,
                });
            })


    }


    const peticionPost = async (ingreso) => {



        let HoraActual = moment().tz('America/Merida').format();

        try {


            await axios.post(baseUrl + "/api/corte/new", {

                inicio_caja: parseInt(ingreso),
                total_efectivo: 0,
                diferencia: 0,
                total_venta_periodo: 0,
                fecha_venta_inicio_caja: HoraActual,
                user: id_usuario,
                estado: "Activo",
                sucursal: id_sucural,
                status: true

            }).then(response => {

                console.log(response);
                obtenerCorteCaja();

                peticionGet();


            }).catch(error => {

                console.log(error);
            })

            obtenerCorteCaja();

            peticionGet();
        } catch (e) {
            console.log(e)
        }


    }


    const [data, setData] = useState([]);

    const peticionGet = async () => {

        setInicial(null)
        setFinal(null)
        // let diaActual = moment().tz('America/Merida').format();

        try {
            setCargando(true)
            await axios.get(baseUrl + "/api/corte/email/" + correo)
                .then(response => {
                    // console.log(response.data)
                    setCargando(false)
                    setData(response.data);
                }).catch(error => {
                    setCargando(false)
                    console.log(error);
                })
        } catch (e) {
            setCargando(false)
            console.log(e)
        }


    }



    const peticionGetxDia = async () => {

        if (inicial === null || final === null) {


        } else {
            try {
                setCargando(true)
                await axios.get(baseUrl + "/api/corte/email/dia/" + correo + "/" + inicial + "/" + final)
                    .then(response => {
                        // console.log(response.data)
                        setCargando(false)
                        setData(response.data);
                    }).catch(error => {
                        setCargando(false)
                        console.log(error);
                    })
            } catch (e) {
                setCargando(false)
                console.log(e)
            }
        }

    }



    useEffect(() => {
        // comprobarUser();
        peticionGet();
        obtenerCorteCaja();
        obtenerDatosUsuario();
    }, [])



    const [corteCajaSeleccionado, setCorteCajaSeleccionado] = useState({
        _id: "",
        id_corte_caja: "",
        inicio_caja: "",
        total_venta_periodo: "",
        fecha_venta_inicio_caja: "",
        fecha_venta_cierre_caja: "",
        id_usuario: "",
        estado: "",
        status: ""
    });


    const obtenerCorteCaja = async () => {

        try {

            await axios.get(baseUrl + "/api/corte/corr/" + correo)
                .then(response => {

                    console.log(response.data)
                    setCorteCajaSeleccionado(response.data);
                    obtenerDatosReporte(response.data);
                }).catch(error => {

                    console.log(error);
                })

        } catch (e) {

            console.log(e)
        }

    }

    // console.log(corteCajaSeleccionado)

    const [datosReporte, setDatosReporte] = useState([]);


    const obtenerDatosReporte = async (datosCorte) => {
        if (Object.keys(datosCorte).length >= 1) {
            await axios.get(baseUrl + "/api/venta/data/report/" + id_sucural + "/" + id_usuario + "/" + datosCorte._id)
                .then(response => {

                    setDatosReporte(response.data);
                }).catch(error => {
                    console.log(error);
                })
        } else {

        }

    }

    // console.log(datosReporte)

    const peticionPut = async (ingreso, resultado) => {


        let HoraActual = moment().tz('America/Merida').format();

        try {
            await axios.put(baseUrl + "/api/corte/" + corteCajaSeleccionado._id, {
                _id: corteCajaSeleccionado._id,
                inicio_caja: corteCajaSeleccionado.inicio_caja,
                total_efectivo: parseInt(ingreso),
                diferencia: parseInt(resultado),
                total_venta_periodo: corteCajaSeleccionado.total_venta_periodo,
                fecha_venta_inicio_caja: corteCajaSeleccionado.fecha_venta_inicio_caja,
                fecha_venta_cierre_caja: HoraActual,
                user: corteCajaSeleccionado.id_usuario,
                estado: "Cerrado"
            })
                .then(response => {

                    // console.log("datosReporte")
                    // console.log(datosReporte)
                    // console.log("datosReporte")

                    try {
                        axios.post(baseUrl + '/api/reporte/new/' + datosUsuario.nombre_completo_usuario + "/" + datosUsuario.sucursal.name + "/" + datosUsuario.sucursal.direccion + "/" + datosUsuario.sucursal.telefono_usuario + "/" + resultado + "/" + ingreso + "/" + corteCajaSeleccionado.inicio_caja, datosReporte)
                            .then(() => axios.get(baseUrl + '/api/reporte/ventas', { responseType: 'blob' }))
                            .then((res) => {
                                const pdfBlob = new Blob([res.data], { type: 'application/pdf' });

                                saveAs(pdfBlob, 'Reporte.pdf');
                            })
                    } catch (e) {
                        console.log(e)
                    }

                })
                .catch(error => {
                    console.log(error);
                });

            obtenerCorteCaja();
            peticionGet();
            // peticionGet();


        } catch (e) {
            console.log(e)
        }
        obtenerCorteCaja();
    }

    const [datosUsuario, setDatosUsuario] = useState({});

    const obtenerDatosUsuario = async () => {
        try {
            await axios.get(baseUrl + "/api/user/" + id_usuario)
                .then(response => {
                    setDatosUsuario(response.data);
                }).catch(error => {
                    console.log(error);
                })
        } catch (e) {
            console.log(e)
        }
    }




    const cCaja = () => {
        swal({
            title: "¿Estás seguro(a) de cerrar la caja?",
            text: "¡Si no lo está puede cancelar la accíón!",
            icon: "warning",
            buttons: ["Cancelar", "Si, ¡cerrar la caja!"],
            dangerMode: true,
        })
            .then((willDelete) => {


                if (willDelete) {

                    swal({
                        title: `Corte de caja`,
                        text: `Base inicial: ${corteCajaSeleccionado.inicio_caja} \n \n Ingresa la cantidad de efectivo en caja (sin contar tu base inicial)`,
                        content: "input",
                        button: "Aceptar",
                        dangerMode: true,
                    })
                        .then((value) => {
                            swal({
                                title: "Verificación",
                                text: `Ingresaste: $${value}, ¿Es correcto?`,
                                icon: "warning",
                                buttons: ["No, cambiar cantidad", "Si, ¡continuar!"],
                                dangerMode: true,
                            })
                                .then((willDelete) => {

                                    if (willDelete) {

                                        // console.log(value)

                                        let ingreso = value;
                                        let ventacorte = corteCajaSeleccionado.total_venta_periodo;

                                        let resultado = ingreso - ventacorte;

                                        if (resultado < 0) {
                                            swal(`Hizo falta: $${Math.abs(resultado)} pesos`, {
                                                icon: "error",
                                                button: "Aceptar",
                                                dangerMode: true,
                                            }).then((willDelete) => {

                                                if (willDelete = true) {

                                                    peticionPut(ingreso, resultado)

                                                    swal("¡El corte de caja se ha hecho correctamente!", {
                                                        icon: "success",
                                                        button: "Aceptar",
                                                        dangerMode: true,
                                                    })


                                                }
                                            })

                                        } else if (resultado > 0) {
                                            swal(`Diferencia: $${resultado} pesos`, {
                                                icon: "info",
                                                button: "Aceptar",
                                                dangerMode: true,
                                            }).then((willDelete) => {
                                                if (willDelete = true) {

                                                    peticionPut(ingreso, resultado)

                                                    swal("¡El corte de caja se ha hecho correctamente!", {
                                                        icon: "success",
                                                        button: "Aceptar",
                                                        dangerMode: true,
                                                    })


                                                }
                                            })


                                        } else if (resultado === 0) {
                                            swal(`Todo bien`, {
                                                icon: "success",
                                                button: "Aceptar",
                                                dangerMode: true,
                                            })
                                                .then((willDelete) => {
                                                    if (willDelete = true) {

                                                        peticionPut(ingreso, resultado)
                                                        swal("¡El corte de caja se ha hecho correctamente!", {
                                                            icon: "success",
                                                            button: "Aceptar",
                                                            dangerMode: true,
                                                        })


                                                    }
                                                })

                                        }

                                    } else {

                                        swal(`Ingresa nuevamente la cantidad de dinero de la caja \n(sin contar tu base inicial: ${corteCajaSeleccionado.inicio_caja})`, {


                                            content: "input",
                                            button: "Aceptar",
                                            dangerMode: true,
                                        })

                                            .then((willDelete) => {

                                                if (willDelete) {

                                                    let ingreso = value;
                                                    let ventacorte = corteCajaSeleccionado.total_venta_periodo;

                                                    let resultado = ingreso - ventacorte;

                                                    if (resultado < 0) {
                                                        swal(`Hizo falta: $${Math.abs(resultado)} pesos`, {
                                                            icon: "error",
                                                            button: "Aceptar",
                                                            dangerMode: true,
                                                        }).then((willDelete) => {

                                                            if (willDelete = true) {

                                                                peticionPut(ingreso, resultado)


                                                                swal("¡El corte de caja se ha hecho correctamente!", {
                                                                    icon: "success",
                                                                    button: "Aceptar",
                                                                    dangerMode: true,
                                                                })


                                                            }
                                                        })



                                                    } else if (resultado > 0) {
                                                        swal(`Diferencia: $${resultado} pesos`, {
                                                            icon: "info",
                                                            button: "Aceptar",
                                                            dangerMode: true,
                                                        }).then((willDelete) => {
                                                            if (willDelete = true) {

                                                                peticionPut(ingreso, resultado)

                                                                swal("¡El corte de caja se ha hecho correctamente!", {
                                                                    icon: "success",
                                                                    button: "Aceptar",
                                                                    dangerMode: true,
                                                                })


                                                            }
                                                        })


                                                    } else if (resultado === 0) {
                                                        swal(`Todo bien`, {
                                                            icon: "success",
                                                            button: "Aceptar",
                                                            dangerMode: true,
                                                        })
                                                            .then((willDelete) => {
                                                                if (willDelete = true) {

                                                                    peticionPut(ingreso, resultado)

                                                                    swal("¡El corte de caja se ha hecho correctamente!", {
                                                                        icon: "success",
                                                                        button: "Aceptar",
                                                                        dangerMode: true,
                                                                    })


                                                                }
                                                            })

                                                    }





                                                } else {
                                                    swal("¡El corte de caja fue cancelado!", {
                                                        icon: "error",
                                                        button: "Aceptar",
                                                        dangerMode: true,
                                                    });

                                                }

                                            })


                                    }



                                })

                        })

                } else swal("¡El corte de caja fue cancelado!", {
                    icon: "error",
                    button: "Aceptar",
                    dangerMode: true,
                });
            })
    }


    const datos = data.length;

    const handleChangeInicial = (e) => {
        // console.log(e.target.value)
        setInicial(e.target.value)

    }

    const handleChangeFinal = (e) => {
        // console.log(e.target.value)
        setFinal(e.target.value)
    }

    useEffect(() => {
        if (inicial !== null || final !== null) {
            peticionGetxDia()
        }
    }, [inicial, final])

    return (

        <Fragment>

            {!cookies.get('correo') && <Redirect to="./" />}
            {permisos !== "628c0a94718beafc1c5ca269" && <Redirect to="./" />}

            <NavBar estado={menu} abrirmenu={abrirmenu} primernombre={primernombre} />

            <div className="fondo-cutbox">

                <Asidebar estado={menu} />

                <div style={menu ? styleagrandardashboard : stylencogerdashboard} className="content-cutbox-main">

                    <label>Administrar cortes de caja</label>


                    <div className="btn_closebox">
                        {Object.keys(corteCajaSeleccionado).length > 0 ?
                            <Fragment>
                                <button onClick={cCaja} id="btn_closebox" >Generar corte de caja</button>
                                {/* <button onClick={cCaja} id="btn_closebox-parls" >Generar corte parcial</button> */}
                            </Fragment>
                            :
                            <button onClick={abrirCaja} id="btn_openbox" >Iniciar caja</button>
                        }

                    </div>

                    <div className="btn_fecha_inicial_termino">
                        <div>
                            <p for="start">Dia Inicial:</p>

                            <input type="date" id="start" name="trip-start"
                                value={inicial === null ? "" : inicial}

                                onChange={handleChangeInicial}
                            />
                        </div>

                        <div>
                            <p for="end">Dia Final:</p>

                            <input type="date" id="end" name="trip-end"
                                value={final === null ? "" : final}
                                onChange={handleChangeFinal}
                            />
                        </div>

                    </div>
                    {(inicial !== null & final !== null) ?
                        <div className="btn_fecha_inicial_termino btn_fecha_inicial_termino_restablecer" style={{ marginTop: '40px' }}>
                            <h4 onClick={() => {
                                setInicial(null)
                                setFinal(null)
                                peticionGet();
                            }}>Restablecer</h4>
                        </div>
                        :
                        <></>
                    }

                    <div className="content-closebox-table">

                        {cargando ?
                            <div className="content-cargando">
                                <div className="lds-dual-ring"></div>
                            </div>
                            :
                            <table className="table">
                                <thead className="thead">
                                    <tr>
                                        {/* <th>#</th> */}
                                        <th>Responsable</th>
                                        <th>Inicio Caja</th>
                                        <th>Total Efectivo</th>
                                        {permisos !== "628c0a94718beafc1c5ca269" && <th>Total venta</th>}
                                        <th>Diferencia</th>
                                        <th>Fecha Inicio</th>
                                        <th>Fecha Cierre</th>
                                        <th>Estado</th>
                                    </tr>
                                </thead>

                                {datos.length < 0 ? (
                                    <tbody className="tbody">



                                    </tbody>
                                ) : (
                                    <tbody className="tbody">


                                        {data.map(cortecaja =>

                                            <tr id="id_cortecaja" key={cortecaja._id}>
                                                {/* <td id="id_closebox">{cortecaja._id}</td> */}
                                                <td id="nom_usuar">{cortecaja.user.nombre_completo_usuario}</td>
                                                <td>{cortecaja.inicio_caja}.00</td>
                                                <td>{cortecaja.total_efectivo}.00</td>
                                                {permisos !== "628c0a94718beafc1c5ca269" && <td>{cortecaja.total_venta_periodo}.00</td>}
                                                <td>{cortecaja.diferencia >= 1 ? "+" + cortecaja.diferencia : cortecaja.diferencia < 0 ? cortecaja.diferencia : "0"}.00</td>
                                                <td>{cortecaja.fecha_venta_inicio_caja ? moment(new Date(cortecaja.fecha_venta_inicio_caja)).format('YYYY-MM-DD HH:mm:ss A') : ""}</td>
                                                <td>{cortecaja.fecha_venta_cierre_caja ? moment(new Date(cortecaja.fecha_venta_cierre_caja)).format('YYYY-MM-DD HH:mm:ss A') : "-----------"}</td>
                                                {cortecaja.estado === "Activo" ?
                                                    <td id="estado_corte_activo">{cortecaja.estado}</td>
                                                    :
                                                    <td id="estado_corte_cerrado">{cortecaja.estado}</td>
                                                }

                                            </tr>
                                        )}
                                    </tbody>
                                )}
                            </table>
                        }

                    </div>
                    <br />

                    {`Mostrando registros del 1 al ${datos} de un total de ${datos}`}


                </div>

            </div>


            <Footer />

        </Fragment>

    )

}

export default CutBox;