import React,{Fragment} from 'react';
import  './Grafica.css';



const Grafic = () =>{

    // const data = {
    //     labels: ['usa', 'mexico', 'españa', 'colombia', 'italia'],
    //     datasets:[{
    //         label:'habitantes',
    //         backgroundColor:'rgba(0,255,0,1)',
    //         borderColor : 'black',
    //         borderWidth : 1,
    //         hoverBackgroundColor : 'rgba(0,255,0,0.2)',
    //         hoverBorderColor : '#FF0000',
    //         data: [327.16, 126.19, 60.64, 49.64, 46.72]
    //     }]
    // };

    // const opciones = {
    //     maintainAspectRatio: false,
    //     responsive: true
    // }

    return(
        <Fragment>
            {/* <div style={{width:'100%', height:'500px'}}>
                <h2>Poblacion en millones</h2>
                <Bar data={data} opciones={opciones} />
            </div> */}
            
        </Fragment>
    )
}

export default Grafic;