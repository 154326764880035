import React, { Fragment } from 'react';
import "./Asidebar.css";
import { NavLink } from "react-router-dom";
import Cookies from 'universal-cookie';
import dataAsidebaruser from "./data-asidebar-user.json";
import dataAsidebaradmin from "./data-asidebar-admin.json";
import dataAsidebarsuperadmin from "./data-asidebar-super-admin.json";
const cookies = new Cookies();

const Asidebar = ({ estado }) => {

  const stylemenuchico = {
    width: "50px"
  };

  const stylemenugrande = {
    display: "flex"
  };

  const stylemenueliminarh3 = {
    display: "none"
  };

  const stylemenuaparecerh3 = {
    background: "none"
  };

  return (
    <Fragment>

      <div style={estado ? stylemenuchico : stylemenugrande} className="content-navbar-left">
        {cookies.get('id_permiso_usuario') === "628c073747bc232d04b1b5f4" ?

          dataAsidebaradmin.map(item => {
            return (
              <ul key={item.id}>
                <NavLink title={item.title} to={item.to} activeClassName="class_active"> <i className={item.iconclass}></i>  <h3 style={estado ? stylemenueliminarh3 : stylemenuaparecerh3} className="content-navbar-left">{item.name}</h3> </NavLink>
              </ul>

            )
          })
          : cookies.get('id_permiso_usuario') === "628c0a94718beafc1c5ca269" ?

            dataAsidebaruser.map(item => {
              return (
                <ul key={item.id}>
                  <NavLink title={item.title} to={item.to} activeClassName="class_active"> <i className={item.iconclass}></i>  <h3 style={estado ? stylemenueliminarh3 : stylemenuaparecerh3} className="content-navbar-left">{item.name}</h3> </NavLink>
                </ul>
              )
            })

            : cookies.get('id_permiso_usuario') === "628c0ab8718beafc1c5ca26b" ?
              dataAsidebarsuperadmin.map(item => {
                return (
                  <ul key={item.id}>
                    <NavLink title={item.title} to={item.to} activeClassName="class_active"> <i className={item.iconclass}></i>  <h3 style={estado ? stylemenueliminarh3 : stylemenuaparecerh3} className="content-navbar-left">{item.name}</h3> </NavLink>
                  </ul>

                )
              })
              :
              ""
        }





      </div>
    </Fragment>
  );
}

export default Asidebar;