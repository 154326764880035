import React, { Fragment, useState, useEffect } from "react";
import './ModalUsuarios.css';
import axios from 'axios';
import Cookies from 'universal-cookie';


const cookies = new Cookies();

const ModalEditarUsuarios = ({ modalEditar, modalInsertar, peticionPut, peticionPost, seleccionarUsuario, usuarioSeleccionado, abrirCerrarModalEditar, abrirCerrarModalInsertar, handleChange }) => {

  const baseUrl = window.$baseUrl;
  let permisos = cookies.get('id_permiso_usuario');

  const peticionS = async () => {
    await axios.get(baseUrl + "/api/sucursal/")
      .then(response => {
        setSucursalSeleccionado(response.data);
      }).catch(error => {
        console.log(error);
      })
  }

  const peticionS_Root = async () => {
    await axios.get(baseUrl + "/api/sucursal/")
      .then(response => {
        setSucursalSeleccionadoRoot(response.data);
      }).catch(error => {
        console.log(error);
      })
  }

  const peticionC = async () => {
    await axios.get(baseUrl + "/api/cargo/")
      .then(response => {
        setCargoSeleccionado(response.data);
      }).catch(error => {
        console.log(error);
      })
  }

  const peticionP = async () => {
    await axios.get(baseUrl + "/api/rol/")
      .then(response => {
        setPermisoSeleccionado(response.data);
      }).catch(error => {
        console.log(error);
      })
  }

  const peticionP_Root = async () => {
    await axios.get(baseUrl + "/api/rol/")
      .then(response => {
        setPermisoSeleccionadoRoot(response.data);
      }).catch(error => {
        console.log(error);
      })
  }

  useEffect(() => {
    peticionS();
    peticionS_Root();
    peticionC();
    peticionP();
    peticionP_Root();
  }, [])

  const [sucursalSeleccionado, setSucursalSeleccionado] = useState([]);
  const [sucursalS, setSucursalS] = useState(false);

  const [cargoSeleccionado, setCargoSeleccionado] = useState([]);
  const [cargoS, setCargoS] = useState(false);

  const [permisoSeleccionado, setPermisoSeleccionado] = useState([]);
  const [permisoS, setPermisoS] = useState(false);


  const edad = usuarioSeleccionado && usuarioSeleccionado.sucursal.edad_usuario;
  const sucursal = usuarioSeleccionado && usuarioSeleccionado.sucursal.name;
  const cargo = usuarioSeleccionado && usuarioSeleccionado.cargo.name;
  const permiso = usuarioSeleccionado && usuarioSeleccionado.rol.name;

  const [permisoSeleccionadoRoot, setPermisoSeleccionadoRoot] = useState([]);
  const [sucursalSeleccionadoRoot, setSucursalSeleccionadoRoot] = useState([]);


  return (
    <Fragment>
      <div className={modalEditar ? "Modal-desactive" : "Modal-active"}>
        <div className="contend-modalU">
          <div className="ModalHeaderU">
            <h2>Editar Usuario</h2>
            <i className="fas fa-times" onClick={() => abrirCerrarModalEditar()} id="icon-close"></i>
          </div>
          <div className="ModalBodyU">
            <div className="FormGroupU">
              <form onSubmit={peticionPut} id="miFormU">

                {permisos !== "628c0ab8718beafc1c5ca26b" ?
                  ""
                  // <label htmlFor="id_sucursal">Sucursal</label>
                  :
                  <label htmlFor="id_sucursal">Sucursal</label>
                }

                {permisos !== "628c0ab8718beafc1c5ca26b" ?
                  ""
                  // <select onChange={handleChange} id="id_sucursal" required name="id_sucursal">
                  //   <option value="" defaultValue>Seleccione una opción</option>
                  //   {sucursalSeleccionado.map(items =>
                  //     <option key={items.id_sucursal} value={items.id_sucursal} >{items.nombre_sucursal}</option>
                  //   )}
                  // </select>
                  : sucursalS ?
                    <select onChange={handleChange} id="id_sucursal" required name="id_sucursal">
                      <option value="" defaultValue>Seleccione una opción</option>
                      {sucursalSeleccionadoRoot.map(items =>
                        <option key={items._id} value={items._id} >{items.name}</option>
                      )}
                    </select>
                    :
                    // <input onClick={() => setSucursalS(true)} onChange={handleChange} id="id_sucursal" name="id_sucursal" value={sucursal === 1 ? "Oxkutzcab" : sucursal === 2 ? "Akil (Matriz)" : sucursal === 3 ? "Akil (Suc. Avenida)" : sucursal === 4 ? "Tekax" : sucursal === 5 ? "Tzucacab" : "Peto"} type="text" />
                    <input onClick={() => setSucursalS(true)} onChange={handleChange} id="id_sucursal" name="id_sucursal" value={sucursal} required type="text" />

                }

                <label htmlFor="nombre_completo_usuario">Nombre completo</label>
                <input onChange={handleChange} name="nombre_completo_usuario" value={usuarioSeleccionado && usuarioSeleccionado.nombre_completo_usuario} required type="text" />

                <label htmlFor="edad_usuario">Fecha de nacimiento</label>
                <input onChange={handleChange} name="edad_usuario" value={usuarioSeleccionado.edad_usuario} type="date" />

                <label htmlFor="telefono_usuario">Teléfono</label>
                <input onChange={handleChange} minLength="10" maxLength="10" name="telefono_usuario" value={usuarioSeleccionado && usuarioSeleccionado.telefono_usuario} required type="text" />

                <label htmlFor="correo">Correo electrónico</label>
                <input onChange={handleChange} name="correo" value={usuarioSeleccionado && usuarioSeleccionado.correo} required type="email" />

                <label hidden htmlFor="contrasena">Contraseña</label>
                <input hidden onChange={handleChange} id="contrasena" name="contrasena" value={usuarioSeleccionado && usuarioSeleccionado.contrasena} required type="password" />

                <label htmlFor="id_cargo_usuario">Cargo</label>
                {cargoS ?
                  <select onChange={handleChange} id="id_cargo_usuario" required name="id_cargo_usuario">
                    <option value="" defaultValue>Seleccione una opción</option>
                    {cargoSeleccionado.map(items =>
                      <option key={items._id} value={items._id} >{items.name}</option>
                    )}
                  </select>
                  :
                  // <input onClick={() => setCargoS(true)} onChange={handleChange} id="id_cargo_usuario" name="id_cargo_usuario" value={cargo === 1 ? "Cocinera(o)" : cargo === 2 ? "Repartidor(a)" : cargo === 3 ? "Encargado" : cargo === 4 ? "Dueño" : "Desarrollador"} type="text" />
                  <input onClick={() => setCargoS(true)} onChange={handleChange} id="id_cargo_usuario" name="id_cargo_usuario" value={cargo} required type="text" />
                }

                <label htmlFor="id_permiso_usuario">Perfil</label>
                {permisoS ?
                  permisos !== "628c0ab8718beafc1c5ca26b" ?
                    <select onChange={handleChange} id="id_permiso_usuario" required name="id_permiso_usuario">
                      <option value="" defaultValue>Seleccione una opción</option>
                      {permisoSeleccionado.map(items =>
                        <option key={items._id} value={items._id}> {items.name}</option>
                      )}
                    </select>
                    :
                    <select onChange={handleChange} id="id_permiso_usuario" required name="id_permiso_usuario">
                      <option value="" defaultValue>Seleccione una opción</option>
                      {permisoSeleccionadoRoot.map(items =>
                        <option key={items._id} value={items._id}> {items.name}</option>
                      )}
                    </select>
                  :
                  // <input onClick={() => setPermisoS(true)} onChange={handleChange} id="id_permiso_usuario" name="id_permiso_usuario" value={permiso === 1 ? "Administrador" : "Empleado"} type="text" />
                  <input onClick={() => setPermisoS(true)} onChange={handleChange} id="id_permiso_usuario" name="id_permiso_usuario" value={permiso} required type="text" />
                }


                <div className="ModalFooter">
                  <input id="yes" type="submit" value="Guardar Cambios" />
                  <button onClick={() => abrirCerrarModalEditar()} id="cancel">Cancelar</button>
                </div>
              </form>




            </div>
          </div>
        </div>
      </div>

    </Fragment>
  );
}

export default ModalEditarUsuarios;
