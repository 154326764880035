import React, { Fragment, useState, useEffect } from "react";
import "./Sales.css";
import NavBar from '../../components/navBar/NavBar';
import Asidebar from '../../components/asidebar/Asidebar';
import Footer from '../../components/footer/Footer';
import ModalVerDetalleVenta from '../../components/commons/modalDetalleVenta/ModalVerDetalleVenta';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import moment from "moment";
moment.locale('es');

const cookies = new Cookies();

const Sales = () => {
    const baseUrl = window.$baseUrl;

    const [data, setData] = useState([]);
    const [dataDetalle, setDataDetalle] = useState([]);
    const [modalVisualizar, setModalVisualizar] = useState(false);
    const [dataTicket, setDataTicket] = useState([]);
    const [cargando, setCargando] = useState(false);
    const [inicial, setInicial] = useState(null);
    const [final, setFinal] = useState(null);



    const abrirCerrarModalVisualizar = () => {

        if (modalVisualizar) {
            setDataDetalle([])
            setDataTicket({})
        }
        setModalVisualizar(!modalVisualizar);

    }

    let id_usuario = cookies.get('id_usuario');

    const peticionGet = async () => {

        setInicial(null)
        setFinal(null)

        setCargando(true)
        try {
            await axios.get(baseUrl + "/api/venta/todos/asc/" + id_usuario)
                .then(response => {
                    console.log(response.data)
                    setData(response.data);

                    setCargando(false)
                }).catch(error => {
                    setCargando(false)
                    console.log(error);
                })
        } catch (e) {
            setCargando(false)
            console.log(e)
        }
    }

    const peticionGetxDia = async () => {

        if (inicial === null || final === null) {


        } else {
            setCargando(true)
            try {
                await axios.get(baseUrl + "/api/venta/dia/todos/asc/" + id_usuario + "/" + inicial + "/" + final)
                    .then(response => {
                        console.log(response.data)
                        setData(response.data);

                        setCargando(false)
                    }).catch(error => {
                        setCargando(false)
                        console.log(error);
                    })
            } catch (e) {
                setCargando(false)
                console.log(e)
            }
        }
    }


    useEffect(() => {
        if (inicial !== null || final !== null) {
            peticionGetxDia()
        }
    }, [inicial, final])

    const [siguiente, setSiguiente] = useState(0);


    const peticionSiguiente = async () => {

        await axios.get(baseUrl + "/api/venta/siguiente/" + id_usuario + "/" + (siguiente + 10))
            .then(response => {
                setData(response.data);

            }).catch(error => {
                console.log(error);
            })

        setSiguiente(siguiente + 10);



    }

    const peticionAnterior = async () => {

        await axios.get(baseUrl + "/api/venta/anterior/" + id_usuario + "/" + (siguiente - 10))
            .then(response => {
                setData(response.data);

            }).catch(error => {
                console.log(error);
            })

        setSiguiente(siguiente - 10);

    }



    const VentasHoy = async () => {

        var now = new Date();
        var year = now.getFullYear();
        var month = now.getMonth() + 1;
        var day = now.getDate();
        var manana = now.getDate() + 1;


        if (month.toString().length === 1) {
            var month = '0' + month;
        }
        if (day.toString().length === 1) {
            var day = '0' + day;
        }
        if (manana.toString().length === 1) {
            var manana = '0' + manana;
        }

        var i = year + '-' + month + '-' + day;
        var f = year + '-' + month + '-' + manana;

        setCargando(true)

        await axios.get(baseUrl + "/api/venta/rango/" + i + "/" + f + "/" + id_usuario)
            .then(response => {
                setCargando(false)
                setData(response.data);
            }).catch(error => {
                setCargando(false)
                console.log(error);
            })
    }

    const VentasAyer = async () => {

        var now = new Date();
        var year = now.getFullYear();
        var month = now.getMonth() + 1;
        var day = now.getDate();
        var ayer = now.getDate() - 1;

        if (month.toString().length === 1) {
            var month = '0' + month;
        }
        if (day.toString().length === 1) {
            var day = '0' + day;
        }

        var i = year + '-' + month + '-' + ayer;
        var f = year + '-' + month + '-' + day;

        setCargando(true)

        await axios.get(baseUrl + "/api/venta/rango/" + i + "/" + f + "/" + id_usuario)
            .then(response => {
                setCargando(false)
                setData(response.data);
            }).catch(error => {
                setCargando(false)
                console.log(error);
            })
    }

    const VentasSienteDias = async () => {

        var now = new Date();
        var year = now.getFullYear();
        var month = now.getMonth() + 1;
        var day = now.getDate();
        var ayer = now.getDate() - 7;

        if (month.toString().length === 1) {
            var month = '0' + month;
        }

        if (day.toString().length === 1) {
            var day = '0' + day;
        }

        if (ayer.toString().length === 1) {
            var ayer = '0' + ayer;
        }

        var i = year + '-' + month + '-' + ayer;
        var f = year + '-' + month + '-' + day;

        setCargando(true)

        await axios.get(baseUrl + "/api/venta/rango/" + i + "/" + f + "/" + id_usuario)
            .then(response => {
                setCargando(false)
                setData(response.data);
            }).catch(error => {
                setCargando(false)
                console.log(error);
            })
    }


    const VentasDelMes = async () => {

        var now = new Date();
        var year = now.getFullYear();
        var month = now.getMonth() + 1;


        if (month.toString().length === 1) {
            var month = '0' + month;
        }

        var m = month;
        var a = year;

        setCargando(true)

        await axios.get(baseUrl + "/api/venta/mes/" + m + "/" + a + "/" + id_usuario)
            .then(response => {
                setCargando(false)
                setData(response.data);
            }).catch(error => {
                setCargando(false)
                console.log(error);
            })
    }

    const buscarRango = () => {
        let valor = document.getElementById("filtro").value;

        if (valor === "Hoy") {
            VentasHoy();

        } else if (valor === "Ayer") {
            VentasAyer();

        } else if (valor === "Últimos 7 días") {
            VentasSienteDias();

        }
        else if (valor === "Este mes") {

            VentasDelMes();
        }

    }



    const peticionGetDesc = async () => {
        setCargando(true)
        await axios.get(baseUrl + "/api/venta/todos/v2/" + id_usuario)
            .then(response => {
                setCargando(false)
                setData(response.data);
            }).catch(error => {
                setCargando(false)
                console.log(error);
            })
    }

    const obtenerDatosTicket = async (venta) => {
        // console.log(venta)
        setCargando(true)
        try {
            await axios.get(baseUrl + "/api/ticket/" + venta.folio)
                .then(response => {
                    setCargando(false)
                    setDataTicket(response.data);
                }).catch(error => {
                    setCargando(false)
                    console.log(error);
                })
        } catch (error) {
            console.log(error)
        }
    }


    const detalle_venta = async (venta) => {
        // console.log(venta)
        obtenerDatosTicket(venta)
        window.location.href = "sales#arriba";
        abrirCerrarModalVisualizar()
        setCargando(true)
        try {
            await axios.get(baseUrl + "/api/detalle/desc/" + venta._id)
                .then(response => {
                    setCargando(false)
                    setDataDetalle(response.data);
                }).catch(error => {
                    setCargando(false)
                    console.log(error);
                })
        } catch (e) {
            console.log(e)
        }
    }


    useEffect(() => {
        peticionGetDesc();
    }, [])


    let permisos = cookies.get('id_permiso_usuario');
    let nombreperfil = cookies.get('nombre_completo_usuario') || "user";
    let primernombre = nombreperfil.split(" ", 1);

    const [menu, setMenu] = useState(true);

    const abrirmenu = () => {
        setMenu(!menu);

    }



    const styleagrandardashboard = {
        transitionDuration: "0.3s"
    };

    const stylencogerdashboard = {};

    const datos = data.length;

    const handleChangeInicial = (e) => {
        // console.log(e.target.value)
        setInicial(e.target.value)

    }

    const handleChangeFinal = (e) => {
        // console.log(e.target.value)
        setFinal(e.target.value)
    }

    return (
        <Fragment>
            <div id="arriba"></div>
            {!cookies.get('correo') && <Redirect to="./" />}
            {permisos !== "628c0a94718beafc1c5ca269" && <Redirect to="./" />}

            {modalVisualizar ?
                <ModalVerDetalleVenta modalVisualizar={modalVisualizar} abrirCerrarModalVisualizar={abrirCerrarModalVisualizar} setDataDetalle={setDataDetalle} dataDetalle={dataDetalle} dataTicket={dataTicket}
                    setDataTicket={setDataTicket} />
                :
                ""
            }



            <NavBar estado={menu} abrirmenu={abrirmenu} primernombre={primernombre} />

            <div className="fondo-sales">

                <Asidebar estado={menu} />

                <div style={menu ? styleagrandardashboard : stylencogerdashboard} className="content-adduser-main">

                    <label>Mis ventas</label>
                    <div className="select_table" style={{ paddingRight: '15px' }}>
                        <button onClick={peticionGet} id="ASC">ASC</button>
                        <button id="DESC" onClick={peticionGetDesc}>DESC</button>

                        <select hidden onChange={buscarRango} id="filtro" name="filtro">
                            <option value="" defaultValue>Seleccione una opción</option>

                            <option value="Hoy">Hoy</option>
                            <option value="Ayer">Ayer</option>
                            <option value="Últimos 7 días">Últimos 7 días</option>
                            <option value="Este mes">Este mes</option>

                        </select>


                        {/* {(inicial !== null & final !== null) ?
                            <div className="btn_fecha_inicial_termino btn_fecha_inicial_termino_restablecer" style={{ marginTop: '40px' }}>
                                <h4 onClick={() => {
                                    setInicial(null)
                                    setFinal(null)
                                    peticionGet();
                                }}>Restablecer</h4>
                            </div>
                            :
                            <></>
                        } */}

                    </div>

                    <div className="btn_fecha_inicial_termino_sales">
                        <div>
                            <p for="start">Dia Inicial:</p>

                            <input type="date" id="start" name="trip-start"
                                value={inicial === null ? "" : inicial}

                                onChange={handleChangeInicial}
                            />
                        </div>

                        <div>
                            <p for="end">Dia Final:</p>

                            <input type="date" id="end" name="trip-end"
                                value={final === null ? "" : final}
                                onChange={handleChangeFinal}
                            />
                        </div>

                    </div>

                    {(inicial !== null & final !== null) ?
                        <div className="btn_fecha_inicial_termino_sales btn_fecha_inicial_termino_salesRestablecer" style={{}}>
                            <h4 onClick={() => {
                                setInicial(null)
                                setFinal(null)
                                peticionGet();
                            }}>Restablecer</h4>
                        </div>
                        :
                        <></>
                    }

                    <div className="content-adduser-table">
                        <table className="table">
                            <thead className="thead">
                                <tr>
                                    <th>Fecha venta</th>
                                    <th>Folio</th>
                                    <th>Vendedor</th>
                                    {/* <th>Sucursal</th> */}
                                    <th>Forma pago</th>
                                    <th>Total</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>

                            {datos.length < 0 ? (
                                <tbody className="tbody">



                                </tbody>
                            ) : (
                                <tbody className="tbody">


                                    {data.map(venta =>

                                        <tr id="id_comida" key={venta._id}>
                                            <td id="desc_venta">{moment(new Date(venta.fecha_venta)).format('YYYY-MM-DD HH:mm:ss A')}</td>
                                            <td id="desc_venta">{venta.folio}</td>
                                            <td>{venta.user.nombre_completo_usuario}</td>
                                            {/* <td>{venta.sucursal.name}</td> */}
                                            <td>{venta.forma_pago}</td>
                                            <td>{`$${venta.total_venta}.00`}</td>
                                            <td>

                                                <button onClick={() => detalle_venta(venta)} id="history" title="Desglozar" className="btn_history"><i className="fas fa-history"></i></button>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            )}
                        </table>

                    </div>

                    {
                        cargando ?
                            <div className="sin_r">
                                <div className="lds-dual-ring"></div>
                            </div>
                            :
                            datos === 0 ?
                                <div className="sin_r">
                                    <h3>Sin resultados...</h3>
                                </div>
                                : ""
                    }

                    {/* {datos > 0 ?
                        <div className="container-pages">
                            {siguiente === 0 ?
                                ""
                                :
                                <button id="anterior" onClick={() => peticionAnterior()}>Anterior</button>
                            }
                            <button id="siguiente" onClick={() => peticionSiguiente()}>Siguiente</button>
                        </div>
                        : <div className="container-pages">
                            {siguiente === 0 ?
                                ""
                                :
                                <button id="anterior" onClick={() => peticionAnterior()}>Anterior</button>
                            }

                        </div>
                    }




                    <br />

                    {`Mostrando registros del 1 al ${datos} de un total de ${datos}`} */}

                </div>

            </div>


            <Footer />
        </Fragment>
    )
}

export default Sales;