import React, { Fragment, useState, useEffect } from "react";
import './ModalUsuarios.css';
import axios from 'axios';
import Cookies from 'universal-cookie';


const cookies = new Cookies();


const ModalAltaUsuarios = ({ modalEditar, modalInsertar, peticionPut, peticionPost, seleccionarUsuario, usuarioSeleccionado, abrirCerrarModalEditar, abrirCerrarModalInsertar, handleChange }) => {

  const baseUrl = window.$baseUrl;
  let permisos = cookies.get('id_permiso_usuario');


  const peticionS = async () => {

    try {
      await axios.get(baseUrl + "/api/sucursal/")
        .then(response => {
          setSucursalSeleccionado(response.data);

        }).catch(error => {
          console.log(error);
        })
    } catch (e) {
      console.log(e)
    }


  }

  const peticionS_Root = async () => {

    try {
      await axios.get(baseUrl + "/api/sucursal/")
        .then(response => {
          setSucursalSeleccionadoRoot(response.data);
        }).catch(error => {
          console.log(error);
        })
    } catch (e) {
      console.log(e)
    }


  }

  const peticionC = async () => {

    try {
      await axios.get(baseUrl + "/api/cargo/")
        .then(response => {
          setCargoSeleccionado(response.data);
        }).catch(error => {
          console.log(error);
        })
    } catch (e) {
      console.log(e)
    }


  }

  const peticionP = async () => {

    try {
      await axios.get(baseUrl + "/api/rol/")
        .then(response => {
          setPermisoSeleccionado(response.data);
        }).catch(error => {
          console.log(error);
        })
    } catch (e) {
      console.log(e)
    }


  }

  const peticionP_Root = async () => {

    try {
      await axios.get(baseUrl + "/api/rol/")
        .then(response => {
          setPermisoSeleccionadoRoot(response.data);
        }).catch(error => {
          console.log(error);
        })
    } catch (e) {
      console.log(e)
    }


  }

  useEffect(() => {
    peticionS();
    peticionS_Root();
    peticionC();
    peticionP();
    peticionP_Root();
  }, [])


  const [cargoSeleccionado, setCargoSeleccionado] = useState([]);

  const [permisoSeleccionado, setPermisoSeleccionado] = useState([]);

  const [permisoSeleccionadoRoot, setPermisoSeleccionadoRoot] = useState([]);

  const [sucursalSeleccionado, setSucursalSeleccionado] = useState([]);

  const [sucursalSeleccionadoRoot, setSucursalSeleccionadoRoot] = useState([]);



  return (
    <Fragment>
      <div className={modalInsertar ? "Modal-desactive" : "Modal-active"}>
        <div className="contend-modalU">
          <div className="ModalHeaderU">
            <h2>Agregar Usuario</h2>
            <i className="fas fa-times" onClick={() => abrirCerrarModalInsertar()} id="icon-close"></i>
          </div>
          <div className="ModalBodyU">
            <div className="FormGroupU">
              <form onSubmit={peticionPost} id="Form-insertarU">
                {permisos !== "628c0ab8718beafc1c5ca26b" ?
                  ""
                  // <label htmlFor="id_sucursal">Sucursal</label>
                  :
                  <label htmlFor="id_sucursal">Sucursal</label>
                }

                {permisos !== "628c0ab8718beafc1c5ca26b" ?
                  ""
                  // <select onChange={handleChange} id="id_sucursal" required name="id_sucursal">
                  //   <option value="" defaultValue>Seleccione una opción</option>
                  //   {sucursalSeleccionado.map(items =>
                  //     <option key={items.id_sucursal} value={items.id_sucursal}>{items.nombre_sucursal}</option>
                  //   )}
                  // </select>
                  :
                  <select onChange={handleChange} id="id_sucursal" required name="id_sucursal">
                    <option value="" defaultValue>Seleccione una opción</option>
                    {sucursalSeleccionadoRoot.map(items =>
                      <option key={items._id} value={items._id}>{items.name}</option>
                    )}
                  </select>
                }
                <label htmlFor="nombre_completo_usuario">Nombre completo</label>
                <input onChange={handleChange} name="nombre_completo_usuario" required type="text" />

                <label htmlFor="edad_usuario">Fecha de nacimiento</label>
                <input onChange={handleChange} name="edad_usuario" required type="date" />

                <label htmlFor="telefono_usuario">Teléfono</label>
                <input onChange={handleChange} minLength="10" maxLength="10" name="telefono_usuario" required type="text" />

                <label htmlFor="correo">Correo electrónico</label>
                <input onChange={handleChange} name="correo" required type="email" />

                <label hidden htmlFor="contrasena">Contraseña</label>
                <input hidden onChange={handleChange} id="contrasena" name="contrasena" type="text" />

                <label htmlFor="id_cargo_usuario">Cargo</label>
                <select onChange={handleChange} id="id_cargo_usuario" required name="id_cargo_usuario">
                  <option value="" defaultValue>Seleccione una opción</option>
                  {cargoSeleccionado.map(items =>
                    <option key={items._id} value={items._id}>{items.name}</option>
                  )}
                </select>

                <label htmlFor="id_permiso_usuario">Perfil</label>
                {permisos !== "628c0ab8718beafc1c5ca26b" ?

                  <select onChange={handleChange} id="id_permiso_usuario" required name="id_permiso_usuario">
                    <option value="" defaultValue>Seleccione una opción</option>
                    {permisoSeleccionado.map(items =>
                      <option key={items._id} value={items._id}> {items.name}</option>
                    )}
                  </select>

                  :
                  <select onChange={handleChange} id="id_permiso_usuario" required name="id_permiso_usuario">
                    <option value="" defaultValue>Seleccione una opción</option>
                    {permisoSeleccionadoRoot.map(items =>
                      <option key={items._id} value={items._id}> {items.name}</option>
                    )}
                  </select>
                }

                <div className="ModalFooter">
                  <input id="yes" type="submit" value="Guardar" />
                  <button onClick={() => abrirCerrarModalInsertar()} id="cancel">Cancelar</button>
                </div>
              </form>



            </div>
          </div>
        </div>
      </div>



    </Fragment>
  );
}

export default ModalAltaUsuarios;
