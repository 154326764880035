import React, { Fragment, useState, useEffect } from "react";
import "./SalesQuantity.css";
import NavBar from '../../components/navBar/NavBar';
import Asidebar from '../../components/asidebar/Asidebar';
import Footer from '../../components/footer/Footer';
import ModalVerDetalleVenta from '../../components/commons/modalDetalleVenta/ModalVerDetalleVenta';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import moment from "moment";


const cookies = new Cookies();

const SalesQuantity = () => {
    const baseUrl = window.$baseUrl;

    let permisos = cookies.get('id_permiso_usuario');
    let nombreperfil = cookies.get('nombre_completo_usuario') || "user";
    let primernombre = nombreperfil.split(" ", 1);



    const [data, setData] = useState([]);
    const [dataBack, setDataBack] = useState([]);
    const [dataDetalle, setDataDetalle] = useState([]);
    const [modalVisualizar, setModalVisualizar] = useState(false);
    const [dataTicket, setDataTicket] = useState([]);
    const [cargando, setCargando] = useState(false);
    const [inicial, setInicial] = useState(null);
    const [final, setFinal] = useState(null);


    const abrirCerrarModalVisualizar = () => {

        if (modalVisualizar) {
            setDataDetalle([])
            setDataTicket({})
        }

        setModalVisualizar(!modalVisualizar);

    }


    const [sucursalSeleccionado, setSucursalSeleccionado] = useState([]);

    const peticionS = async () => {
        await axios.get(baseUrl + "/api/sucursal")
            .then(response => {
                setSucursalSeleccionado(response.data);
            }).catch(error => {
                console.log(error);
            })
    }



    const peticionGetDesc = async () => {

        await axios.get(baseUrl + "/api/venta")
            .then(response => {

                setData(response.data);
            }).catch(error => {

                console.log(error);
            })
    }

    const [siguiente, setSiguiente] = useState(0);


    const peticionSiguiente = async () => {

        await axios.get(baseUrl + "/api/ventas/siguiente")
            .then(response => {
                setData(response.data);
                setDataBack(response.data);
            }).catch(error => {
                console.log(error);
            })

        setSiguiente(siguiente + 10);



    }

    const peticionAnterior = async () => {

        await axios.get(baseUrl + "/api/ventas/anterior/" + (siguiente - 10))
            .then(response => {
                setData(response.data);

            }).catch(error => {
                console.log(error);
            })

        setSiguiente(siguiente - 10);

    }

    const peticionGetFiltro = async () => {

        let id = document.getElementById("id_sucursal").value;
        console.log(id)

        if (id === "") {
            await axios.get(baseUrl + "/api/venta")
                .then(response => {
                    setData(response.data);
                }).catch(error => {
                    console.log(error);
                })
        } else {
            await axios.get(baseUrl + "/api/venta/suc/" + id)
                .then(response => {
                    setData(response.data);
                }).catch(error => {
                    console.log(error);
                })
        }

    }

    const obtenerDatosTicket = async (venta) => {
        // console.log(venta)

        try {
            await axios.get(baseUrl + "/api/ticket/" + venta.folio)
                .then(response => {
                    setDataTicket(response.data);
                }).catch(error => {
                    console.log(error);
                })
        } catch (error) {
            console.log(error)
        }
    }

    const detalle_venta = async (venta) => {
        obtenerDatosTicket(venta)
        window.location.href = "salesquantity#arriba";
        abrirCerrarModalVisualizar()

        try {
            await axios.get(baseUrl + "/api/detalle/desc/" + venta._id)
                .then(response => {
                    setDataDetalle(response.data);
                }).catch(error => {
                    console.log(error);
                })
        } catch (e) {
            console.log(e)
        }
    }

    const peticionAllGetDesc = async () => {
        setCargando(true)
        await axios.get(baseUrl + "/api/venta")
            .then(response => {
                setCargando(false)
                setData(response.data);
                setFolioSeleccionado(response.data);
            }).catch(error => {
                setCargando(false)
                console.log(error);
            })

    }





    const [folio, setFolio] = useState({ folio: "" });

    const [folioSeleccionado, setFolioSeleccionado] = useState([]);

    const found = folioSeleccionado.filter(element => element.folio.toString().toLowerCase().includes(folio.folio.toLocaleLowerCase()) || element.user.nombre_completo_usuario.toString().toLowerCase().includes(folio.folio.toLocaleLowerCase()) || element.forma_pago.toString().toLowerCase().includes(folio.folio.toLocaleLowerCase()) || element.sucursal.name.toString().toLowerCase().includes(folio.folio.toLocaleLowerCase()));


    const handleChange = e => {
        const { name, value } = e.target;

        setFolio((prevState) => ({
            ...prevState,
            [name]: value.toUpperCase()
        }))

    }


    const VentasHoy = async () => {

        var now = new Date();
        var year = now.getFullYear();
        var month = now.getMonth() + 1;
        var day = now.getDate();
        var manana = now.getDate() + 1;


        if (month.toString().length === 1) {
            var month = '0' + month;
        }
        if (day.toString().length === 1) {
            var day = '0' + day;
        }
        if (manana.toString().length === 1) {
            var manana = '0' + manana;
        }

        var i = year + '-' + month + '-' + day;
        var f = year + '-' + month + '-' + manana;

        await axios.get(baseUrl + "/api/venta/obtener/todas/" + i + "/" + f)
            .then(response => {
                setData(response.data);
            }).catch(error => {
                console.log(error);
            })
    }

    const VentasAyer = async () => {

        var now = new Date();
        var year = now.getFullYear();
        var month = now.getMonth() + 1;
        var day = now.getDate();
        var ayer = now.getDate() - 1;

        if (month.toString().length === 1) {
            var month = '0' + month;
        }
        if (day.toString().length === 1) {
            var day = '0' + day;
        }

        var i = year + '-' + month + '-' + ayer;
        var f = year + '-' + month + '-' + day;

        await axios.get(baseUrl + "/api/venta/obtener/todas/" + i + "/" + f)
            .then(response => {
                setData(response.data);
            }).catch(error => {
                console.log(error);
            })
    }

    const VentasSienteDias = async () => {

        await axios.get(baseUrl + "/api/venta/todas/rangoventa/siete")
            .then(response => {
                setData(response.data);
            }).catch(error => {
                console.log(error);
            })
    }


    const VentasDelMes = async () => {

        var now = new Date();
        var year = now.getFullYear();
        var month = now.getMonth() + 1;


        if (month.toString().length === 1) {
            var month = '0' + month;
        }

        var m = month;
        var a = year;

        await axios.get(baseUrl + "/api/venta/mes/todas/" + m + "/" + a)
            .then(response => {
                setData(response.data);
            }).catch(error => {
                console.log(error);
            })
    }


    const buscarRango = () => {
        let valor = document.getElementById("filtro").value;

        if (valor === "Hoy") {
            VentasHoy();

        } else if (valor === "Ayer") {
            VentasAyer();

        } else if (valor === "Últimos 7 días") {
            VentasSienteDias();

        }
        else if (valor === "Este mes") {

            VentasDelMes();
        }

    }

    const peticionGetDescxDia = async () => {
        if (inicial === null || final === null) {


        } else {
            await axios.get(baseUrl + "/api/venta/" + inicial + "/" + final)
                .then(response => {

                    setData(response.data);
                }).catch(error => {

                    console.log(error);
                })


        }
    }


    const peticionAllGetDescxDia = async () => {
        if (inicial === null || final === null) {


        } else {
            setCargando(true)
            await axios.get(baseUrl + "/api/venta/" + inicial + "/" + final)
                .then(response => {
                    setCargando(false)
                    setFolioSeleccionado(response.data);
                }).catch(error => {
                    setCargando(false)
                    console.log(error);
                })
        }
    }


    useEffect(() => {
        peticionS();
        // peticionGetDesc();
        peticionAllGetDesc();
    }, [])



    useEffect(() => {
        if (inicial !== null || final !== null) {
            // peticionGetDescxDia()
            peticionAllGetDescxDia()
        }
    }, [inicial, final])


    const [menu, setMenu] = useState(true);

    const abrirmenu = () => {
        setMenu(!menu);

    }


    const styleagrandardashboard = {
        transitionDuration: "0.3s"
    };

    const stylencogerdashboard = {};


    const datos = data.length;



    const handleChangeInicial = (e) => {
        // console.log(e.target.value)
        setInicial(e.target.value)

    }

    const handleChangeFinal = (e) => {
        // console.log(e.target.value)
        setFinal(e.target.value)
    }


    return (
        <Fragment>
            <div id="arriba"></div>

            {!cookies.get('correo') && <Redirect to="./" />}
            {permisos !== "628c0ab8718beafc1c5ca26b" && <Redirect to="./" />}

            {modalVisualizar ?
                <ModalVerDetalleVenta modalVisualizar={modalVisualizar} abrirCerrarModalVisualizar={abrirCerrarModalVisualizar} dataDetalle={dataDetalle} dataTicket={dataTicket}
                    setDataTicket={setDataTicket} />
                :
                ""
            }

            <NavBar estado={menu} abrirmenu={abrirmenu} primernombre={primernombre} />

            <div className="fondo-salesquantity">

                <Asidebar estado={menu} />

                <div style={menu ? styleagrandardashboard : stylencogerdashboard} className="content-salesquantity-main">

                    <label>Administrar las ventas</label>


                    <div className="select_table_filtros">
                        <select id="id_sucursal" onChange={peticionGetFiltro} required name="id_sucursal">
                            <option value="" defaultValue>Seleccione una opción</option>
                            {sucursalSeleccionado.map(items =>
                                <option key={items._id} value={items._id}>{items.name}</option>
                            )}
                        </select>

                        <input autoComplete="off" onChange={handleChange} type="text" id="folio" name="folio" placeholder="Buscar"></input>

                        <select hidden onChange={buscarRango} id="filtro" name="filtro">
                            <option value="" defaultValue>Seleccione una opción</option>

                            <option value="Hoy">Hoy</option>
                            <option value="Ayer">Ayer</option>
                            <option value="Últimos 7 días">Últimos 7 días</option>
                            <option value="Este mes">Este mes</option>

                        </select>

                    </div>

                    <div className="btn_fecha_inicial_termino_sales" style={{ marginTop: '10px' }}>
                        <div>
                            <p for="start">Dia Inicial:</p>

                            <input type="date" id="start" name="trip-start"
                                value={inicial === null ? "" : inicial}

                                onChange={handleChangeInicial}
                            />
                        </div>

                        <div>
                            <p for="end">Dia Final:</p>

                            <input type="date" id="end" name="trip-end"
                                value={final === null ? "" : final}
                                onChange={handleChangeFinal}
                            />
                        </div>

                    </div>

                    {(inicial !== null & final !== null) ?
                        <div className="btn_fecha_inicial_termino_sales btn_fecha_inicial_termino_salesRestablecer" style={{}}>
                            <h4 onClick={() => {
                                setInicial(null)
                                setFinal(null)
                                // peticionGetDescxDia()
                                peticionAllGetDescxDia()
                            }}>Restablecer</h4>
                        </div>
                        :
                        <></>
                    }


                    <div className="content-adduser-table">
                        <table className="table">
                            <thead className="thead">
                                <tr>
                                    <th>Fecha venta</th>
                                    <th>Folio</th>
                                    <th>Vendedor</th>
                                    <th>Sucursal</th>
                                    <th>Forma pago</th>
                                    <th>Total</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>

                            {datos === 0 ? (
                                <tbody className="tbody">


                                </tbody>
                            ) : (
                                <tbody className="tbody">

                                    {found.length >= 1 ?

                                        found.map(venta =>

                                            <tr id="id_comida" key={venta._id}>
                                                <td id="desc_venta">{venta.fecha_venta ? moment(venta.fecha_venta).format("YYYY-MM-DD HH:mm:ss A") : ""}</td>
                                                <td>{venta.folio}</td>
                                                <td>{venta.user.nombre_completo_usuario}</td>
                                                <td>{venta.sucursal.name}</td>
                                                <td>{venta.forma_pago}</td>
                                                <td>{`$${venta.total_venta}.00`}</td>
                                                <td>
                                                    <button onClick={() => detalle_venta(venta)} id="history" title="Desglozar" className="btn_history"><i className="fas fa-history"></i></button>
                                                </td>
                                            </tr>
                                        )
                                        : found.length === 0 ?
                                            <></>
                                            :
                                            data.map(venta =>

                                                <tr id="id_comida" key={venta._id}>
                                                    <td id="desc_venta">{venta.fecha_venta ? moment(venta.fecha_venta).format("YYYY-MM-DD HH:mm:ss A") : ""}</td>
                                                    <td>{venta.folio}</td>
                                                    <td>{venta.user.nombre_completo_usuario}</td>
                                                    <td>{venta.sucursal.name}</td>
                                                    <td>{venta.forma_pago}</td>
                                                    <td>{`$${venta.total_venta}.00`}</td>
                                                    <td>
                                                        <button onClick={() => detalle_venta(venta)} id="history" title="Desglozar" className="btn_history"><i className="fas fa-history"></i></button>
                                                    </td>
                                                </tr>
                                            )
                                    }
                                </tbody>
                            )}
                        </table>


                    </div>

                    {
                        cargando ?
                            <div className="content-cargando">
                                <div className="lds-dual-ring"></div>
                            </div>
                            :
                            datos === 0 || found.length === 0 ?
                                <div className="sin_r">
                                    <h3>Sin resultados...</h3>
                                </div>
                                : ""
                    }

                    {/* {datos > 0 ?
                        <div className="container-pages">
                            {siguiente === 0 ?
                                ""
                                :
                                <button id="anterior" onClick={() => peticionAnterior()}>Anterior</button>
                            }
                            <button id="siguiente" onClick={() => peticionSiguiente()}>Siguiente</button>
                        </div>
                        : <div className="container-pages">
                            {siguiente === 0 ?
                                ""
                                :
                                <button id="anterior" onClick={() => peticionAnterior()}>Anterior</button>
                            }

                        </div>
                    }
 */}



                    {/* <br />

                    {`Mostrando registros del 1 al ${datos} de un total de ${datos}`} */}
                </div>

            </div>


            <Footer />
        </Fragment>
    )
}

export default SalesQuantity;