import React, { Fragment, useEffect, useState } from "react";
import axios from 'axios';
import './ModalBebidas.css';
import Cookies from 'universal-cookie';


const cookies = new Cookies();


const ModalAltaBebidas = ({ modalInsertar, peticionPost, seleccionarBebida, bebidaSeleccionado, abrirCerrarModalEditar, abrirCerrarModalInsertar, handleChange }) => {

  const baseUrl = window.$baseUrl;
  let permisos = cookies.get('id_permiso_usuario');

  const [sucursalSeleccionado, setSucursalSeleccionado] = useState([]);

  const [sucursalSeleccionadoRoot, setSucursalSeleccionadoRoot] = useState([]);

  const peticionS = async () => {
    await axios.get(baseUrl + "/api/sucursal")
      .then(response => {
        setSucursalSeleccionado(response.data);
      }).catch(error => {
        console.log(error);
      })
  }

  const peticionS_Root = async () => {
    await axios.get(baseUrl + "/api/sucursal")
      .then(response => {
        setSucursalSeleccionadoRoot(response.data);
      }).catch(error => {
        console.log(error);
      })
  }

  const peticionC = async () => {
    await axios.get(baseUrl + "/api/clasificacion")
      .then(response => {
        setClasificacionSeleccionado(response.data);
      }).catch(error => {
        console.log(error);
      })
  }

  const peticionP = async () => {
    await axios.get(baseUrl + "/api/presentacion")
      .then(response => {
        setPresentacionSeleccionado(response.data);
      }).catch(error => {
        console.log(error);
      })
  }



  useEffect(() => {
    peticionC();
    peticionP();
    peticionS();
    peticionS_Root();
  }, [])

  const [presentacionSeleccionado, setPresentacionSeleccionado] = useState([]);

  const [clasificacionSeleccionado, setClasificacionSeleccionado] = useState([]);


  return (
    <Fragment>
      <div className={modalInsertar ? "Modal-desactive" : "Modal-active"}>
        <div className="contend-modalB">
          <div className="ModalHeaderB">
            <h2>Agregar Bebida</h2>
            <i className="fas fa-times" onClick={() => abrirCerrarModalInsertar()} id="icon-close"></i>
          </div>
          <div className="ModalBodyB">
            <div className="FormGroupB">
              <form onSubmit={peticionPost} id="Form-insertar">

                <label htmlFor="nombre_bebida">Nombre bebida</label>
                <input onChange={handleChange} name="nombre_bebida" required type="text" />

                {permisos !== "628c0ab8718beafc1c5ca26b" ?
                  ""
                  // <label htmlFor="id_sucursal">Sucursal</label>
                  :
                  <label htmlFor="id_sucursal">Sucursal</label>
                }

                {permisos !== "628c0ab8718beafc1c5ca26b" ?
                  ""
                  // <select onChange={handleChange} id="id_sucursal" required name="id_sucursal">
                  //   <option value="" defaultValue>Seleccione una opción</option>
                  //   {sucursalSeleccionado.map(items =>
                  //     <option key={items.id_sucursal} value={items.id_sucursal}>{items.nombre_sucursal}</option>
                  //   )}
                  // </select>
                  :
                  <select onChange={handleChange} id="id_sucursal" required name="id_sucursal">
                    <option value="" defaultValue>Seleccione una opción</option>
                    {sucursalSeleccionadoRoot.map(items =>
                      <option key={items._id} value={items._id}>{items.name}</option>
                    )}
                  </select>
                }


                <label htmlFor="id_clasificacion_bebidas">Clasificación</label>
                <select onChange={handleChange} id="id_clasificacion_bebidas" required name="id_clasificacion_bebidas">
                  <option value="" defaultValue>Seleccione una opción</option>
                  {clasificacionSeleccionado.map(items =>
                    <option key={items._id} value={items._id}>{items.name}</option>
                  )}
                </select>

                <label htmlFor="id_presentacion_bebidas">Presentación</label>
                <select onChange={handleChange} id="id_presentacion_bebidas" required name="id_presentacion_bebidas">
                  <option value="" defaultValue>Seleccione una opción</option>
                  {presentacionSeleccionado.map(items =>
                    <option key={items._id} value={items._id}>{items.name}</option>
                  )}
                </select>

                <label htmlFor="tamano">Tamaño</label>
                <input onChange={handleChange} name="tamano" required type="text" />

                <label htmlFor="precio">Precio</label>
                <input onChange={handleChange} step=".01" name="precio" required type="number" />

                <div className="ModalFooter">
                  <input id="yes" type="submit" value="Guardar" />
                  <button onClick={() => abrirCerrarModalInsertar()} id="cancel">Cancelar</button>
                </div>
              </form>




            </div>
          </div>
        </div>
      </div>



    </Fragment>
  );
}

export default ModalAltaBebidas;
