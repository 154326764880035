import React, {Fragment} from 'react';
import './App.css';
import Header from './components/header/Header';
import Login from "./views/login/Login";
import Footer from './components/footer/Footer';


function App() {

  return (
    <Fragment>
      <Header />
      <Login />  

      <Footer />
    </Fragment>
  );
}

export default App;
