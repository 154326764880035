import React, { Fragment, useState, useEffect } from 'react';
import "./Products.css";
import NavBar from '../../components/navBar/NavBar';
import Asidebar from '../../components/asidebar/Asidebar';
import ModalAltaComidas from '../../components/commons/modalComidas/ModalAltaComidas';
import ModalEditarComidas from '../../components/commons/modalComidas/ModalEditarComidas';
import Footer from '../../components/footer/Footer';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import swal from 'sweetalert';
import Cookies from 'universal-cookie';
import moment from 'moment';

const cookies = new Cookies();

const Products = () => {

    const baseUrl = window.$baseUrl;
    let permisos = cookies.get('id_permiso_usuario');

    let id_sucursal = cookies.get('id_sucursal');

    const [data, setData] = useState([]);
    const [modalInsertar, setModalInsertar] = useState(false);
    const [modalEditar, setModalEditar] = useState(false);
    const [modalEliminar, setModalEliminar] = useState(false);
    const [comidaSeleccionado, setComidaSeleccionado] = useState({
        id_comida: "",
        id_sucursal: "",
        nombre_comida: "",
        presentacion_comida: "",
        equivalencia_presentacion: "",
        precio: ""

    });

    const handleChange = e => {
        const { name, value } = e.target;
        setComidaSeleccionado((prevState) => ({
            ...prevState,
            [name]: value
        }))

    }


    const abrirCerrarModalInsertar = () => {
        document.getElementById("Form-insertar").reset();
        setModalInsertar(!modalInsertar);

    }

    const abrirCerrarModalEditar = () => {
        setModalEditar(!modalEditar);
    }

    const abrirCerrarModalEliminar = () => {
        setModalEliminar(!modalEliminar);
    }

    const peticionGet = async () => {

        try {
            await axios.get(baseUrl + "/api/comida/suc/cantidad/" + id_sucursal)
                .then(response => {
                    setData(response.data);
                    console.log(response.data)
                }).catch(error => {
                    console.log(error);
                })
        } catch (e) {
            console.log(e)
        }


    }


    const peticionAllGet = async () => {
        try {
            await axios.get(baseUrl + "/api/comida/cantidad/root")
                .then(response => {
                    console.log(response.data)
                    setComidas(response.data);
                }).catch(error => {
                    console.log(error);
                })
        } catch (error) {
            console.log(error)
        }
    }



    const peticionPost = async (e) => {
        e.preventDefault();

        if (permisos === "628c073747bc232d04b1b5f4") {


            try {
                await axios.post(baseUrl + "/api/comida/new", {
                    sucursal: id_sucursal,
                    nombre_comida: comidaSeleccionado.nombre_comida,
                    presentacion_comida: " " + comidaSeleccionado.presentacion_comida,
                    equivalencia_presentacion: 1,
                    precio: comidaSeleccionado.precio,
                    status: true
                }).then(response => {
                    console.log(response);
                }).catch(error => {
                    console.log(error);
                })
            } catch (error) {
                console.log(error)
            }


        } else if (permisos === "628c0ab8718beafc1c5ca26b") {

            try {
                await axios.post(baseUrl + "/api/comida/new", {
                    sucursal: comidaSeleccionado.id_sucursal,
                    nombre_comida: comidaSeleccionado.nombre_comida,
                    presentacion_comida: " " + comidaSeleccionado.presentacion_comida,
                    equivalencia_presentacion: 1,
                    precio: comidaSeleccionado.precio,
                    status: true
                }).then(response => {
                    console.log(response);
                }).catch(error => {
                    console.log(error);
                })
            } catch (error) {
                console.log(error)
            }


        }


        peticionGet();
        peticionAllGet();
        abrirCerrarModalInsertar();
    }

    const peticionPut = async (e) => {
        e.preventDefault();
        if (permisos === "628c073747bc232d04b1b5f4") {

            try {
                await axios.put(baseUrl + "/api/comida/" + comidaSeleccionado._id, {
                    id_comida: comidaSeleccionado._id,
                    sucursal: id_sucursal,
                    nombre_comida: comidaSeleccionado.nombre_comida,
                    presentacion_comida: comidaSeleccionado.presentacion_comida,
                    equivalencia_presentacion: 1,
                    precio: comidaSeleccionado.precio

                })
                    .then(response => {
                        console.log(response);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            } catch (error) {
                console.log(error)
            }


        } else if (permisos === "628c0ab8718beafc1c5ca26b") {

            try {
                await axios.put(baseUrl + "/api/comida/" + comidaSeleccionado._id, {
                    id_comida: comidaSeleccionado._id,
                    sucursal: comidaSeleccionado.id_sucursal,
                    nombre_comida: comidaSeleccionado.nombre_comida,
                    presentacion_comida: comidaSeleccionado.presentacion_comida,
                    equivalencia_presentacion: 1,
                    precio: comidaSeleccionado.precio
                })
                    .then(response => {
                        console.log(response);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            } catch (error) {
                console.log(error)
            }


        }


        peticionGet();
        peticionAllGet();
        abrirCerrarModalEditar();

    }


    const peticionDelete = (id) => {
        swal({
            title: "¿Estás seguro?",
            text: "¡Una vez eliminado no podrás recuperar la información!",
            icon: "warning",
            buttons: ["Cancelar", "Aceptar"],
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                    try {
                        axios.put(baseUrl + '/api/comida/eliminar/' + id, {

                        }).then(response => {



                            swal("La comida fue borrado correctamente", {
                                icon: "success",
                                button: "Aceptar",
                                timer: "2000"
                            });

                            peticionGet();
                            peticionAllGet();

                        }).catch(e => {
                            console.log(e);
                        });
                    } catch (error) {
                        console.log(error)
                    }
                }
            });

    }

    const seleccionarComida = (comida, caso) => {
        window.location.href = "products#arriba";
        setComidaSeleccionado(comida);

        (caso === "Editar") ?
            abrirCerrarModalEditar() :
            abrirCerrarModalEliminar()
    }

    const [sucursalSeleccionadoRoot, setSucursalSeleccionadoRoot] = useState([]);

    const peticionS_Root = async () => {

        try {
            await axios.get(baseUrl + "/api/sucursal")
                .then(response => {
                    setSucursalSeleccionadoRoot(response.data);
                }).catch(error => {
                    console.log(error);
                })
        } catch (error) {
            console.log(error)
        }


    }


    const [comidas, setComidas] = useState([]);

    const buscarComidasSucursal = async () => {

        let valor = document.getElementById("filtro").value;

        if (valor === "") {
            try {
                await axios.get(baseUrl + "/api/comida/cantidad/root")
                    .then(response => {
                        console.log(response.data)
                        setComidas(response.data);
                    }).catch(error => {
                        console.log(error);
                    })
            } catch (error) {
                console.log(error)
            }
        } else {
            try {
                await axios.get(baseUrl + "/api/comida/suc/cantidad/" + valor)
                    .then(response => {
                        setComidas(response.data);
                    }).catch(error => {
                        console.log(error);
                    })
            } catch (error) {
                console.log(error)
            }
        }

    }



    useEffect(() => {
        peticionS_Root();
        peticionGet();
        peticionAllGet();
    }, [])




    let nombreperfil = cookies.get('nombre_completo_usuario') || "user";
    let primernombre = nombreperfil.split(" ", 1);

    const [menu, setMenu] = useState(true);

    const abrirmenu = () => {
        setMenu(!menu);

    }

    const styleagrandardashboard = {
        transitionDuration: "0.3s"
    };

    const stylencogerdashboard = {};

    const datos = data ? data.length : 0;
    const com = comidas ? comidas.length : 0;


    return (
        <Fragment>
            <div id="arriba"></div>
            {!cookies.get('correo') && <Redirect to="./" />}
            {permisos === "628c0a94718beafc1c5ca269" && <Redirect to="./" />}


            {modalEditar ?
                <ModalEditarComidas comidaSeleccionado={comidaSeleccionado} seleccionarComida={seleccionarComida} peticionPut={peticionPut} modalInsertar={modalInsertar} peticionPost={peticionPost} modalEditar={modalEditar} abrirCerrarModalEditar={abrirCerrarModalEditar} abrirCerrarModalInsertar={abrirCerrarModalInsertar} handleChange={handleChange} />
                :
                <ModalAltaComidas comidaSeleccionado={comidaSeleccionado} seleccionarComida={seleccionarComida} peticionPut={peticionPut} modalInsertar={modalInsertar} peticionPost={peticionPost} modalEditar={modalEditar} abrirCerrarModalEditar={abrirCerrarModalEditar} abrirCerrarModalInsertar={abrirCerrarModalInsertar} handleChange={handleChange} />
            }



            <NavBar estado={menu} abrirmenu={abrirmenu} primernombre={primernombre} />

            <div className="fondo-addcomidas">

                <Asidebar estado={menu} />

                <div style={menu ? styleagrandardashboard : stylencogerdashboard} className="content-addcomidas-main">

                    <label>Administrar comidas</label>
                    <div className="btn_addcomidas">
                        <button onClick={() => abrirCerrarModalInsertar()} id="btn_addcomidas">Agregar comida <i className="fas fa-plus"></i></button>
                    </div>

                    {permisos !== "628c0ab8718beafc1c5ca26b" ?
                        ""
                        :
                        <div className="select_table">

                            <select onChange={buscarComidasSucursal} id="filtro" name="filtro">
                                <option value="" defaultValue>Seleccione una opción</option>

                                {sucursalSeleccionadoRoot.map(sucursales =>

                                    <option key={sucursales._id} value={sucursales._id}>{sucursales.name}</option>

                                )}

                            </select>
                        </div>
                    }


                    {permisos === "628c073747bc232d04b1b5f4" ?
                        <div className="content-addcomidas-table">
                            <table className="table">
                                <thead className="thead">
                                    <tr>
                                        <th>#</th>
                                        <th>Nombre comida</th>
                                        <th>Presentación</th>
                                        <th>Precio</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>

                                {datos.length < 0 ? (
                                    <tbody className="tbody">



                                    </tbody>
                                ) : (
                                    <tbody className="tbody">


                                        {data.map(comida =>

                                            <tr id="id_comida" key={comida._id}>
                                                <td id="prod">{comida._id}</td>
                                                <td id="nom_prod">{comida.nombre_comida}</td>
                                                <td>{comida.presentacion_comida}</td>
                                                <td>{`$${comida.precio}.00`}</td>
                                                <td>
                                                    <button onClick={() => seleccionarComida(comida, "Editar")} id="edit" className="btn_edit"><i className="fas fa-pen"></i></button>

                                                    <button onClick={e => peticionDelete(comida._id)} id="remove" className="btn_remove"><i className="fas fa-trash"></i></button>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                )}
                            </table>

                        </div>

                        : permisos === "628c0ab8718beafc1c5ca26b" ?

                            <div className="content-addcomidas-table">
                                <table className="table">
                                    <thead className="thead">
                                        <tr>
                                            <th>#</th>
                                            <th>Nombre comida</th>
                                            <th>Presentación</th>
                                            <th>Precio</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>

                                    {com.length < 0 ? (
                                        <tbody className="tbody">



                                        </tbody>
                                    ) : (
                                        <tbody className="tbody">


                                            {comidas.map(comida =>

                                                <tr id="id_comida" key={comida._id}>
                                                    <td id="prod">{comida._id}</td>
                                                    <td id="nom_prod">{comida.nombre_comida}</td>
                                                    <td>{comida.presentacion_comida}</td>
                                                    <td>{`$${comida.precio}.00`}</td>
                                                    <td>
                                                        <button onClick={() => seleccionarComida(comida, "Editar")} id="edit" className="btn_edit"><i className="fas fa-pen"></i></button>

                                                        <button onClick={e => peticionDelete(comida._id)} id="remove" className="btn_remove"><i className="fas fa-trash"></i></button>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    )}
                                </table>

                            </div>
                            :
                            ""
                    }






                    <br />

                    {permisos === "628c073747bc232d04b1b5f4" ?
                        `Mostrando registros del ${datos / datos} al ${datos} de un total de ${datos}`
                        :
                        `Mostrando registros del ${com / com} al ${com} de un total de ${com}`
                    }

                </div>

            </div>


            <Footer />

        </Fragment>
    )
}

export default Products;