import React, { Fragment, useState } from "react";
import "./NavBar.css";
import logo from "../../images/logo.png";
import Cookies from 'universal-cookie';
import { Link } from "react-router-dom";

const cookies = new Cookies();

const NavBar = ({ estado, abrirmenu, primernombre }) => {


    const cerrarSesion = () => {
        cookies.remove('id_usuario', { path: "/" });
        cookies.remove('nombre_sucursal', { path: "/" });
        cookies.remove('telefono_sucursal', { path: "/" });
        cookies.remove('direccion_sucursal', { path: "/" });
        cookies.remove('id_sucursal', { path: "/" });
        cookies.remove('nombre_completo_usuario', { path: "/" });
        cookies.remove('edad_usuario', { path: "/" });
        cookies.remove('telefono_usuario', { path: "/" });
        cookies.remove('correo', { path: "/" });
        cookies.remove('nombre_cargo', { path: "/" });
        cookies.remove('id_permiso_usuario', { path: "/" });
        cookies.remove('token', { path: "/" });

        window.location.href = './';
    }


    const [abrircuadro, setAbrircuadro] = useState(false);

    const abrircuadroinfo = () => {
        setAbrircuadro(!abrircuadro);
    }

    const stylesinfodes = {
        display: "inline-block",
        textAlign: "center",
        width: "286px",
        height: "270px",
        paddingTop: "15px",
        background: "#0071B8",
        marginLeft: "-273px",
        marginTop: "42px",
        position: "absolute"

    };

    const stylesinfocer = {};

    let nombre_cargo = cookies.get('nombre_cargo');
    let permiso = cookies.get('id_permiso_usuario');

    return (
        <Fragment>

            <div className="content-navbar">
                <div className="content-navbar-top">
                    <div className="content-navbar-menu">
                        <Link to="/"><h2>El Pollo Rico <br></br> {parseInt(permiso) !== 3 ? cookies.get('nombre_sucursal') : "Super Admin"} </h2></Link>

                        <i onClick={abrirmenu} className={estado ? "fas fa-bars" : "fas fa-times"}></i>
                    </div>

                    <div onClick={abrircuadroinfo} className="content-navbar-profile">
                        <ul>
                            <li>  <i title="Menú" onClick={abrircuadroinfo} className={abrircuadro ? "fas fa-times" : "far fa-user-circle"}></i><label title="Menú" onClick={abrircuadroinfo} id="nom-abrev1">{primernombre}</label>

                                <ul style={abrircuadro ? stylesinfodes : stylesinfocer}>
                                    <li className="li">
                                        <img src={logo} alt="logo" />
                                        <label id="nom-abrev">{cookies.get('nombre_completo_usuario')}</label>
                                        <label id="correop">{cookies.get('correo')}</label>
                                        <label id="perfil"><strong>Cargo:{" "}</strong>{nombre_cargo}</label>
                                        <div className="btn">
                                            <button onClick={cerrarSesion} id="btn">Cerrar Sesión</button>
                                        </div>
                                    </li>
                                </ul>

                            </li>
                        </ul>

                    </div>
                </div>
            </div>


        </Fragment>
    )
}

export default NavBar;