import React, { Fragment, useState, useEffect } from "react";
import "./Providers.css";
import NavBar from '../../components/navBar/NavBar';
import Asidebar from '../../components/asidebar/Asidebar';
import Footer from '../../components/footer/Footer';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const Providers = () => {
    const baseUrl = window.$baseUrl;

    let permisos = cookies.get('id_permiso_usuario');
    let nombreperfil = cookies.get('nombre_completo_usuario') || "user";
    let primernombre = nombreperfil.split(" ", 1);

    const [menu, setMenu] = useState(true);

    const abrirmenu = () => {
        setMenu(!menu);

    }


    const styleagrandardashboard = {
        transitionDuration: "0.3s"
    };

    const stylencogerdashboard = {};


    return (
        <Fragment>


            {!cookies.get('correo') && <Redirect to="./" />}
            {permisos === "628c0a94718beafc1c5ca269" && <Redirect to="./" />}

            <NavBar estado={menu} abrirmenu={abrirmenu} primernombre={primernombre} />

            <div className="fondo-providers">

                <Asidebar estado={menu} />

                <div style={menu ? styleagrandardashboard : stylencogerdashboard} className="content-providers-main">

                    <label>Proveedores</label>




                </div>

            </div>


            <Footer />
        </Fragment>
    )
}

export default Providers;