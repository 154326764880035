import React, { Fragment, useEffect, useState } from "react";
import './ModalVerDetalleVenta.css';
import axios from 'axios';
import { saveAs } from 'file-saver';
import moment from "moment";


const ModalVerDetalleVenta = ({ dataDetalle, modalVisualizar, abrirCerrarModalVisualizar, setDataDetalle, dataTicket, setDataTicket }) => {

  // console.log(moment(dataDetalle).format('YYYY-MM-DD HH:mm:ss A'))

  // console.log(dataTicket)

  // const [data, setData] = useState({});

  // console.log(dataDetalle)

  const baseUrl = window.$baseUrl;
  const [cargando, serCargando] = useState(true);

  const reimprimirTicket = () => {

    serCargando(false)

    if (dataTicket.nota === "") {
      try {
        axios.post(baseUrl + '/api/reporte/reimprimir/create-pdf/' + dataTicket.folio + "/" + dataTicket.sucursal.name + "/" + dataTicket.sucursal.telefono_usuario + "/" + dataTicket.sucursal.direccion + "/" + dataTicket.efectivo + "/" + dataTicket.cambio + "/" + dataTicket.hora_actual, dataTicket.compras)
          .then(() => axios.get(baseUrl + '/api/reporte/reimprimir/fetch-pdf-ticket', { responseType: 'blob' }))
          .then((res) => {
            serCargando(true)
            const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
            saveAs(pdfBlob, 'Ticket.pdf');
          })
      } catch (e) {
        serCargando(true)
        console.log(e)
      }

      // serCargando(true)
    } else {
      try {
        axios.post(baseUrl + '/api/reporte/reimprimir/create-pdf/' + dataTicket.folio + "/" + dataTicket.sucursal.name + "/" + dataTicket.sucursal.telefono_usuario + "/" + dataTicket.sucursal.direccion + "/" + dataTicket.efectivo + "/" + dataTicket.cambio + "/" + dataTicket.nota + "/" + dataTicket.hora_actual, dataTicket.compras)
          .then(() => axios.get(baseUrl + '/api/reporte/reimprimir/fetch-pdf-ticket', { responseType: 'blob' }))
          .then((res) => {
            serCargando(true)
            const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
            saveAs(pdfBlob, 'Ticket.pdf');
          })
      } catch (e) {
        serCargando(true)
        console.log(e)
      }
      // serCargando(true)
    }


  }

  // const getTicket = async () => {
  //   try {
  //     await axios.get(baseUrl + "/api/ticket/" + folio)
  //       .then(response => {
  //         setData(response.data);
  //       }).catch(error => {
  //         console.log(error);
  //       })
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  // console.log(data)

  // useEffect(() => {
  //   getTicket()
  // }, [])

  return (
    <Fragment>

      <div className={modalVisualizar ? "Modal-desactive" : "Modal-active"}>
        <div className="contend-modalCs">
          <div className="ModalHeaders">
            <h2>Detalles</h2>
            <i className="fas fa-times" onClick={() => abrirCerrarModalVisualizar()} id="icon-close"></i>
          </div>
          <div className="ModalBodys">
            <div className="FormGroups">
              <div className="content-detalleVenta-table">
                <table className="table-detalleVenta">
                  <thead className="thead">
                    <tr>

                      {/* <th>#</th> */}
                      <th>Producto</th>
                      <th>Precio Unitario</th>
                      <th>Cantidad</th>
                      <th>Totales</th>
                      <th>Fecha venta</th>

                    </tr>
                  </thead>
                  <tbody className="tbody">


                    {dataDetalle.map(detalle =>

                      <tr id="id_comida" key={detalle._id}>
                        {/* <td>{detalle.id_detalle_venta}</td> */}
                        <td>{detalle.nombre_producto}</td>
                        <td>{`$${detalle.precio}.00`}</td>
                        <td>{detalle.cantidad_total}</td>
                        <td>{`$${detalle.precio_total}.00`}</td>
                        <td>{moment(new Date(detalle.fecha_venta)).format('YYYY-MM-DD HH:mm:ss A')}</td>
                        {/* {console.log(detalle.fecha_venta)} */}
                      </tr>

                    )}

                  </tbody>

                </table>


              </div>
              <div className="ModalFooter">
                <button onClick={reimprimirTicket} disabled={!cargando} id="yes">{cargando ? "Reimprimir Ticket" : "Cargando..."}</button>
                <button onClick={() => abrirCerrarModalVisualizar()} id="cancel">Cerrar</button>
              </div>


            </div>
          </div>
        </div>
      </div>

    </Fragment>
  );
}

export default ModalVerDetalleVenta;
