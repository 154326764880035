import React, { Fragment } from 'react';
import "./prueba.css";



const pruebas = () => {
    return (
        <Fragment>


            <div class="content-all-pdf">
                <div id="fecha-factura">

                    <strong>Fecha Corte: 2020-01-10 </strong>

                </div>
                <div class="content-detalle-corte">
                    <div id="detalle-sucursal">

                        <table>  
                            <th>Cajero(a) <br></br>  Luis Eduardo Negrón Chan</th>
                            <th>El Pollo Rico <br></br>  "Tekax"</th>
                            <th>Dirección <br></br> calle 30 entre 9 y 9a </th>
                            <th>Telefóno <br></br> 9971210804 </th>
                            <th id="reporte"> <strong>Reporte venta</strong></th>
                        </table>

                    </div>

                    <table id="detalles-reporte">
                        <tr>
                            <th>Nombre producto</th>
                            <th>Precio Unitario</th>
                            <th>cantidad</th>
                            <th>Totales</th>
                        </tr>

                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>

                    </table>

                    <div class="content-total-reporte">

                        <div>Total: </div>

                    </div>

                </div>
            
            </div>


        </Fragment>
    )
}


export default pruebas;