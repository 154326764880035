import React, { Fragment, useState } from "react";
import logo_email from "../../images/email.png";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import loading from "../../images/loading.gif";
import "./ForgotPassword.css";
import swal from 'sweetalert';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { Link, Redirect } from "react-router-dom";


const ForgotPassword = () => {

  const baseUrl = window.$baseUrl;

  const cookies = new Cookies();

  const [email_input, setEmail_input] = useState({
    email: "",
  });



  const [enviado, setEnviado] = useState(true);

  const stylesde = { background: "rgb(99, 125, 245)", color: "rgb(219, 219, 219)", cursor: "context-menu" };
  const stylesac = {};

  const handleChange = (event) => {
    // console.log(event.target.name)
    // console.log(event.target.value)
    setEmail_input({
      ...email_input,
      [event.target.name]: event.target.value,
    });
  };


  const recuperarContrasena = async (e) => {
    e.preventDefault();

    if (email_input.email.length <= 0) {
      swal({
        title: "¡Campo vacio!",
        text: "¡Ingresa tu correo electrónico!",
        icon: "warning",
        button: "Aceptar",
        timer: "3000"
      });
    } else {

      setEnviado(false);

      try {

        await axios.post(baseUrl + '/api/user/resetpass', {

          correo: email_input.email,

        }).then(response => {
          setEnviado(true);
          setEmail_input({
            email: "",
          })
          swal({
            title: "¡Contraseña enviada!",
            text: `¡La contraseña fue enviado a: ${email_input.email}!, sino encuentra el correo verifica en sección de correos no deseados o Spam`,
            icon: "success",
            button: "Aceptar",
            timer: "6000"
          });
        }).catch(e => {
          setEnviado(true);
          swal({
            title: "Error",
            text: `Usuario no encontrado`,
            icon: "warning",
            button: "Aceptar",
            timer: "6000"
          });
          console.log(e);
          setEmail_input({
            email: "",
          })
        })

      } catch (e) {
        console.log(e)
      }

      setEmail_input({
        email: "",
      })
    }
  }




  return (
    <Fragment>
      {cookies.get('correo') && <Redirect to="./dashboard" />}
      <Header />
      <div className="fondo-forgot-password">
        <div className="content-forgot-password">
          <form onSubmit={recuperarContrasena} method="#" className="form">

            {enviado ? <img src={logo_email} alt="Logo_email" /> : <img src={loading} id="loading" alt="Loading..." />}

            <h1>¿Olvidaste tu contraseña?</h1>
            <p>
              ¡Ingresa el correo electrónico que usaste al momento de{" "}
              registrarte para recuperar tu contraseña!
            </p>
            <label id="label">Correo</label>

            <input
              id="email"
              name="email"
              type="email"
              value={email_input.email}
              placeholder="Correo electrónico"
              required
              onChange={handleChange}
            />

            <input style={enviado ? stylesac : stylesde} onClick={recuperarContrasena} disabled={enviado ? "" : "true"} id="send" type="submit" value={enviado ? "Enviar" : "Enviando..."} />
          </form>
        </div>
      </div>
      <footer className="footer">
        <Link to="/Login">
          <h3>
            {" "}
            <i className="fas fa-arrow-left"></i>Regresar
          </h3>
        </Link>
      </footer>
      <Footer />
    </Fragment>
  );
};

export default ForgotPassword;
