import React, { Fragment, useState, useEffect } from 'react';
import './AddUser.css';
import Footer from '../../components/footer/Footer';
import NavBar from '../../components/navBar/NavBar';
import Asidebar from '../../components/asidebar/Asidebar';
import ModalAltaUsuarios from '../../components/commons/modalUsuarios/ModalAltaUsuarios';
import ModalEditarUsuarios from '../../components/commons/modalUsuarios/ModalEditarUsuarios';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import swal from 'sweetalert';
import Cookies from 'universal-cookie';
import moment from 'moment';

const cookies = new Cookies();

const AddUser = () => {

    const baseUrl = window.$baseUrl;
    let id_sucursal = cookies.get('id_sucursal');
    let permisos = cookies.get('id_permiso_usuario');
    let nombreperfil = cookies.get('nombre_completo_usuario') || "user";
    let primernombre = nombreperfil.split(" ", 1);

    const [data, setData] = useState([]);
    const [usuarios, setUsuarios] = useState([]);
    const [modalInsertar, setModalInsertar] = useState(false);
    const [modalEditar, setModalEditar] = useState(false);
    const [modalEliminar, setModalEliminar] = useState(false);
    const [usuarioSeleccionado, setUsuarioSeleccionado] = useState({
        id_usuario: "",
        id_sucursal: "",
        nombre_completo_usuario: "",
        edad_usuario: "",
        telefono_usuario: "",
        correo: "",
        contrasena: "",
        id_cargo_usuario: "",
        id_permiso_usuario: "",
    });

    const handleChange = e => {
        const { name, value } = e.target;
        setUsuarioSeleccionado((prevState) => ({
            ...prevState,
            [name]: value
        }))

    }


    const abrirCerrarModalInsertar = () => {
        setModalInsertar(!modalInsertar);
        document.getElementById("Form-insertarU").reset();
    }

    const abrirCerrarModalEditar = () => {
        setModalEditar(!modalEditar);
    }

    const abrirCerrarModalEliminar = () => {
        setModalEliminar(!modalEliminar);
    }

    const peticionGet = async () => {

        try {
            await axios.get(baseUrl + "/api/user/suc/cantidad/" + id_sucursal)
                .then(response => {
                    setData(response.data);
                }).catch(error => {
                    console.log(error);
                })
        } catch (error) {
            console.log(error)
        }


    }

    const peticionAllUsers = async () => {

        try {
            await axios.get(baseUrl + "/api/user/cantidad/root")
                .then(response => {
                    setUsuarios(response.data);
                }).catch(error => {
                    console.log(error);
                })
        } catch (error) {
            console.log(error)
        }


    }


    // console.log(permisos)

    const peticionPost = async (e) => {

        let caracteres = "abcdefghijkmnpqrtuvwxyzABCDEFGHJKMNPQRTUVWXYZ2346789";
        let GenerarContrasena = "";
        for (let i = 0; i < 20; i++) GenerarContrasena += caracteres.charAt(Math.floor(Math.random() * caracteres.length));


        e.preventDefault();

        if (permisos === "628c073747bc232d04b1b5f4") {

            try {
                await axios.post(baseUrl + "/api/user/new", {
                    sucursal: id_sucursal,
                    nombre_completo_usuario: usuarioSeleccionado.nombre_completo_usuario,
                    edad_usuario: usuarioSeleccionado.edad_usuario,
                    telefono_usuario: usuarioSeleccionado.telefono_usuario,
                    correo: usuarioSeleccionado.correo,
                    contrasena: GenerarContrasena,
                    cargo: usuarioSeleccionado.id_cargo_usuario,
                    rol: usuarioSeleccionado.id_permiso_usuario
                }).then(response => {
                    console.log(response);

                }).catch(error => {
                    console.log(error);
                })
            } catch (error) {
                console.log(error)
            }


        } else if (permisos === "628c0ab8718beafc1c5ca26b") {

            try {
                await axios.post(baseUrl + "/api/user/new", {
                    sucursal: usuarioSeleccionado.id_sucursal,
                    nombre_completo_usuario: usuarioSeleccionado.nombre_completo_usuario,
                    edad_usuario: usuarioSeleccionado.edad_usuario,
                    telefono_usuario: usuarioSeleccionado.telefono_usuario,
                    correo: usuarioSeleccionado.correo,
                    contrasena: GenerarContrasena,
                    cargo: usuarioSeleccionado.id_cargo_usuario,
                    rol: usuarioSeleccionado.id_permiso_usuario
                }).then(response => {
                    console.log(response);
                    if (response.data.ok === true) {
                        enviarCredencialesUsuarios(GenerarContrasena);
                        peticionGet();
                        peticionAllUsers();
                        abrirCerrarModalInsertar();
                    } else {
                        alert(`${response?.data?.msg}`)
                    }

                }).catch(error => {
                    console.log(error);
                })
            } catch (error) {
                console.log(error)
            }


        }
    }

    const enviarCredencialesUsuarios = (GenerarContrasena) => {

        try {
            axios.post(baseUrl + '/api/user/enviarcredenciales', {
                nombre: usuarioSeleccionado.nombre_completo_usuario,
                email: usuarioSeleccionado.correo,
                password: GenerarContrasena
            }).then(response => {

            }).catch(e => {
                console.log(e);
            });
        } catch (error) {
            console.log(error)
        }

    }


    const peticionPut = async (e) => {
        e.preventDefault();

        if (permisos === "628c073747bc232d04b1b5f4") {

            try {
                await axios.put(baseUrl + "/api/user/update/" + usuarioSeleccionado._id, {

                    _id: usuarioSeleccionado._id,
                    sucursal: id_sucursal,
                    nombre_completo_usuario: usuarioSeleccionado.nombre_completo_usuario,
                    edad_usuario: usuarioSeleccionado.edad_usuario,
                    telefono_usuario: usuarioSeleccionado.telefono_usuario,
                    correo: usuarioSeleccionado.correo,
                    contrasena: usuarioSeleccionado.contrasena,
                    cargo: usuarioSeleccionado.id_cargo_usuario,
                    rol: usuarioSeleccionado.id_permiso_usuario

                })
                    .then(response => {
                        console.log(response);

                    })
                    .catch(error => {
                        console.log(error);
                    });
            } catch (error) {
                console.log(error)
            }



        } else if (permisos === "628c0ab8718beafc1c5ca26b") {

            try {
                await axios.put(baseUrl + "/api/user/update/" + usuarioSeleccionado._id, {

                    _id: usuarioSeleccionado._id,
                    sucursal: usuarioSeleccionado.id_sucursal,
                    nombre_completo_usuario: usuarioSeleccionado.nombre_completo_usuario,
                    edad_usuario: usuarioSeleccionado.edad_usuario,
                    telefono_usuario: usuarioSeleccionado.telefono_usuario,
                    correo: usuarioSeleccionado.correo,
                    contrasena: usuarioSeleccionado.contrasena,
                    cargo: usuarioSeleccionado.id_cargo_usuario,
                    rol: usuarioSeleccionado.id_permiso_usuario

                })
                    .then(response => {
                        console.log(response);

                    })
                    .catch(error => {
                        console.log(error);
                    });
            } catch (error) {
                console.log(error)
            }



        }



        peticionGet();
        peticionAllUsers();
        abrirCerrarModalEditar();
    }


    const peticionDelete = (id) => {
        swal({
            title: "¿Estás seguro?",
            text: "¡Una vez eliminado no podrás recuperar la información!",
            icon: "warning",
            buttons: ["Cancelar", "Aceptar"],
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                    try {
                        axios.put(baseUrl + "/api/user/delete/" + id, {

                        }).then(response => {

                            swal("El usuario fue borrado correctamente", {
                                icon: "success",
                                button: "Aceptar",
                                timer: "2000"
                            });

                            peticionGet();
                            peticionAllUsers();


                        }).catch(e => {
                            console.log(e);
                        });

                    } catch (error) {
                        console.log(error)
                    }


                }
            });

    }



    const buscarUsuarioSucursal = async () => {
        let valor = document.getElementById("filtro").value;

        if (valor == "") {
            try {
                await axios.get(baseUrl + "/api/user/cantidad/root")
                    .then(response => {
                        setUsuarios(response.data);
                    }).catch(error => {
                        console.log(error);
                    })
            } catch (error) {
                console.log(error)
            }
        } else {
            try {
                await axios.get(baseUrl + "/api/user/suc/cantidad/" + valor)
                    .then(response => {
                        setUsuarios(response.data);
                    }).catch(error => {
                        console.log(error);
                    })
            } catch (error) {
                console.log(error)
            }
        }

    }



    const [sucursalSeleccionadoRoot, setSucursalSeleccionadoRoot] = useState([]);

    const peticionS_Root = async () => {


        try {
            await axios.get(baseUrl + "/api/sucursal")
                .then(response => {
                    setSucursalSeleccionadoRoot(response.data);
                }).catch(error => {
                    console.log(error);
                })
        } catch (error) {
            console.log(error)
        }


    }




    const seleccionarUsuario = (usuario, caso) => {
        window.location.href = "adduser#arriba";
        setUsuarioSeleccionado(usuario);

        (caso === "Editar") ?
            abrirCerrarModalEditar() :
            abrirCerrarModalEliminar()
    }

    useEffect(() => {
        peticionS_Root();
        peticionAllUsers();
    }, [])



    useEffect(() => {
        peticionGet();
    }, [])



    const [menu, setMenu] = useState(true);

    const abrirmenu = () => {
        setMenu(!menu);

    }

    const styleagrandardashboard = {
        transitionDuration: "0.3s"
    };

    const stylencogerdashboard = {

    };

    const datos = data.length;
    const usu = usuarios.length;

    return (
        <Fragment>
            <div id="arriba"></div>
            {!cookies.get('correo') && <Redirect to="./" />}
            {permisos === "628c0a94718beafc1c5ca269" && <Redirect to="./" />}

            {modalEditar ?
                <ModalEditarUsuarios usuarioSeleccionado={usuarioSeleccionado} seleccionarUsuario={seleccionarUsuario} peticionPut={peticionPut} modalInsertar={modalInsertar} peticionPost={peticionPost} modalEditar={modalEditar} abrirCerrarModalEditar={abrirCerrarModalEditar} abrirCerrarModalInsertar={abrirCerrarModalInsertar} handleChange={handleChange} />
                :
                <ModalAltaUsuarios usuarioSeleccionado={usuarioSeleccionado} seleccionarUsuario={seleccionarUsuario} peticionPut={peticionPut} modalInsertar={modalInsertar} peticionPost={peticionPost} modalEditar={modalEditar} abrirCerrarModalEditar={abrirCerrarModalEditar} abrirCerrarModalInsertar={abrirCerrarModalInsertar} handleChange={handleChange} />
            }

            <NavBar estado={menu} abrirmenu={abrirmenu} primernombre={primernombre} />

            <div className="fondo-addsucursal">
                <Asidebar estado={menu} />


                <div style={menu ? styleagrandardashboard : stylencogerdashboard} className="content-addsucursal-main">

                    <label>Administrar usuarios</label>
                    <div className="btn_addsucursal">
                        <button onClick={() => abrirCerrarModalInsertar()} id="btn_addsucursal">Agregar usuario <i className="fas fa-plus"></i></button>
                    </div>

                    {permisos !== "628c0ab8718beafc1c5ca26b" ?
                        ""
                        :
                        <div className="select_table">

                            <select onChange={buscarUsuarioSucursal} id="filtro" name="filtro">
                                <option value="" defaultValue>Seleccione una opción</option>

                                {sucursalSeleccionadoRoot.map(sucursales =>

                                    <option key={sucursales._id} value={sucursales._id}>{sucursales.name}</option>

                                )}

                            </select>
                        </div>
                    }

                    {permisos === "628c073747bc232d04b1b5f4" ?
                        <div className="content-addsucursal-table">
                            <table className="table">
                                <thead className="thead">
                                    <tr>
                                        {/* <th>#</th> */}
                                        <th>Nombre completo</th>
                                        <th>Sucursal</th>
                                        <th>F. Nac</th>
                                        <th>Teléfono</th>
                                        <th>Correo electrónico</th>
                                        <th>Cargo</th>
                                        <th>Perfil</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>

                                {datos.length < 0 ? (
                                    <tbody className="tbody">



                                    </tbody>
                                ) : (
                                    <tbody className="tbody">


                                        {data.map(usuario =>

                                            <tr id="id_usuario" key={usuario._id}>
                                                {/* <td id="id_user">{usuario._id}</td> */}
                                                <td id="nom_usuario">{usuario.nombre_completo_usuario}</td>
                                                <td>{usuario.sucursal ? usuario.sucursal.name : ""}</td>
                                                <td>{usuario.edad_usuario ? moment(usuario.edad_usuario).format("DD/MM/YYYY") : ""}</td>
                                                <td>{usuario.telefono_usuario}</td>
                                                <td id="correoU">{usuario.correo}</td>
                                                <td>{usuario.cargo ? usuario.cargo.name : ""}</td>
                                                <td>{usuario.rol ? usuario.rol.name : ""}</td>
                                                <td>
                                                    <button onClick={() => seleccionarUsuario(usuario, "Editar")} id="edit" className="btn_edit"><i className="fas fa-pen"></i></button>

                                                    <button onClick={e => peticionDelete(usuario._id)} id="remove" className="btn_remove"><i className="fas fa-trash"></i></button>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                )}
                            </table>

                        </div>

                        : permisos === "628c0ab8718beafc1c5ca26b" ?
                            <div className="content-addsucursal-table">
                                <table className="table">
                                    <thead className="thead">
                                        <tr>
                                            {/* <th>#</th> */}
                                            <th>Nombre completo</th>
                                            <th>Sucursal</th>
                                            <th>F. Nac</th>
                                            <th>Teléfono</th>
                                            <th>Correo electrónico</th>
                                            <th>Cargo</th>
                                            <th>Perfil</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>

                                    {usu.length < 0 ? (
                                        <tbody className="tbody">



                                        </tbody>
                                    ) : (
                                        <tbody className="tbody">


                                            {usuarios.map(usuario =>

                                                <tr id="id_usuario" key={usuario._id}>
                                                    {/* <td id="id_user">{usuario._id}</td> */}
                                                    <td id="nom_usuario">{usuario.nombre_completo_usuario}</td>
                                                    <td>{usuario.sucursal ? usuario.sucursal.name : ""}</td>
                                                    <td>{usuario.edad_usuario ? moment(usuario.edad_usuario).format("DD/MM/YYYY") : ""}</td>
                                                    <td>{usuario.telefono_usuario}</td>
                                                    <td id="correoU">{usuario.correo}</td>
                                                    <td>{usuario.cargo ? usuario.cargo.name : ""}</td>
                                                    <td>{usuario.rol ? usuario.rol.name : ""}</td>
                                                    <td>
                                                        <button onClick={() => seleccionarUsuario(usuario, "Editar")} id="edit" className="btn_edit"><i className="fas fa-pen"></i></button>

                                                        <button onClick={e => peticionDelete(usuario._id)} id="remove" className="btn_remove"><i className="fas fa-trash"></i></button>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    )}
                                </table>

                            </div>
                            :

                            ""
                    }
                    <br />

                    {permisos === "628c073747bc232d04b1b5f4" ?
                        `Mostrando registros del ${datos / datos} al ${datos} de un total de ${datos}`
                        :
                        `Mostrando registros del ${usu / usu} al ${usu} de un total de ${usu}`
                    }


                </div>
            </div>
            <Footer />
        </Fragment>
    )
}


export default AddUser;