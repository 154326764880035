import React, { Fragment } from "react";
import './ModalSucursales.css';



const ModalAltaSucursales = ({ modalEditar, modalInsertar, peticionPut, peticionPost, seleccionarSucursal, sucursalSeleccionado, abrirCerrarModalEditar, abrirCerrarModalInsertar, handleChange }) => {




  return (
    <Fragment>
      <div className={modalInsertar ? "Modal-desactive" : "Modal-active"}>
        <div className="contend-modalC">
          <div className="ModalHeader">
            <h2>Agregar Sucursal</h2>
            <i className="fas fa-times" onClick={() => abrirCerrarModalInsertar()} id="icon-close"></i>
          </div>
          <div className="ModalBody">
            <div className="FormGroup">
              <form onSubmit={peticionPost} id="Form-insertar">
                <label htmlFor="nombre_sucursal">Nombre de sucursal</label>
                <input onChange={handleChange} name="nombre_sucursal" type="text" required />

                <label htmlFor="nombre_sucursal">Abreviatura sucursal</label>
                <input onChange={handleChange} name="abreviatura" type="text" required />

                <label htmlFor="numero_tel">Teléfono</label>
                <input onChange={handleChange} name="numero_tel" minLength="10" maxLength="10" required type="text" />



                <label htmlFor="direccion">Dirección</label>
                <input onChange={handleChange} name="direccion" type="text" required />

                <div className="ModalFooter">
                  <input id="yes" type="submit" value="Guardar" />
                  <button onClick={() => abrirCerrarModalInsertar()} id="cancel">Cancelar</button>
                </div>

              </form>




            </div>
          </div>
        </div>
      </div>



    </Fragment>
  );
}

export default ModalAltaSucursales;
