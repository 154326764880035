import React, { Fragment, useState, useEffect } from 'react';
import "./Drinks.css";
import NavBar from '../../components/navBar/NavBar';
import Asidebar from '../../components/asidebar/Asidebar';
import ModalAltaBebidas from '../../components/commons/modalBebidas/ModalAltaBebidas';
import ModalEditarBebidas from '../../components/commons/modalBebidas/ModalEditarBebidas';
import Footer from '../../components/footer/Footer';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import swal from 'sweetalert';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const Products = () => {

    const baseUrl = window.$baseUrl;

    let id_sucursal = cookies.get('id_sucursal');
    let permisos = cookies.get('id_permiso_usuario');
    let nombreperfil = cookies.get('nombre_completo_usuario') || 'user';
    let primernombre = nombreperfil.split(" ", 1);


    const [data, setData] = useState([]);
    const [bebidas, setBebidas] = useState([]);
    const [modalInsertar, setModalInsertar] = useState(false);
    const [modalEditar, setModalEditar] = useState(false);
    const [modalEliminar, setModalEliminar] = useState(false);
    const [bebidaSeleccionado, setBebidaSeleccionado] = useState({
        id_bebida: "",
        id_sucursal: "",
        nombre_bebida: "",
        id_clasificacion_bebidas: "",
        id_presentacion_bebidas: "",
        tamano: "",
        precio: ""

    });

    const handleChange = e => {
        const { name, value } = e.target;
        setBebidaSeleccionado((prevState) => ({
            ...prevState,
            [name]: value
        }))

    }


    const abrirCerrarModalInsertar = () => {
        document.getElementById("Form-insertar").reset();
        setModalInsertar(!modalInsertar);

    }

    const abrirCerrarModalEditar = () => {
        setModalEditar(!modalEditar);
    }

    const abrirCerrarModalEliminar = () => {
        setModalEliminar(!modalEliminar);
    }

    const peticionGet = async () => {

        try {
            await axios.get(baseUrl + "/api/bebida/suc/cantidad/" + id_sucursal)
                .then(response => {
                    setData(response.data);
                }).catch(error => {
                    console.log(error);
                })
        } catch (e) {
            console.log(e)
        }


    }

    const peticionAllGet = async () => {

        try {
            await axios.get(baseUrl + "/api/bebida/cantidad/root")
                .then(response => {
                    setBebidas(response.data);
                }).catch(error => {
                    console.log(error);
                })
        } catch (e) {
            console.log(e)
        }


    }


    const peticionPost = async (e) => {
        e.preventDefault();

        if (permisos === "628c073747bc232d04b1b5f4") {

            try {
                await axios.post(baseUrl + "/api/bebida/new", {
                    id_bebida: bebidaSeleccionado.id_bebida,
                    sucursal: id_sucursal,
                    nombre_bebida: bebidaSeleccionado.nombre_bebida,
                    clasificacion: bebidaSeleccionado.id_clasificacion_bebidas,
                    presentacion: bebidaSeleccionado.id_presentacion_bebidas,
                    tamano: bebidaSeleccionado.tamano,
                    precio: bebidaSeleccionado.precio,
                    status: true
                }).then(response => {
                    console.log(response);
                }).catch(error => {
                    console.log(error);
                })
            } catch (e) {
                console.log(e)
            }


        } else if (permisos === "628c0ab8718beafc1c5ca26b") {

            try {
                await axios.post(baseUrl + "/api/bebida/new", {
                    id_bebida: bebidaSeleccionado.id_bebida,
                    sucursal: bebidaSeleccionado.id_sucursal,
                    nombre_bebida: bebidaSeleccionado.nombre_bebida,
                    clasificacion: bebidaSeleccionado.id_clasificacion_bebidas,
                    presentacion: bebidaSeleccionado.id_presentacion_bebidas,
                    tamano: bebidaSeleccionado.tamano,
                    precio: bebidaSeleccionado.precio,
                    status: true
                }).then(response => {
                    console.log(response);
                }).catch(error => {
                    console.log(error);
                })
            } catch (e) {
                console.log(e)
            }


        }


        peticionGet();
        peticionAllGet();
        abrirCerrarModalInsertar();
    }

    const peticionPut = async (e) => {
        e.preventDefault();

        if (permisos === "628c073747bc232d04b1b5f4") {


            try {
                await axios.put(baseUrl + "/api/bebida/" + bebidaSeleccionado._id, {

                    _id: bebidaSeleccionado._id,
                    sucursal: id_sucursal,
                    nombre_bebida: bebidaSeleccionado.nombre_bebida,
                    clasificacion: bebidaSeleccionado.id_clasificacion_bebidas,
                    presentacion: bebidaSeleccionado.id_presentacion_bebidas,
                    tamano: bebidaSeleccionado.tamano,
                    precio: bebidaSeleccionado.precio

                })
                    .then(response => {
                        console.log(response);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            } catch (e) {
                console.log(e)
            }



        } else if (permisos === "628c0ab8718beafc1c5ca26b") {


            try {
                await axios.put(baseUrl + "/api/bebida/" + bebidaSeleccionado._id, {

                    _id: bebidaSeleccionado._id,
                    sucursal: bebidaSeleccionado.id_sucursal,
                    nombre_bebida: bebidaSeleccionado.nombre_bebida,
                    clasificacion: bebidaSeleccionado.id_clasificacion_bebidas,
                    presentacion: bebidaSeleccionado.id_presentacion_bebidas,
                    tamano: bebidaSeleccionado.tamano,
                    precio: bebidaSeleccionado.precio

                })
                    .then(response => {
                        console.log(response);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            } catch (e) {
                console.log(e)
            }


        }


        peticionGet();
        peticionAllGet();
        abrirCerrarModalEditar();

    }


    const peticionDelete = (id) => {
        swal({
            title: "¿Estás seguro?",
            text: "¡Una vez eliminado no podrás recuperar la información!",
            icon: "warning",
            buttons: ["Cancelar", "Aceptar"],
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                    try {
                        axios.put(baseUrl + '/api/bebida/delete/' + id, {

                        }).then(response => {



                            swal("La bebida fue borrado correctamente", {
                                icon: "success",
                                button: "Aceptar",
                                timer: "2000"
                            });

                            peticionGet();
                            peticionAllGet();

                        }).catch(e => {
                            console.log(e);
                        });
                    } catch (e) {
                        console.log(e)
                    }



                }
            });

    }

    const seleccionarBebida = (bebida, caso) => {
        window.location.href = "drinks#arriba";
        setBebidaSeleccionado(bebida);

        (caso === "Editar") ?
            abrirCerrarModalEditar() :
            abrirCerrarModalEliminar()
    }

    const [sucursalSeleccionadoRoot, setSucursalSeleccionadoRoot] = useState([]);

    const peticionS_Root = async () => {

        try {
            await axios.get(baseUrl + "/api/sucursal")
                .then(response => {
                    setSucursalSeleccionadoRoot(response.data);
                }).catch(error => {
                    console.log(error);
                })
        } catch (e) {
            console.log(e)
        }


    }

    const buscarUsuarioSucursal = async () => {
        let valor = document.getElementById("filtro").value;

        if (valor === "") {
            try {
                await axios.get(baseUrl + "/api/bebida/cantidad/root")
                    .then(response => {
                        setBebidas(response.data);
                    }).catch(error => {
                        console.log(error);
                    })
            } catch (error) {
                console.log(error)
            }

        } else {
            try {
                await axios.get(baseUrl + "/api/bebida/suc/cantidad/" + valor)
                    .then(response => {
                        setBebidas(response.data);
                    }).catch(error => {
                        console.log(error);
                    })
            } catch (error) {
                console.log(error)
            }
        }


    }


    useEffect(() => {
        peticionS_Root();
        peticionGet();
        peticionAllGet();
    }, [])

    const [menu, setMenu] = useState(true);

    const abrirmenu = () => {
        setMenu(!menu);

    }

    const styleagrandardashboard = {
        transitionDuration: "0.3s"
    };

    const stylencogerdashboard = {};

    const datos = data.length;
    const beb = bebidas.length;


    return (
        <Fragment>
            <div id="arriba"></div>

            {!cookies.get('correo') && <Redirect to="./" />}
            {permisos === "628c0a94718beafc1c5ca269" && <Redirect to="./" />}

            {modalEditar ?
                <ModalEditarBebidas bebidaSeleccionado={bebidaSeleccionado} seleccionarBebida={seleccionarBebida} peticionPut={peticionPut} modalInsertar={modalInsertar} peticionPost={peticionPost} modalEditar={modalEditar} abrirCerrarModalEditar={abrirCerrarModalEditar} abrirCerrarModalInsertar={abrirCerrarModalInsertar} handleChange={handleChange} />
                :
                <ModalAltaBebidas bebidaSeleccionado={bebidaSeleccionado} seleccionarBebida={seleccionarBebida} peticionPut={peticionPut} modalInsertar={modalInsertar} peticionPost={peticionPost} modalEditar={modalEditar} abrirCerrarModalEditar={abrirCerrarModalEditar} abrirCerrarModalInsertar={abrirCerrarModalInsertar} handleChange={handleChange} />
            }



            <NavBar estado={menu} abrirmenu={abrirmenu} primernombre={primernombre} />

            <div className="fondo-addcomidas">

                <Asidebar estado={menu} />

                <div style={menu ? styleagrandardashboard : stylencogerdashboard} className="content-addcomidas-main">

                    <label>Administrar Bebidas</label>
                    <div className="btn_addcomidas">
                        <button onClick={() => abrirCerrarModalInsertar()} id="btn_addcomidas">Agregar bebida <i className="fas fa-plus"></i></button>
                    </div>

                    {permisos !== "628c0ab8718beafc1c5ca26b" ?
                        ""
                        :
                        <div className="select_table">

                            <select onChange={buscarUsuarioSucursal} id="filtro" name="filtro">
                                <option value="" defaultValue>Seleccione una opción</option>

                                {sucursalSeleccionadoRoot.map(sucursales =>

                                    <option key={sucursales._id} value={sucursales._id}>{sucursales.name}</option>

                                )}

                            </select>
                        </div>
                    }

                    {permisos === "628c073747bc232d04b1b5f4" ?

                        <div className="content-addcomidas-table">
                            <table className="table">
                                <thead className="thead">
                                    <tr>
                                        <th>#</th>
                                        <th>Nombre bebida</th>
                                        <th>Clasificación</th>
                                        <th>Presentación</th>
                                        <th>Tamaño</th>
                                        <th>Precio</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>

                                {datos.length < 0 ? (
                                    <tbody className="tbody">



                                    </tbody>
                                ) : (
                                    <tbody className="tbody">


                                        {data.map(bebida =>

                                            <tr id="id_comida" key={bebida._id}>
                                                <td id="prod">{bebida._id}</td>
                                                <td id="nom_prod">{bebida.nombre_bebida}</td>
                                                <td>{bebida.clasificacion.name}</td>
                                                <td>{bebida.presentacion.name}</td>
                                                <td>{bebida.tamano}</td>
                                                <td>{`$${bebida.precio}.00`}</td>
                                                <td>
                                                    <button onClick={() => seleccionarBebida(bebida, "Editar")} id="edit" className="btn_edit"><i className="fas fa-pen"></i></button>

                                                    <button onClick={e => peticionDelete(bebida._id)} id="remove" className="btn_remove"><i className="fas fa-trash"></i></button>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                )}
                            </table>

                        </div>
                        :
                        <div className="content-addcomidas-table">
                            <table className="table">
                                <thead className="thead">
                                    <tr>
                                        <th>#</th>
                                        <th>Nombre bebida</th>
                                        <th>Clasificación</th>
                                        <th>Presentación</th>
                                        <th>Tamaño</th>
                                        <th>Precio</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>

                                {datos.length < 0 ? (
                                    <tbody className="tbody">



                                    </tbody>
                                ) : (
                                    <tbody className="tbody">


                                        {bebidas.map(bebida =>

                                            <tr id="id_comida" key={bebida._id}>
                                                <td id="prod">{bebida._id}</td>
                                                <td id="nom_prod">{bebida.nombre_bebida}</td>
                                                <td>{bebida.clasificacion.name}</td>
                                                <td>{bebida.presentacion.name}</td>
                                                <td>{bebida.tamano}</td>
                                                <td>{`$${bebida.precio}.00`}</td>
                                                <td>
                                                    <button onClick={() => seleccionarBebida(bebida, "Editar")} id="edit" className="btn_edit"><i className="fas fa-pen"></i></button>

                                                    <button onClick={e => peticionDelete(bebida._id)} id="remove" className="btn_remove"><i className="fas fa-trash"></i></button>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                )}
                            </table>

                        </div>
                    }
                    <br />

                    {permisos === "628c073747bc232d04b1b5f4" ?
                        `Mostrando registros del ${datos / datos} al ${datos} de un total de ${datos}`
                        :
                        `Mostrando registros del ${beb / beb} al ${beb} de un total de ${beb}`
                    }

                </div>

            </div>


            <Footer />

        </Fragment>
    )
}

export default Products;