import React, { Fragment, useState, useEffect } from "react";
import './ModalComplementos.css';
import axios from 'axios';
import Cookies from 'universal-cookie';


const cookies = new Cookies();


const ModalAltaComplementos = ({ modalInsertar, peticionPut, peticionPost, seleccionarComplemento, complementoSeleccionado, abrirCerrarModalEditar, abrirCerrarModalInsertar, handleChange }) => {
  const baseUrl = window.$baseUrl;
  let permisos = cookies.get('id_permiso_usuario');


  const [sucursalSeleccionado, setSucursalSeleccionado] = useState([]);

  const [sucursalSeleccionadoRoot, setSucursalSeleccionadoRoot] = useState([]);

  const peticionS = async () => {
    await axios.get(baseUrl + "/api/sucursal")
      .then(response => {
        setSucursalSeleccionado(response.data);
      }).catch(error => {
        console.log(error);
      })
  }

  const peticionS_Root = async () => {
    await axios.get(baseUrl + "/api/sucursal")
      .then(response => {
        setSucursalSeleccionadoRoot(response.data);
      }).catch(error => {
        console.log(error);
      })
  }


  useEffect(() => {
    peticionS();
    peticionS_Root();

  }, [])



  return (
    <Fragment>
      <div className={modalInsertar ? "Modal-desactive" : "Modal-active"}>
        <div className="contend-modalC">
          <div className="ModalHeader">
            <h2>Agregar Complemento</h2>
            <i className="fas fa-times" onClick={() => abrirCerrarModalInsertar()} id="icon-close"></i>
          </div>
          <div className="ModalBody">
            <div className="FormGroup">
              <form onSubmit={peticionPost} id="Form-insertar">

                {permisos !== "628c0ab8718beafc1c5ca26b" ?
                  ""
                  // <label htmlFor="id_sucursal">Sucursal</label>
                  :
                  <label htmlFor="id_sucursal">Sucursal</label>
                }

                {permisos !== "628c0ab8718beafc1c5ca26b" ?
                  ""
                  // <select onChange={handleChange} id="id_sucursal" required name="id_sucursal">
                  //   <option value="" defaultValue>Seleccione una opción</option>
                  //   {sucursalSeleccionado.map(items =>
                  //     <option key={items.id_sucursal} value={items.id_sucursal}>{items.nombre_sucursal}</option>
                  //   )}
                  // </select>
                  :
                  <select onChange={handleChange} id="id_sucursal" required name="id_sucursal">
                    <option value="" defaultValue>Seleccione una opción</option>
                    {sucursalSeleccionadoRoot.map(items =>
                      <option key={items._id} value={items._id}>{items.name}</option>
                    )}
                  </select>
                }

                <label htmlFor="nombre_complemento">Nombre de complemento</label>
                <input onChange={handleChange} name="nombre_complemento" required type="text" />

                <label htmlFor="precio_complemento">Precio</label>
                <input onChange={handleChange} step=".01" name="precio_complemento" required type="number" />

                <div className="ModalFooter">
                  <input id="yes" type="submit" value="Guardar" />
                  <button onClick={() => abrirCerrarModalInsertar()} id="cancel">Cancelar</button>
                </div>

              </form>





            </div>
          </div>
        </div>
      </div>



    </Fragment>
  );
}

export default ModalAltaComplementos;
