import React, { Fragment, useState, useEffect } from "react";
import "./ReportsRoot.css";
import NavBar from '../../components/navBar/NavBar';
import Asidebar from '../../components/asidebar/Asidebar';
import Footer from '../../components/footer/Footer';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import moment from "moment";

const cookies = new Cookies();

const ReportsRoot = () => {
    const baseUrl = window.$baseUrl;

    let permisos = cookies.get('id_permiso_usuario');
    let nombreperfil = cookies.get('nombre_completo_usuario') || "user";
    let primernombre = nombreperfil.split(" ", 1);


    const [data, setData] = useState([]);
    const [CorteSeleccionado, setCorteSeleccionado] = useState([]);



    const peticionGet = async () => {
        await axios.get(baseUrl + "/api/corte")
            .then(response => {
                setData(response.data);
                setCorteSeleccionado(response.data);
            }).catch(error => {
                console.log(error);
            })
    }


    const VentasHoy = async () => {

        var now = new Date();
        var year = now.getFullYear();
        var month = now.getMonth() + 1;
        var day = now.getDate();
        var manana = now.getDate() + 1;


        if (month.toString().length === 1) {
            var month = '0' + month;
        }
        if (day.toString().length === 1) {
            var day = '0' + day;
        }
        if (manana.toString().length === 1) {
            var manana = '0' + manana;
        }

        var i = year + '-' + month + '-' + day;
        var f = year + '-' + month + '-' + manana;

        await axios.get(baseUrl + "/api/cortes/obtener/root/todas/" + i + "/" + f)
            .then(response => {
                setData(response.data);
                setData(response.data);

            }).catch(error => {
                console.log(error);
            })
    }

    const VentasAyer = async () => {

        var now = new Date();
        var year = now.getFullYear();
        var month = now.getMonth() + 1;
        var day = now.getDate();
        var ayer = now.getDate() - 1;

        if (month.toString().length === 1) {
            var month = '0' + month;
        }
        if (day.toString().length === 1) {
            var day = '0' + day;
        }

        var i = year + '-' + month + '-' + ayer;
        var f = year + '-' + month + '-' + day;

        await axios.get(baseUrl + "/api/cortes/obtener/root/todas/" + i + "/" + f)
            .then(response => {
                setData(response.data);
            }).catch(error => {
                console.log(error);
            })
    }

    const VentasSienteDias = async () => {

        await axios.get(baseUrl + "/api/cortes/todas/rangoventa/siete/userroot")
            .then(response => {
                setData(response.data);
            }).catch(error => {
                console.log(error);
            })
    }


    const VentasDelMes = async () => {

        var now = new Date();
        var year = now.getFullYear();
        var month = now.getMonth() + 1;


        if (month.toString().length === 1) {
            var month = '0' + month;
        }

        var m = month;
        var a = year;

        await axios.get(baseUrl + "/api/cortes/mes/todas/user/root/" + m + "/" + a)
            .then(response => {
                setData(response.data);
            }).catch(error => {
                console.log(error);
            })

    }


    const Ventas_dia_espesifico = async (valor) => {
        peticionGet();

        setFound([CorteSeleccionado.filter(element =>

            element.fecha_venta_cierre_caja.split(" ", 1) == valor

        )]);


    }



    const buscarRango = () => {

        let valor = document.getElementById("filtro").value;



        if (valor === "Hoy") {
            VentasHoy();

        } else if (valor === "Ayer") {
            VentasAyer();

        } else if (valor === "Últimos 7 días") {
            VentasSienteDias();

        }
        else if (valor === "Este mes") {

            VentasDelMes();
        }

    }

    const buscarDia = async () => {


        let valor = document.getElementById("filtro_dia_root").value;


        let i = moment(valor).format('YYYY-MM-DD');
        let f = moment(valor).add(1, 'days').format('YYYY-MM-DD');

        // console.log(i)
        // console.log(f)

        // Ventas_dia_espesifico(valor);

        try {
            await axios.get(baseUrl + "/api/corte/dia/" + i + "/" + f)
                .then(response => {
                    setData(response.data);
                    // setCorteSeleccionado(response.data);
                }).catch(error => {
                    console.log(error);
                })
        } catch (error) {
            console.log(error)
        }
    }



    const [datosReporte, setDatosReporte] = useState([]);

    const obtenerDatosReporte = async (id_suc, id_usu, id) => {

        setDatosReporte([])

        await axios.get(baseUrl + "/api/venta/data/report/" + id_suc + "/" + id_usu + "/" + id)
            .then(response => {

                setDatosReporte(response.data);
            }).catch(error => {
                console.log(error);
            })

    }

    // console.log(datosReporte)

    const [cargando, serCargando] = useState(false);

    const detalle_corte = async (cortecaja) => {

        // console.log(cortecaja)
        // obtenerDatosReporte()

        try {
            serCargando(true)
            await axios.get(baseUrl + "/api/venta/data/report/" + cortecaja.sucursal._id + "/" + cortecaja.user._id + "/" + cortecaja._id)
                .then(response => {
                    // setDatosReporte(response.data);
                    axios.post(baseUrl + "/api/reporte/new/" + cortecaja.user.nombre_completo_usuario + "/" + cortecaja.sucursal.name + "/" + cortecaja.sucursal.direccion + "/" + cortecaja.sucursal.telefono_usuario + "/" + cortecaja.diferencia + "/" + cortecaja.total_efectivo + "/" + cortecaja.inicio_caja, response.data)
                        .then(() => axios.get(baseUrl + '/api/reporte/ventas', { responseType: 'blob' }))
                        .then((res) => {
                            const pdfBlob = new Blob([res.data], { type: 'application/pdf' });

                            saveAs(pdfBlob, 'Reporte.pdf');
                            serCargando(false)
                        })



                }).catch(error => {
                    serCargando(false)
                    console.log(error);
                })


        } catch (e) {
            serCargando(false)
            console.log(e)
        }


    }


    const [found, setFound] = useState([]);

    const buscarCorteCajaSucursal = async () => {
        // peticionGet();
        let valor = document.getElementById("filtro_suc").value;
        // console.log(valor)

        if (valor === "") {
            try {
                await axios.get(baseUrl + "/api/corte")
                    .then(response => {
                        setData(response.data);
                        // setCorteSeleccionado(response.data);
                    }).catch(error => {
                        console.log(error);
                    })
            } catch (error) {
                console.log(error)
            }
        } else {
            try {
                await axios.get(baseUrl + "/api/corte/" + valor)
                    .then(response => {
                        setData(response.data);
                        // setCorteSeleccionado(response.data);
                    }).catch(error => {
                        console.log(error);
                    })
            } catch (error) {
                console.log(error)
            }
        }

        // setFound(CorteSeleccionado.filter(element => element._id === parseInt(valor)));
    }


    const [sucursalSeleccionadoRoot, setSucursalSeleccionadoRoot] = useState([]);

    const peticionS_Root = async () => {
        await axios.get(baseUrl + "/api/sucursal")
            .then(response => {
                setSucursalSeleccionadoRoot(response.data);
            }).catch(error => {
                console.log(error);
            })
    }




    useEffect(() => {

        peticionS_Root();
        peticionGet();

    }, [])





    const [menu, setMenu] = useState(true);

    const abrirmenu = () => {
        setMenu(!menu);

    }


    const styleagrandardashboard = {
        transitionDuration: "0.3s"
    };

    const stylencogerdashboard = {};

    const datos = data.length;

    return (
        <Fragment>
            {!cookies.get('correo') && <Redirect to="./" />}
            <div id="arriba"></div>
            {permisos !== "628c0ab8718beafc1c5ca26b" && <Redirect to="./" />}



            <NavBar estado={menu} abrirmenu={abrirmenu} primernombre={primernombre} />

            <div className="fondo-reports">

                <Asidebar estado={menu} />

                <div style={menu ? styleagrandardashboard : stylencogerdashboard} className="content-reports-main">

                    <label>Reportes</label>


                    <div className="select_table-admin">


                        <select onChange={buscarCorteCajaSucursal} id="filtro_suc" name="filtro_suc">
                            <option value="" defaultValue>Seleccione una opción</option>

                            {sucursalSeleccionadoRoot.map(sucursales =>

                                <option key={sucursales._id} value={sucursales._id}>{sucursales.name}</option>

                            )}

                        </select>


                        <input onChange={buscarDia} min="2021-01-10" id="filtro_dia_root" type="date"></input>

                        <select hidden onChange={buscarRango} id="filtro" name="filtro">
                            <option value="" defaultValue>Seleccione una opción</option>

                            <option value="Hoy">Hoy</option>
                            <option value="Ayer">Ayer</option>
                            <option value="Últimos 7 días">Últimos 7 días</option>
                            <option value="Este mes">Este mes</option>

                        </select>
                    </div>


                    <div style={{ flexDirection: 'column', justifyContent: 'flex-start' }} className="content-closebox-table">
                        <table className="table">
                            <thead className="thead">
                                <tr>
                                    {/* <th>#</th> */}
                                    <th>Responsable</th>
                                    <th>Inicio Caja</th>
                                    <th>Total Efectivo</th>
                                    <th>Total venta</th>
                                    <th>Diferencia</th>
                                    <th>Fecha Inicio</th>
                                    <th>Fecha Cierre</th>
                                    <th>Acción</th>
                                </tr>
                            </thead>

                            {datos === 0 ? (
                                <tbody className="tbody">


                                </tbody>
                            ) : (

                                <tbody className="tbody">


                                    {found.length >= 1 ?

                                        found.map(cortecaja =>

                                            <tr id="id_cortecaja" key={cortecaja._id}>
                                                {/* <td id="id_closebox">{cortecaja.id_corte_caja}</td> */}
                                                <td id="nom_usuar">{cortecaja.user.nombre_completo_usuario}</td>
                                                <td>{cortecaja.inicio_caja}.00</td>
                                                <td>{cortecaja.total_efectivo}.00</td>
                                                <td>{cortecaja.total_venta_periodo}.00</td>
                                                <td>{cortecaja.diferencia >= 1 ? "+" + cortecaja.diferencia : cortecaja.diferencia < 0 ? cortecaja.diferencia : "0"}.00</td>
                                                <td>{cortecaja.fecha_venta_inicio_caja ? moment(cortecaja.fecha_venta_inicio_caja).format("YYYY-MM-DD HH:mm:ss A") : "------------"}</td>
                                                <td>{cortecaja.fecha_venta_cierre_caja ? moment(cortecaja.fecha_venta_cierre_caja).format("YYYY-MM-DD HH:mm:ss A") : "------------"}</td>
                                                <td>
                                                    <button onClick={() => detalle_corte(cortecaja)} id="history" disabled={cargando} title="Descargar PDF" className="btn_history">{cargando ? "..." : <i className="fas fa-download"></i>}</button>
                                                </td>

                                            </tr>
                                        )

                                        :

                                        data.map(cortecaja =>

                                            <tr id="id_cortecaja" key={cortecaja._id}>
                                                {/* <td id="id_closebox">{cortecaja.id_corte_caja}</td> */}
                                                <td id="nom_usuar">{cortecaja.user.nombre_completo_usuario}</td>
                                                <td>{cortecaja.inicio_caja}.00</td>
                                                <td>{cortecaja.total_efectivo}.00</td>
                                                <td>{cortecaja.total_venta_periodo}.00</td>
                                                <td>{cortecaja.diferencia >= 1 ? "+" + cortecaja.diferencia : cortecaja.diferencia < 0 ? cortecaja.diferencia : "0"}.00</td>
                                                <td>{cortecaja.fecha_venta_inicio_caja ? moment(cortecaja.fecha_venta_inicio_caja).format("YYYY-MM-DD HH:mm:ss A") : "------------"}</td>
                                                <td>{cortecaja.fecha_venta_cierre_caja ? moment(cortecaja.fecha_venta_cierre_caja).format("YYYY-MM-DD HH:mm:ss A") : "------------"}</td>
                                                <td>
                                                    <button onClick={() => detalle_corte(cortecaja)} id="history" disabled={cargando} title="Descargar PDF" className="btn_history">{cargando ? "..." : <i className="fas fa-download"></i>}</button>
                                                </td>

                                            </tr>
                                        )
                                    }


                                </tbody>
                            )}


                        </table>

                        {(found.length === 0 & data.length === 0) ?
                            <div style={{ width: '100%', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="sin_r">
                                <h3>Sin resultados...</h3>
                            </div>
                            : ""
                        }

                    </div>


                </div>

            </div>


            <Footer />
        </Fragment>
    )
}

export default ReportsRoot;