import React, { Fragment, useEffect, useState } from "react";
import axios from 'axios';
import './ModalBebidas.css';
import Cookies from 'universal-cookie';


const cookies = new Cookies();


const ModalEditarBebidas = ({ modalEditar, peticionPut, seleccionarBebida, bebidaSeleccionado, abrirCerrarModalEditar, abrirCerrarModalInsertar, handleChange }) => {

  const baseUrl = window.$baseUrl;
  let permisos = cookies.get('id_permiso_usuario');



  const [sucursalSeleccionado, setSucursalSeleccionado] = useState([]);
  const [sucursalS, setSucursalS] = useState(false);
  const [sucursalSeleccionadoRoot, setSucursalSeleccionadoRoot] = useState([]);
  const sucursal = bebidaSeleccionado && bebidaSeleccionado.sucursal.name;

  const peticionS = async () => {
    await axios.get(baseUrl + "/api/sucursal")
      .then(response => {
        setSucursalSeleccionado(response.data);
      }).catch(error => {
        console.log(error);
      })
  }

  const peticionS_Root = async () => {
    await axios.get(baseUrl + "/api/sucursal")
      .then(response => {
        setSucursalSeleccionadoRoot(response.data);
      }).catch(error => {
        console.log(error);
      })
  }



  const peticionC = async () => {
    await axios.get(baseUrl + "/api/clasificacion")
      .then(response => {
        setClasificacionSeleccionado(response.data);
      }).catch(error => {
        console.log(error);
      })
  }

  const peticionP = async () => {
    await axios.get(baseUrl + "/api/presentacion")
      .then(response => {
        setPresentacionSeleccionado(response.data);
      }).catch(error => {
        console.log(error);
      })
  }

  useEffect(() => {
    peticionC();
    peticionP();
    peticionS();
    peticionS_Root();
  }, [])

  const [presentacionSeleccionado, setPresentacionSeleccionado] = useState([]);
  const [presentacionS, setPresentacionS] = useState(false);
  const [clasificacionSeleccionado, setClasificacionSeleccionado] = useState([]);
  const [clasificacionS, setClasificacionS] = useState(false);


  const presentacion = bebidaSeleccionado && bebidaSeleccionado.presentacion.name;
  const clasificacion = bebidaSeleccionado && bebidaSeleccionado.clasificacion.name;


  return (
    <Fragment>
      <div className={modalEditar ? "Modal-desactive" : "Modal-active"}>
        <div className="contend-modalB">
          <div className="ModalHeaderB">
            <h2>Editar Bebida</h2>
            <i className="fas fa-times" onClick={() => abrirCerrarModalEditar()} id="icon-close"></i>
          </div>
          <div className="ModalBodyB">
            <div className="FormGroupB">
              <form onSubmit={peticionPut} id="miForm">


                <label htmlFor="nombre_bebida">Nombre bebida</label>
                <input onChange={handleChange} name="nombre_bebida" value={bebidaSeleccionado && bebidaSeleccionado.nombre_bebida} required type="text" />


                {permisos !== "628c0ab8718beafc1c5ca26b" ?
                  ""
                  // <label htmlFor="id_sucursal">Sucursal</label>
                  :
                  <label htmlFor="id_sucursal">Sucursal</label>
                }

                {permisos !== "628c0ab8718beafc1c5ca26b" ?
                  ""
                  // <select onChange={handleChange} id="id_sucursal" required name="id_sucursal">
                  //   <option value="" defaultValue>Seleccione una opción</option>
                  //   {sucursalSeleccionado.map(items =>
                  //     <option key={items.id_sucursal} value={items.id_sucursal} >{items.nombre_sucursal}</option>
                  //   )}
                  // </select>
                  : sucursalS ?
                    <select onChange={handleChange} id="id_sucursal" required name="id_sucursal">
                      <option value="" defaultValue>Seleccione una opción</option>
                      {sucursalSeleccionadoRoot.map(items =>
                        <option key={items._id} value={items._id} >{items.name}</option>
                      )}
                    </select>
                    :
                    // <input onClick={() => setSucursalS(true)} onChange={handleChange} id="id_sucursal" name="id_sucursal" value={sucursal === 1 ? "Oxkutzcab" : sucursal === 2 ? "Akil (Matriz)" : sucursal === 3 ? "Akil (Suc. Avenida)" : sucursal === 4 ? "Tekax" : sucursal === 5 ? "Tzucacab" : "Peto"} type="text" />
                    <input onClick={() => setSucursalS(true)} onChange={handleChange} id="id_sucursal" name="id_sucursal" value={sucursal} required type="text" />

                }


                <label htmlFor="id_clasificacion_bebidas">Clasificación</label>
                {clasificacionS ?
                  <select onChange={handleChange} id="id_clasificacion_bebidas" required name="id_clasificacion_bebidas">
                    <option value="" defaultValue>Seleccione una opción</option>
                    {clasificacionSeleccionado.map(items =>
                      <option key={items._id} value={items._id}>{items.name}</option>
                    )}
                  </select>
                  :
                  <input onClick={() => setClasificacionS(true)} onChange={handleChange} id="id_clasificacion_bebidas" name="id_clasificacion_bebidas" value={clasificacion} required type="text" />
                }

                <label htmlFor="id_presentacion_bebidas">Presentación</label>
                {presentacionS ?
                  <select onChange={handleChange} id="id_presentacion_bebidas" required name="id_presentacion_bebidas">
                    <option value="" defaultValue>Seleccione una opción</option>
                    {presentacionSeleccionado.map(items =>
                      <option key={items._id} value={items._id}>{items.nombre_bebida}</option>
                    )}
                  </select>
                  :
                  <input onClick={() => setPresentacionS(true)} onChange={handleChange} id="id_presentacion_bebidas" name="id_presentacion_bebidas" value={presentacion} required type="text" />
                }

                <label htmlFor="tamano">Tamaño</label>
                <input onChange={handleChange} name="tamano" value={bebidaSeleccionado && bebidaSeleccionado.tamano} required type="text" />

                <label htmlFor="precio">Precio</label>
                <input onChange={handleChange} step=".01" name="precio" value={bebidaSeleccionado && bebidaSeleccionado.precio} required type="number" />


                <div className="ModalFooter">
                  <input id="yes" type="submit" value="Guardar Cambios" />
                  <button onClick={() => abrirCerrarModalEditar()} id="cancel">Cancelar</button>
                </div>

              </form>




            </div>
          </div>
        </div>
      </div>

    </Fragment>
  );
}

export default ModalEditarBebidas;
