import React, { Fragment, useState, useEffect } from "react";
import "./BranchOffice.css";
import Footer from '../../components/footer/Footer';
import NavBar from '../../components/navBar/NavBar';
import Asidebar from '../../components/asidebar/Asidebar';
import ModalAltaSucursales from '../../components/commons/modalSucursales/ModalAltaSucursales';
import ModalEditarSucursales from '../../components/commons/modalSucursales/ModalEditarSucursales';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import swal from 'sweetalert';
import Cookies from 'universal-cookie';


const cookies = new Cookies();


const BranchOffice = () => {
    const baseUrl = window.$baseUrl;
    let permisos = cookies.get('id_permiso_usuario');

    const [data, setData] = useState([]);
    const [modalInsertar, setModalInsertar] = useState(false);
    const [modalEditar, setModalEditar] = useState(false);
    const [modalEliminar, setModalEliminar] = useState(false);
    const [sucursalSeleccionado, setSucursalSeleccionado] = useState({
        id_sucursal: "",
        abreviatura: "",
        nombre_sucursal: "",
        numero_tel: "",
        direccion: ""
    });

    const handleChange = e => {
        const { name, value } = e.target;
        setSucursalSeleccionado((prevState) => ({
            ...prevState,
            [name]: value
        }))

    }


    const abrirCerrarModalInsertar = () => {
        setModalInsertar(!modalInsertar);
        document.getElementById("Form-insertar").reset();
    }

    const abrirCerrarModalEditar = () => {
        setModalEditar(!modalEditar);
    }

    const abrirCerrarModalEliminar = () => {
        setModalEliminar(!modalEliminar);
    }

    const peticionGet = async () => {
        try {
            await axios.get(baseUrl + "/api/sucursal")
                .then(response => {
                    setData(response.data);
                }).catch(error => {
                    console.log(error);
                })
        } catch (error) {
            console.log(error)
        }
    }


    const peticionPost = async (e) => {
        e.preventDefault();
        await axios.post(baseUrl + "/api/sucursal/new", {
            name: sucursalSeleccionado.nombre_sucursal,
            telefono_usuario: sucursalSeleccionado.numero_tel,
            abreviatura: sucursalSeleccionado.abreviatura,
            direccion: sucursalSeleccionado.direccion,
            status: true
        }).then(response => {
            console.log(response);

        }).catch(error => {
            console.log(error);
        })

        peticionGet();
        abrirCerrarModalInsertar();

    }

    const peticionPut = async (e) => {
        e.preventDefault();
        await axios.put(baseUrl + "/api/sucursal/" + sucursalSeleccionado._id, {

            id_sucursal: sucursalSeleccionado._id,
            name: sucursalSeleccionado.nombre_sucursal,
            telefono_usuario: sucursalSeleccionado.numero_tel,
            abreviatura: sucursalSeleccionado.abreviatura,
            direccion: sucursalSeleccionado.direccion

        })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error);
            });

        peticionGet();
        abrirCerrarModalEditar();

    }


    const peticionDelete = (id) => {
        swal({
            title: "¿Estás seguro?",
            text: "¡Una vez eliminado no podrás recuperar la información!",
            icon: "warning",
            buttons: ["Cancelar", "Aceptar"],
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                    axios.put(baseUrl + "/api/sucursal/delete/" + id, {

                    }).then(response => {

                        swal("La sucursal fue borrado correctamente", {
                            icon: "success",
                            button: "Aceptar",
                            timer: "2000"
                        });

                        peticionGet();


                    }).catch(e => {
                        console.log(e);
                    });

                }
            });

    }

    const seleccionarSucursal = (sucursal, caso) => {
        window.location.href = "branchoffice#arriba";

        setSucursalSeleccionado(sucursal);

        (caso === "Editar") ?

            abrirCerrarModalEditar() :
            abrirCerrarModalEliminar()
    }

    useEffect(() => {
        peticionGet();
    }, [])

    let nombreperfil = cookies.get('nombre_completo_usuario') || "user";
    let primernombre = nombreperfil.split(" ", 1);

    const [menu, setMenu] = useState(true);

    const abrirmenu = () => {
        setMenu(!menu);

    }

    const styleagrandardashboard = {
        transitionDuration: "0.3s"
    };

    const stylencogerdashboard = {

    };

    const datos = data.length;


    return (
        <Fragment>
            {!cookies.get('correo') && <Redirect to="./" />}
            <div id="arriba"></div>
            {permisos != "628c0ab8718beafc1c5ca26b" && <Redirect to="./" />}



            {modalEditar ?
                <ModalEditarSucursales sucursalSeleccionado={sucursalSeleccionado} seleccionarSucursal={seleccionarSucursal} peticionPut={peticionPut} modalInsertar={modalInsertar} peticionPost={peticionPost} modalEditar={modalEditar} abrirCerrarModalEditar={abrirCerrarModalEditar} abrirCerrarModalInsertar={abrirCerrarModalInsertar} handleChange={handleChange} />
                :
                <ModalAltaSucursales sucursalSeleccionado={sucursalSeleccionado} seleccionarSucursal={seleccionarSucursal} peticionPut={peticionPut} modalInsertar={modalInsertar} peticionPost={peticionPost} modalEditar={modalEditar} abrirCerrarModalEditar={abrirCerrarModalEditar} abrirCerrarModalInsertar={abrirCerrarModalInsertar} handleChange={handleChange} />
            }

            <NavBar estado={menu} abrirmenu={abrirmenu} primernombre={primernombre} />

            <div className="fondo-addsucursal">
                <Asidebar estado={menu} />


                <div style={menu ? styleagrandardashboard : stylencogerdashboard} className="content-addsucursal-main">

                    <label>Administrar sucursales</label>
                    <div className="btn_addsucursal">
                        <button onClick={() => abrirCerrarModalInsertar()} id="btn_addsucursal">Agregar sucursal <i className="fas fa-plus"></i></button>
                    </div>



                    <div className="content-addsucursal-table">
                        <table className="table">
                            <thead className="thead">
                                <tr>
                                    {/* <th>#</th> */}
                                    <th>Nombre sucursal</th>
                                    <th>Número telefónico</th>
                                    <th>Dirección</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>

                            {datos.length < 0 ? (
                                <tbody className="tbody">



                                </tbody>
                            ) : (
                                <tbody className="tbody">


                                    {data.map(sucursal =>

                                        <tr id="id_usuario" key={sucursal._id}>
                                            {/* <td id="id_sucursales">{sucursal.id_sucursal}</td> */}
                                            <td id="nombre_sucursal">{sucursal.name}</td>
                                            <td>{sucursal.telefono_usuario}</td>
                                            <td>{sucursal.direccion}</td>
                                            <td>
                                                <button onClick={() => seleccionarSucursal(sucursal, "Editar")} id="edit" className="btn_edit"><i className="fas fa-pen"></i></button>

                                                <button onClick={e => peticionDelete(sucursal._id)} id="remove" className="btn_remove"><i className="fas fa-trash"></i></button>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            )}
                        </table>

                    </div>
                    <br />

                    {`Mostrando registros del 1 al ${datos} de un total de ${datos}`}




                </div>
            </div>
            <Footer />
        </Fragment>
    )
}

export default BranchOffice;