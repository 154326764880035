import React, { Fragment, useState, useEffect } from "react";
import './ModalComplementos.css';
import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const ModalEditarComplementos = ({ modalEditar, peticionPut, seleccionarComplemento, complementoSeleccionado, abrirCerrarModalEditar, abrirCerrarModalInsertar, handleChange }) => {

  const baseUrl = window.$baseUrl;
  let permisos = cookies.get('id_permiso_usuario');


  const [sucursalSeleccionado, setSucursalSeleccionado] = useState([]);
  const [sucursalS, setSucursalS] = useState(false);
  const [sucursalSeleccionadoRoot, setSucursalSeleccionadoRoot] = useState([]);
  const sucursal = complementoSeleccionado && complementoSeleccionado.sucursal.name;

  const peticionS = async () => {
    await axios.get(baseUrl + "/api/sucursal")
      .then(response => {
        setSucursalSeleccionado(response.data);
      }).catch(error => {
        console.log(error);
      })
  }

  const peticionS_Root = async () => {
    await axios.get(baseUrl + "/api/sucursal")
      .then(response => {
        setSucursalSeleccionadoRoot(response.data);
      }).catch(error => {
        console.log(error);
      })
  }


  useEffect(() => {
    peticionS();
    peticionS_Root();

  }, [])





  return (
    <Fragment>
      <div className={modalEditar ? "Modal-desactive" : "Modal-active"}>
        <div className="contend-modalC">
          <div className="ModalHeader">
            <h2>Editar Complemento</h2>
            <i className="fas fa-times" onClick={() => abrirCerrarModalEditar()} id="icon-close"></i>
          </div>
          <div className="ModalBody">
            <div className="FormGroup">
              <form onSubmit={peticionPut} id="miForm">

                {permisos !== "628c0ab8718beafc1c5ca26b" ?
                  ""
                  // <label htmlFor="id_sucursal">Sucursal</label>
                  :
                  <label htmlFor="id_sucursal">Sucursal</label>
                }

                {permisos !== "628c0ab8718beafc1c5ca26b" ?
                  ""
                  // <select onChange={handleChange} id="id_sucursal" required name="id_sucursal">
                  //   <option value="" defaultValue>Seleccione una opción</option>
                  //   {sucursalSeleccionado.map(items =>
                  //     <option key={items.id_sucursal} value={items.id_sucursal} >{items.nombre_sucursal}</option>
                  //   )}
                  // </select>
                  : sucursalS ?
                    <select onChange={handleChange} id="id_sucursal" required name="id_sucursal">
                      <option value="" defaultValue>Seleccione una opción</option>
                      {sucursalSeleccionadoRoot.map(items =>
                        <option key={items._id} value={items._id} >{items.name}</option>
                      )}
                    </select>
                    :
                    // <input onClick={() => setSucursalS(true)} onChange={handleChange} id="id_sucursal" name="id_sucursal" value={sucursal === 1 ? "Oxkutzcab" : sucursal === 2 ? "Akil (Matriz)" : sucursal === 3 ? "Akil (Suc. Avenida)" : sucursal === 4 ? "Tekax" : sucursal === 5 ? "Tzucacab" : "Peto"} type="text" />
                    <input onClick={() => setSucursalS(true)} onChange={handleChange} id="id_sucursal" name="id_sucursal" value={sucursal} required type="text" />

                }

                <label htmlFor="nombre_complemento">Nombre de complemento</label>
                <input onChange={handleChange} name="nombre_complemento" value={complementoSeleccionado && complementoSeleccionado.nombre_complemento} required type="text" />

                <label htmlFor="precio_complemento">Precio</label>
                <input onChange={handleChange} step=".01" name="precio_complemento" value={complementoSeleccionado && complementoSeleccionado.precio_complemento} required type="number" />

                <div className="ModalFooter">
                  <input id="yes" type="submit" value="Guardar Cambios" />
                  <button onClick={() => abrirCerrarModalEditar()} id="cancel">Cancelar</button>
                </div>

              </form>




            </div>
          </div>
        </div>
      </div>

    </Fragment>
  );
}

export default ModalEditarComplementos;
