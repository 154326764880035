import React, { Fragment } from "react";
import './ModalSucursales.css';



const ModalEditarSucursales = ({ modalEditar, peticionPut, seleccionarSucursal, sucursalSeleccionado, abrirCerrarModalEditar, abrirCerrarModalInsertar, handleChange }) => {




  return (
    <Fragment>
      <div className={modalEditar ? "Modal-desactive" : "Modal-active"}>
        <div className="contend-modalC">
          <div className="ModalHeader">
            <h2>Editar Sucursal</h2>
            <i className="fas fa-times" onClick={() => abrirCerrarModalEditar()} id="icon-close"></i>
          </div>
          <div className="ModalBody">
            <div className="FormGroup">
              <form onSubmit={peticionPut} id="miForm">

                <label htmlFor="nombre_sucursal">Nombre de sucursal</label>
                <input onChange={handleChange} name="nombre_sucursal" value={sucursalSeleccionado && sucursalSeleccionado.name} required type="text" />

                <label htmlFor="nombre_sucursal">Abreviatura sucursal</label>
                <input onChange={handleChange} name="abreviatura" value={sucursalSeleccionado && sucursalSeleccionado.abreviatura} type="text" required />

                <label htmlFor="numero_tel">Teléfono</label>
                <input onChange={handleChange} name="numero_tel" minLength="10" maxLength="10" value={sucursalSeleccionado && sucursalSeleccionado.telefono_usuario} required type="text" />

                <label htmlFor="direccion">Nombre de sucursal</label>
                <input onChange={handleChange} name="direccion" value={sucursalSeleccionado && sucursalSeleccionado.direccion} required type="text" />


                <div className="ModalFooter">
                  <input id="yes" type="submit" value="Guardar Cambios" />
                  <button onClick={() => abrirCerrarModalEditar()} id="cancel">Cancelar</button>
                </div>

              </form>




            </div>
          </div>
        </div>
      </div>

    </Fragment>
  );
}

export default ModalEditarSucursales;
