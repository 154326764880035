import React, { Fragment, useState, useEffect } from "react";
import "./Reports.css";
import NavBar from '../../components/navBar/NavBar';
import Asidebar from '../../components/asidebar/Asidebar';
import Footer from '../../components/footer/Footer';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import moment from "moment";

const cookies = new Cookies();

const Reports = () => {
    const baseUrl = window.$baseUrl;

    let permisos = cookies.get('id_permiso_usuario');
    let sucursal = cookies.get('id_sucursal');
    let nombreperfil = cookies.get('nombre_completo_usuario') || "user";
    let primernombre = nombreperfil.split(" ", 1);


    const [data, setData] = useState([]);


    const peticionGet = async () => {
        try {
            await axios.get(baseUrl + "/api/corte/" + sucursal)
                .then(response => {
                    setData(response.data);
                    // setCorteSeleccionado(response.data);
                }).catch(error => {
                    console.log(error);
                })
        } catch (error) {
            console.log(error)
        }
    }

    const VentasHoy = async () => {

        var now = new Date();
        var year = now.getFullYear();
        var month = now.getMonth() + 1;
        var day = now.getDate();
        var manana = now.getDate() + 1;


        if (month.toString().length === 1) {
            var month = '0' + month;
        }
        if (day.toString().length === 1) {
            var day = '0' + day;
        }
        if (manana.toString().length === 1) {
            var manana = '0' + manana;
        }

        var i = year + '-' + month + '-' + day;
        var f = year + '-' + month + '-' + manana;

        await axios.get(baseUrl + "/api/cortes/obtener/todas/" + i + "/" + f + "/" + sucursal)
            .then(response => {
                setData(response.data);
            }).catch(error => {
                console.log(error);
            })
    }

    const VentasAyer = async () => {

        var now = new Date();
        var year = now.getFullYear();
        var month = now.getMonth() + 1;
        var day = now.getDate();
        var ayer = now.getDate() - 1;

        if (month.toString().length === 1) {
            var month = '0' + month;
        }
        if (day.toString().length === 1) {
            var day = '0' + day;
        }

        var i = year + '-' + month + '-' + ayer;
        var f = year + '-' + month + '-' + day;

        await axios.get(baseUrl + "/api/cortes/obtener/todas/" + i + "/" + f + "/" + sucursal)
            .then(response => {
                setData(response.data);
            }).catch(error => {
                console.log(error);
            })
    }

    const VentasSienteDias = async () => {

        await axios.get(baseUrl + "/api/cortes/todas/rangoventa/siete/" + sucursal)
            .then(response => {
                setData(response.data);
            }).catch(error => {
                console.log(error);
            })
    }


    const VentasDelMes = async () => {

        var now = new Date();
        var year = now.getFullYear();
        var month = now.getMonth() + 1;


        if (month.toString().length === 1) {
            var month = '0' + month;
        }

        var m = month;
        var a = year;

        await axios.get(baseUrl + "/api/cortes/mes/todas/" + m + "/" + a + "/" + sucursal)
            .then(response => {
                setData(response.data);
            }).catch(error => {
                console.log(error);
            })
    }

    const Ventas_dia_espesifico = async (valor) => {

        await axios.get(baseUrl + "/api/cortes/dia/espesifico/" + sucursal + "/" + valor)
            .then(response => {
                setData(response.data);
            }).catch(error => {
                console.log(error);
            })

    }


    const buscarRango = () => {
        let valor = document.getElementById("filtro").value;



        if (valor === "Hoy") {
            VentasHoy();

        } else if (valor === "Ayer") {
            VentasAyer();

        } else if (valor === "Últimos 7 días") {
            VentasSienteDias();

        }
        else if (valor === "Este mes") {

            VentasDelMes();
        }

    }

    const buscarDia = async () => {
        let valor = document.getElementById("filtro_dia").value;

        let i = moment(valor).format('YYYY-MM-DD');
        let f = moment(valor).add(1, 'days').format('YYYY-MM-DD');


        try {
            await axios.get(baseUrl + "/api/corte/dia/" + i + "/" + f)
                .then(response => {
                    setData(response.data);
                    // setCorteSeleccionado(response.data);
                }).catch(error => {
                    console.log(error);
                })
        } catch (error) {
            console.log(error)
        }

    }

    const detalle_corte = async (cortecaja) => {

        try {

            await axios.get(baseUrl + "/api/venta/data/report/" + cortecaja.sucursal._id + "/" + cortecaja.user._id + "/" + cortecaja._id)
                .then(response => {
                    // setDatosReporte(response.data);
                    axios.post(baseUrl + "/api/reporte/new/" + cortecaja.user.nombre_completo_usuario + "/" + cortecaja.sucursal.name + "/" + cortecaja.sucursal.direccion + "/" + cortecaja.sucursal.telefono_usuario + "/" + cortecaja.diferencia + "/" + cortecaja.total_efectivo + "/" + cortecaja.inicio_caja, response.data)
                        .then(() => axios.get(baseUrl + '/api/reporte/ventas', { responseType: 'blob' }))
                        .then((res) => {
                            const pdfBlob = new Blob([res.data], { type: 'application/pdf' });

                            saveAs(pdfBlob, 'Reporte.pdf');

                        })

                }).catch(error => {
                    console.log(error);
                })


        } catch (e) {
            console.log(e)
        }


    }




    useEffect(() => {

        peticionGet();

    }, [])



    const [menu, setMenu] = useState(true);

    const abrirmenu = () => {
        setMenu(!menu);

    }


    const styleagrandardashboard = {
        transitionDuration: "0.3s"
    };

    const stylencogerdashboard = {};

    return (
        <Fragment>
            {!cookies.get('correo') && <Redirect to="./" />}
            <div id="arriba"></div>
            {permisos !== "628c073747bc232d04b1b5f4" && <Redirect to="./" />}



            <NavBar estado={menu} abrirmenu={abrirmenu} primernombre={primernombre} />

            <div className="fondo-reports">

                <Asidebar estado={menu} />

                <div style={menu ? styleagrandardashboard : stylencogerdashboard} className="content-reports-main">

                    <label>Reportes</label>


                    <div className="select_table-admin">
                        <input onChange={buscarDia} min="2021-01-10" id="filtro_dia" type="date"></input>

                        <select hidden onChange={buscarRango} id="filtro" name="filtro">
                            <option value="" defaultValue>Seleccione una opción</option>

                            <option value="Hoy">Hoy</option>
                            <option value="Ayer">Ayer</option>
                            <option value="Últimos 7 días">Últimos 7 días</option>
                            <option value="Este mes">Este mes</option>

                        </select>
                    </div>


                    <div style={{ flexDirection: 'column', justifyContent: 'flex-start' }} className="content-closebox-table">
                        <table className="table">
                            <thead className="thead">
                                <tr>
                                    {/* <th>#</th> */}
                                    <th>Responsable</th>
                                    <th>Inicio Caja</th>
                                    <th>Total Efectivo</th>
                                    <th>Total venta</th>
                                    <th>Diferencia</th>
                                    <th>Fecha Inicio</th>
                                    <th>Fecha Cierre</th>
                                    <th>Acción</th>
                                </tr>
                            </thead>


                            <tbody className="tbody">


                                {data.map(cortecaja =>

                                    <tr id="id_cortecaja" key={cortecaja._id}>
                                        {/* <td id="id_closebox">{cortecaja._id}</td> */}
                                        <td id="nom_usuar">{cortecaja.user.nombre_completo_usuario}</td>
                                        <td>{cortecaja.inicio_caja}.00</td>
                                        <td>{cortecaja.total_efectivo}.00</td>
                                        <td>{cortecaja.total_venta_periodo}.00</td>
                                        <td>{cortecaja.diferencia >= 1 ? "+" + cortecaja.diferencia : cortecaja.diferencia < 0 ? cortecaja.diferencia : "0"}.00</td>
                                        <td>{cortecaja.fecha_venta_inicio_caja ? moment(cortecaja.fecha_venta_inicio_caja).format("YYYY-MM-DD HH:mm:ss A") : "------------"}</td>
                                        <td>{cortecaja.fecha_venta_cierre_caja ? moment(cortecaja.fecha_venta_cierre_caja).format("YYYY-MM-DD HH:mm:ss A") : "------------"}</td>
                                        <td>
                                            <button onClick={() => detalle_corte(cortecaja)} id="history" title="Descargar PDF" className="btn_history"><i className="fas fa-download"></i></button>
                                        </td>

                                    </tr>
                                )}
                            </tbody>

                        </table>

                        {data.length === 0 ?
                            <div style={{ width: '100%', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="sin_r">
                                <h3>Sin resultados...</h3>
                            </div>
                            : ""
                        }

                    </div>


                </div>

            </div>


            <Footer />
        </Fragment>
    )
}

export default Reports;