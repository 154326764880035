import React, { Fragment, useState, useEffect } from 'react';
import './Profile.css';
import Footer from '../../components/footer/Footer';
import NavBar from '../../components/navBar/NavBar';
import Asidebar from '../../components/asidebar/Asidebar';
import fotoperfil from '../../images/imgprofile.png';
import portadaperfil from '../../images/portada.jpg';
import { Redirect } from 'react-router-dom';
import swal from 'sweetalert';
import axios from 'axios';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const Profile = () => {

    const baseUrl = window.$baseUrl;


    let id = cookies.get('id_usuario');
    let nombreperfil = cookies.get('nombre_completo_usuario') || "user";
    let primernombre = nombreperfil.split(" ", 1);
    let permisos = cookies.get('id_permiso_usuario');



    const [menu, setMenu] = useState(true);
    const [cambiarP, setCambiarP] = useState(false);
    const [data, setData] = useState([]);
    const [contrasenaSeleccionado, setContrasenaSeleccionado] = useState({
        id_usuario: "",
        id_sucursal: "",
        nombre_completo_usuario: "",
        edad_usuario: "",
        telefono_usuario: "",
        correo: "",
        contrasena: "",
        id_cargo_usuario: "",
        id_permiso_usuario: "",
    });

    const cambiarContrasena = () => {
        setCambiarP(!cambiarP);
    }

    const handleChange = e => {
        const { name, value } = e.target;
        setContrasenaSeleccionado((prevState) => ({
            ...prevState,
            [name]: value
        }))

    }

    const peticionGet = async () => {
        try {
            await axios.get(baseUrl + "/api/user/" + id)
                .then(response => {
                    console.log(response.data)
                    setData(response.data);
                }).catch(error => {
                    console.log(error);
                })
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        peticionGet();
    }, [])


    const peticionPut = async (e) => {
        e.preventDefault();

        try {
            await axios.put(baseUrl + "/api/user/update/password/" + id, {
                _id: id,
                sucursal: data.sucursal._id,
                nombre_completo_usuario: data.nombre_completo_usuario,
                edad_usuario: data.edad_usuario,
                telefono_usuario: data.telefono_usuario,
                correo: data.correo,
                contrasena: contrasenaSeleccionado.contrasena,
                cargo: data.cargo._id,
                rol: data.rol._id
            })
                .then(response => {
                    console.log(response);
                    swal({
                        title: "¡Contraseña Actualizada!",
                        text: "Tú contraseña fue actualizada exitosamente",
                        icon: "success",
                        button: "Aceptar",
                        timer: "5000"
                    });
                })
                .catch(error => {
                    console.log(error);
                });

            cambiarContrasena();
        } catch (error) {
            console.log(error)
        }



    }




    const abrirmenu = () => {
        setMenu(!menu);

    }





    return (
        <Fragment>
            {!cookies.get('correo') && <Redirect to="./" />}
            <NavBar estado={menu} abrirmenu={abrirmenu} primernombre={primernombre} />
            <div className="fondo-profile">
                <Asidebar estado={menu} />
                <div className="profile-content">

                    <div className="profile">
                        <div className="cover_page">
                            <img src={portadaperfil} alt="Portada de perfil" />
                        </div>
                        <div className="profile_picture">
                            <img src={fotoperfil} alt="Foto de perfil" />
                        </div>
                        <div className="username">
                            <h1> {cookies.get('nombre_completo_usuario')} </h1>
                            <h4>Añadir presentación</h4>
                        </div>
                        <hr />

                    </div>

                    <div className="main_profile_content">
                        <div className="user_data">
                            <h3 id="detalles-perfil">Detalles</h3>
                            <br />
                            <div className="content-data-profile">
                                <i id="blanco" className="fas fa-briefcase"></i><h5>El Pollo Rico "{cookies.get('nombre_sucursal')}"</h5>
                            </div>
                            <div className="content-data-profile">
                                <i id="blanco" className="fas fa-phone-alt"></i><h5>{cookies.get('telefono_usuario')}</h5>
                            </div>
                            <div className="content-data-profile">
                                <i id="blanco" className="fas fa-envelope-open-text"></i><h5>{cookies.get('correo')}</h5>
                            </div>
                            <div className="content-data-profile">
                                <i id="blanco" className="fas fa-birthday-cake"></i><h5>{cookies.get('edad_usuario')}</h5>
                            </div>
                            <div className="content-data-profile">
                                <i id="blanco" className="fas fa-key"></i><h5>**************</h5>
                            </div>
                            {cambiarP ?
                                <form onSubmit={peticionPut}>
                                    <input id="input_nContrasena" onChange={handleChange} type="text" name="contrasena" placeholder="Escribe tu nueva contraseña" required ></input>
                                    <div className="content-btn-nC">
                                        <input id="g_nueva_c" type="submit" value="Guardar"></input>
                                        <input id="c_nueva_c" type="button" value="Cancelar" onClick={cambiarContrasena}></input>
                                    </div>
                                </form>

                                :
                                <input type="button" id="nPass" value="Cambiar Contraseña" onClick={cambiarContrasena}></input>
                            }
                        </div>
                        {permisos === "628c0a94718beafc1c5ca269" ?
                            <div className="content-borrowed_books">
                                <div id="ventas_mes" className="borrowed_books">
                                    <h3 id="fecha_corte">Ventas(Mes)</h3>


                                </div>


                                <div className="borrowed_books">
                                    <h3 id="fecha_corte">Ganancia del ultimo corte</h3>



                                </div>
                            </div>
                            :
                            <div className="content-borrowed_books">
                                <div id="ventas_mes" className="borrowed_books">
                                    <h3 id="fecha_corte"></h3>


                                </div>


                                <div className="borrowed_books">
                                    <h3 id="fecha_corte"></h3>



                                </div>
                            </div>
                        }


                    </div>

                </div>
            </div>



            <Footer />
        </Fragment>
    )
}

export default Profile;