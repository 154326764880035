import React, { Fragment, useState, useEffect } from "react";
import "./ManageSales.css";
import NavBar from '../../components/navBar/NavBar';
import Asidebar from '../../components/asidebar/Asidebar';
import Footer from '../../components/footer/Footer';
import axios from 'axios';
import { Redirect, Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import moment from "moment";
const cookies = new Cookies();

const ManageSales = () => {
    const baseUrl = window.$baseUrl;

    let permisos = cookies.get('id_permiso_usuario');
    let nombreperfil = cookies.get('nombre_completo_usuario') || "user";
    let primernombre = nombreperfil.split(" ", 1);

    const [menu, setMenu] = useState(true);

    const abrirmenu = () => {
        setMenu(!menu);

    }

    useEffect(() => {

        peticionS();

    }, [])

    const styleagrandardashboard = {
        transitionDuration: "0.3s"
    };

    const stylencogerdashboard = {};

    const [totalVentasSucursales, setTotalVentasSucursales] = useState([]);
    const [sucursalSeleccionado, setSucursalSeleccionado] = useState([]);
    const [data, setData] = useState([]);



    const peticionS = async () => {
        await axios.get(baseUrl + "/api/sucursal")
            .then(response => {
                setSucursalSeleccionado(response.data)
                // response.data.forEach(element => obtenerTotalVentas(element._id))
                obtenerTotalVentas()
            }).catch(error => {
                console.log(error);
            })




    }





    const obtenerTotalVentas = async () => {
        // console.log(id)

        let i = moment().format('YYYY-MM-DD');
        let f = moment().add(1, 'days').format('YYYY-MM-DD');

        await axios.get(baseUrl + "/api/venta/corte/total/" + i + "/" + f)
            .then(response => {
                // totalVentasSucursales.push(response.data)
                setTotalVentasSucursales(response.data)
                setData(response.data);

            }).catch(error => {
                console.log(error);
            })


    }




    return (
        <Fragment>
            <div id="arriba"></div>

            {!cookies.get('correo') && <Redirect to="./" />}
            {permisos !== "628c0ab8718beafc1c5ca26b" && <Redirect to="./" />}

            <NavBar estado={menu} abrirmenu={abrirmenu} primernombre={primernombre} />

            <div className="fondo-managesales">

                <Asidebar estado={menu} />

                <div style={menu ? styleagrandardashboard : stylencogerdashboard} className="content-managesales-main">

                    <label>Administrar ganancias</label>

                    <div className="content-target-managesales">

                        {
                            totalVentasSucursales.length > 0 ?

                                totalVentasSucursales.map(items =>

                                    <div key={items._id} id="naranjaA" className="content-managesales-target">
                                        <div className="content-managesales-target-info">

                                            <div id="noVentas" className="content-info-managesales">

                                                <h1>${items.totales || 0}.00<br /></h1>
                                                <h2>último corte</h2>


                                            </div>

                                            <div className="content-managesales-target-img">

                                                <img src="https://cdn-icons-png.flaticon.com/512/2727/2727253.png" alt="ventas" />

                                            </div>

                                        </div>
                                        <div id="naranjaB" className="content-managesales-vermas">
                                            <Link to="/reportsroot">
                                                <h4>{items._id && sucursalSeleccionado.length > 0 && (sucursalSeleccionado.find(i => i._id === items._id)).name} <i className="fas fa-arrow-circle-right"></i></h4>
                                            </Link>
                                        </div>

                                    </div>

                                )
                                :
                                <label>Sin resultados</label>

                        }

                    </div>

                </div>

            </div>


            <Footer />
        </Fragment>
    )
}

export default ManageSales;