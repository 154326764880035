import React, { Fragment, useState, useEffect } from "react";
import './Dashboard.css';
import Footer from '../../components/footer/Footer';
import NavBar from '../../components/navBar/NavBar';
import Asidebar from '../../components/asidebar/Asidebar';
import { Link, Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import dataTargetuser from './data-target-user.json';
import Grafic from './grafics/Grafic';
import axios from 'axios';

import ventasDia from '../../images/4573971.png';
import ventas from '../../images/2660366.png';
import comidas from '../../images/562678.png';
import usuario from '../../images/633781.png';
import bebidas from '../../images/5821393.png';
import complementos from '../../images/3081915.png';

import moment from 'moment';

const cookies = new Cookies();


const Dashboard = () => {
    const baseUrl = window.$baseUrl;

    let id_sucursal = cookies.get('id_sucursal');
    let nombreperfil = cookies.get('nombre_completo_usuario') || "user";
    let primernombre = nombreperfil.split(" ", 1);

    const [menu, setMenu] = useState(true);

    const abrirmenu = () => {
        setMenu(!menu);
    }



    const styleagrandardashboard = {
        // width: "94%",
        transitionDuration: "0.3s"
    };

    const stylencogerdashboard = {
        transitionDuration: ".3s"
    };

    const [totalV, setTotalV] = useState(0);

    const totalVentas = async () => {

        let i = moment().format('YYYY-MM-DD');
        let f = moment().add(1, 'days').format('YYYY-MM-DD');

        try {
            await axios.get(baseUrl + "/api/corte/total/" + i + "/" + f)
                .then(response => {

                    let sum = 0;

                    if (response.data.length > 0) {
                        for (let i = 0; i < response.data.length; i++) {
                            sum += response.data[i].totales;
                        }

                    } else {
                        setTotalV(sum);
                    }

                    setTotalV(sum);
                })
        } catch (error) {
            console.log(error)
        }

    }

    const [totalSuc, setTotalSuc] = useState(0);

    const totalVentasSuc = async () => {

        let i = moment().format('YYYY-MM-DD');
        let f = moment().add(1, 'days').format('YYYY-MM-DD');


        try {
            await axios.get(baseUrl + "/api/corte/total/suc/total/" + i + "/" + f + "/" + id_sucursal)
                .then(response => {

                    // console.log(response.data)
                    let sum = 0;

                    if (response.data.length > 0) {
                        for (let i = 0; i < response.data.length; i++) {
                            sum += response.data[i].totales;
                        }

                    } else {
                        setTotalSuc(sum);
                    }

                    setTotalSuc(sum);
                })
        } catch (error) {
            console.log(error)
        }


    }

    const [cantV, setCantidadV] = useState(0);

    const cantidadVentas = async () => {

        let i = moment().format('YYYY-MM-DD');
        let f = moment().add(1, 'days').format('YYYY-MM-DD');

        try {

            await axios.get(baseUrl + "/api/venta/total/suc/cantidad/" + i + "/" + f + "/" + id_sucursal)
                .then(response => {
                    console.log("3: " + response.data.cantVentas)
                    setCantidadV(response.data.cantVentas ? response.data.cantVentas : 0);
                })
        } catch (error) {
            console.log(error)
        }

    }


    const [cantVSuc, setCantidadVSuc] = useState(0);

    const cantidadVentasSuc = async () => {

        let i = moment().format('YYYY-MM-DD');
        let f = moment().add(1, 'days').format('YYYY-MM-DD');

        try {
            await axios.get(baseUrl + "/api/venta/total/cantidad/root/" + i + "/" + f)
                .then(response => {
                    console.log("4: " + response.data.total_venta)
                    setCantidadVSuc(response.data.cantVentas ? response.data.cantVentas : 0);
                })
        } catch (error) {
            console.log(error)
        }

    }


    const [cantU, setCantidadU] = useState(0);

    const cantidadUsuarios = async () => {

        try {
            await axios.get(baseUrl + "/api/user/total/suc/cantidad/" + id_sucursal)
                .then(response => {
                    console.log("5: " + response.data.CantUsers)
                    setCantidadU(response.data.CantUsers ? response.data.CantUsers : 0);
                })

        } catch (error) {
            console.log(error)
        }


    }

    const [cantURoot, setCantidadURoot] = useState(0);

    const cantidadUsuariosRoot = async () => {

        try {
            await axios.get(baseUrl + "/api/user/total/cantidad/root")
                .then(response => {
                    console.log("6: " + response.data.CantUsers)
                    setCantidadURoot(response.data.CantUsers ? response.data.CantUsers : 0);
                })
        } catch (error) {
            console.log(error)
        }



    }



    const [cantCo, setCantidadCo] = useState(0);

    const cantidadComidas = async () => {

        try {
            await axios.get(baseUrl + "/api/comida/total/suc/cantidad/" + id_sucursal)
                .then(response => {
                    console.log("7: " + response.data.CantComidas)
                    setCantidadCo(response.data.CantComidas ? response.data.CantComidas : 0);
                })
        } catch (error) {
            console.log(error)
        }

    }

    const [cantCoRoot, setCantidadCoRoot] = useState(0);

    const cantidadComidasRoot = async () => {

        try {
            await axios.get(baseUrl + "/api/comida/total/cantidad/root")
                .then(response => {
                    console.log("8: " + response.data.CantComidas)
                    setCantidadCoRoot(response.data.CantComidas ? response.data.CantComidas : 0);
                })
        } catch (error) {
            console.log(error)
        }



    }

    // AQUII

    const [cantB, setCantidadB] = useState(0);

    const cantidadBebidas = async () => {


        try {
            await axios.get(baseUrl + "/api/bebida/total/suc/cantidad/" + id_sucursal)
                .then(response => {
                    console.log("9: " + response.data.CantBebidas)
                    setCantidadB(response.data.CantBebidas ? response.data.CantBebidas : 0);
                })
        } catch (error) {
            console.log(error)
        }



    }

    const [cantBRoot, setCantidadBRoot] = useState(0);

    const cantidadBebidasRoot = async () => {


        try {
            await axios.get(baseUrl + "/api/bebida/total/cantidad/root")
                .then(response => {
                    console.log("10: " + response.data.CantBebidas)
                    setCantidadBRoot(response.data.CantBebidas ? response.data.CantBebidas : 0);
                })
        } catch (error) {
            console.log(error)
        }



    }

    const [cantCom, setCantidadCom] = useState(0);

    const cantidadComplementos = async () => {

        try {
            await axios.get(baseUrl + "/api/complemento/total/suc/cantidad/" + id_sucursal)
                .then(response => {
                    console.log("11: " + response.data.CantComplementos)
                    setCantidadCom(response.data.CantComplementos ? response.data.CantComplementos : 0);
                })
        } catch (error) {
            console.log(error)
        }



    }


    const [cantComRoot, setCantidadComRoot] = useState(0);

    const cantidadComplementosRoot = async () => {

        try {
            await axios.get(baseUrl + "/api/complemento/total/cantidad/root")
                .then(response => {
                    console.log("12: " + response.data.CantComplementos)
                    setCantidadComRoot(response.data.CantComplementos ? response.data.CantComplementos : 0);
                })

        } catch (error) {
            console.log(error)
        }


    }


    useEffect(() => {
        // corteIniciado();
        totalVentas();
        cantidadVentasSuc();
        totalVentasSuc();
        cantidadVentas();
        cantidadUsuarios();
        cantidadUsuariosRoot();
        cantidadComidas();
        cantidadComidasRoot();
        cantidadBebidas();
        cantidadBebidasRoot();
        cantidadComplementos();
        cantidadComplementosRoot();
    }, [])

    // console.log(cookies.get('id_permiso_usuario'))

    return (
        <Fragment>
            {!cookies.get('correo') && <Redirect to="./" />}

            <NavBar estado={menu} abrirmenu={abrirmenu} primernombre={primernombre} />
            <div className="fondo-dashboard">

                <Asidebar estado={menu} />

                <div style={menu ? styleagrandardashboard : stylencogerdashboard} className="content-dashboard-main">
                    <label>Panel de Control</label>
                    <h2>Bienvenid@ {primernombre}</h2>

                    {cookies.get('id_permiso_usuario') === "628c073747bc232d04b1b5f4" ?

                        <div className="content-dashboard-options">

                            <div id="azulA" className="content-dashboard-target">

                                <div className="content-dashboard-target-info">

                                    <div id="noVentas" className="content-info">
                                        <Link to="/salesquantityadmin">
                                            <h1>{cantV}</h1>
                                            <h3>no° ventas del día</h3>
                                        </Link>
                                    </div>

                                    <div className="content-dashboard-target-img">
                                        <Link to="/salesquantityadmin">
                                            <img src={ventasDia} alt="ventas" />
                                        </Link>
                                    </div>

                                </div>
                                <div id="azulB" className="content-dashboard-vermas">
                                    <Link to="/salesquantityadmin">
                                        <h4>Ver más información <i className="fas fa-arrow-circle-right"></i></h4>
                                    </Link>
                                </div>
                            </div>


                            <div id="naranjaA" className="content-dashboard-target">

                                <div className="content-dashboard-target-info">

                                    <div className="content-info">
                                        <Link to="/reports">
                                            <h1>${totalSuc || 0}.00 MXN</h1>
                                            <h3>ingreso del día</h3>
                                        </Link>
                                    </div>

                                    <div className="content-dashboard-target-img">
                                        <Link to="/reports">
                                            <img src={ventas} alt="ventas" />
                                        </Link>
                                    </div>

                                </div>
                                <div id="naranjaB" className="content-dashboard-vermas">
                                    <Link to="/reports">
                                        <h4>Ver más información <i className="fas fa-arrow-circle-right"></i></h4>
                                    </Link>
                                </div>
                            </div>

                            <div id="rojoA" className="content-dashboard-target">

                                <div className="content-dashboard-target-info">

                                    <div className="content-info">
                                        <Link to="/products">
                                            <h1>{cantCo}</h1>
                                            <h3>Comidas</h3>
                                        </Link>
                                    </div>

                                    <div className="content-dashboard-target-img">
                                        <Link to="/products">
                                            <img src={comidas} alt="ventas" />
                                        </Link>
                                    </div>

                                </div>
                                <div id="rojoB" className="content-dashboard-vermas">
                                    <Link to="/products">
                                        <h4>Ver más información <i className="fas fa-arrow-circle-right"></i></h4>
                                    </Link>
                                </div>
                            </div>

                            <div id="verdeA" className="content-dashboard-target">

                                <div className="content-dashboard-target-info">

                                    <div className="content-info">
                                        <Link to="/adduser">
                                            <h1>{cantU}</h1>
                                            <h3>Usuarios</h3>
                                        </Link>
                                    </div>

                                    <div className="content-dashboard-target-img">
                                        <Link to="/adduser">
                                            <img src={usuario} alt="ventas" />
                                        </Link>
                                    </div>

                                </div>
                                <div id="verdeB" className="content-dashboard-vermas">
                                    <Link to="/adduser">
                                        <h4>Ver más información <i className="fas fa-arrow-circle-right"></i></h4>
                                    </Link>
                                </div>
                            </div>


                            <div id="naranjaA" className="content-dashboard-target">

                                <div className="content-dashboard-target-info">

                                    <div className="content-info">
                                        <Link to="/drinks">
                                            <h1>{cantB}</h1>
                                            <h3>Bebidas</h3>
                                        </Link>
                                    </div>

                                    <div className="content-dashboard-target-img">
                                        <Link to="/drinks">
                                            <img src={bebidas} alt="ventas" />
                                        </Link>
                                    </div>

                                </div>
                                <div id="naranjaB" className="content-dashboard-vermas">
                                    <Link to="/drinks">
                                        <h4>Ver más información <i className="fas fa-arrow-circle-right"></i></h4>
                                    </Link>
                                </div>
                            </div>


                            <div id="azulA" className="content-dashboard-target">

                                <div className="content-dashboard-target-info">

                                    <div className="content-info">
                                        <Link to="/complements">
                                            <h1>{cantCom}</h1>
                                            <h3>Complementos</h3>
                                        </Link>
                                    </div>

                                    <div className="content-dashboard-target-img">
                                        <Link to="/complements">
                                            <img src={complementos} alt="ventas" />
                                        </Link>
                                    </div>

                                </div>
                                <div id="azulB" className="content-dashboard-vermas">
                                    <Link to="/complements">
                                        <h4>Ver más información <i className="fas fa-arrow-circle-right"></i></h4>
                                    </Link>
                                </div>
                            </div>

                        </div>

                        : cookies.get('id_permiso_usuario') === "628c0a94718beafc1c5ca269" ?

                            <div className="content-dashboard-options">

                                {
                                    dataTargetuser.map(content => {
                                        return (

                                            <div key={content.id} id={content.colorprincipal} className="content-dashboard-target">

                                                <div className="content-dashboard-target-info">

                                                    <div className="content-info">
                                                        <Link to={content.to}>
                                                            <h1>{content.name}</h1>
                                                            <h3>{content.description}</h3>
                                                        </Link>
                                                    </div>

                                                    <div className="content-dashboard-target-img">
                                                        <Link to={content.to}>
                                                            <img src={content.image} alt="ventas" />
                                                        </Link>
                                                    </div>

                                                </div>
                                                <div id={content.colorsecuendario} className="content-dashboard-vermas">
                                                    <Link to={content.to}>
                                                        <h4>Ver más información <i className="fas fa-arrow-circle-right"></i></h4>
                                                    </Link>
                                                </div>
                                            </div>
                                        )
                                    })
                                }


                            </div>
                            : cookies.get('id_permiso_usuario') === "628c0ab8718beafc1c5ca26b" ?
                                <div className="content-dashboard-options">

                                    <div id="azulA" className="content-dashboard-target">

                                        <div className="content-dashboard-target-info">

                                            <div id="noVentas" className="content-info">
                                                <Link to="/salesquantity">
                                                    <h1>{cantVSuc}</h1>
                                                    <h3>no° ventas mostrador</h3>
                                                </Link>
                                            </div>

                                            <div className="content-dashboard-target-img">
                                                <Link to="/salesquantity">
                                                    <img src={ventasDia} alt="ventas" />
                                                </Link>
                                            </div>

                                        </div>
                                        <div id="azulB" className="content-dashboard-vermas">
                                            <Link to="/salesquantity">
                                                <h4>Ver más información <i className="fas fa-arrow-circle-right"></i></h4>
                                            </Link>
                                        </div>
                                    </div>


                                    <div id="naranjaA" className="content-dashboard-target">

                                        <div className="content-dashboard-target-info">

                                            <div className="content-info">
                                                <Link to="/managesales">
                                                    <h1>${totalV || 0}.00 MXN</h1>
                                                    <h3>ingresos del día sucursales</h3>
                                                </Link>
                                            </div>

                                            <div className="content-dashboard-target-img">
                                                <Link to="/managesales">
                                                    <img src={ventas} alt="ventas" />
                                                </Link>
                                            </div>

                                        </div>
                                        <div id="naranjaB" className="content-dashboard-vermas">
                                            <Link to="/managesales">
                                                <h4>Ver más información <i className="fas fa-arrow-circle-right"></i></h4>
                                            </Link>
                                        </div>
                                    </div>

                                    <div id="rojoA" className="content-dashboard-target">

                                        <div className="content-dashboard-target-info">

                                            <div className="content-info">
                                                <Link to="/products">
                                                    <h1>{cantCoRoot}</h1>
                                                    <h3>Comidas</h3>
                                                </Link>
                                            </div>

                                            <div className="content-dashboard-target-img">
                                                <Link to="/products">
                                                    <img src={comidas} alt="ventas" />
                                                </Link>
                                            </div>

                                        </div>
                                        <div id="rojoB" className="content-dashboard-vermas">
                                            <Link to="/products">
                                                <h4>Ver más información <i className="fas fa-arrow-circle-right"></i></h4>
                                            </Link>
                                        </div>
                                    </div>

                                    <div id="verdeA" className="content-dashboard-target">

                                        <div className="content-dashboard-target-info">

                                            <div className="content-info">
                                                <Link to="/adduser">
                                                    <h1>{cantURoot}</h1>
                                                    <h3>Usuarios</h3>
                                                </Link>
                                            </div>

                                            <div className="content-dashboard-target-img">
                                                <Link to="/adduser">
                                                    <img src={usuario} alt="ventas" />
                                                </Link>
                                            </div>

                                        </div>
                                        <div id="verdeB" className="content-dashboard-vermas">
                                            <Link to="/adduser">
                                                <h4>Ver más información <i className="fas fa-arrow-circle-right"></i></h4>
                                            </Link>
                                        </div>
                                    </div>


                                    <div id="naranjaA" className="content-dashboard-target">

                                        <div className="content-dashboard-target-info">

                                            <div className="content-info">
                                                <Link to="/drinks">
                                                    <h1>{cantBRoot}</h1>
                                                    <h3>Bebidas</h3>
                                                </Link>
                                            </div>

                                            <div className="content-dashboard-target-img">
                                                <Link to="/drinks">
                                                    <img src={bebidas} alt="ventas" />
                                                </Link>
                                            </div>

                                        </div>
                                        <div id="naranjaB" className="content-dashboard-vermas">
                                            <Link to="/drinks">
                                                <h4>Ver más información <i className="fas fa-arrow-circle-right"></i></h4>
                                            </Link>
                                        </div>
                                    </div>


                                    <div id="azulA" className="content-dashboard-target">

                                        <div className="content-dashboard-target-info">

                                            <div className="content-info">
                                                <Link to="/complements">
                                                    <h1>{cantComRoot}</h1>
                                                    <h3>Complementos</h3>
                                                </Link>
                                            </div>

                                            <div className="content-dashboard-target-img">
                                                <Link to="/complements">
                                                    <img src={complementos} alt="ventas" />
                                                </Link>
                                            </div>

                                        </div>
                                        <div id="azulB" className="content-dashboard-vermas">
                                            <Link to="/complements">
                                                <h4>Ver más información <i className="fas fa-arrow-circle-right"></i></h4>
                                            </Link>
                                        </div>
                                    </div>

                                </div>

                                :
                                ""
                    }

                    <Grafic />
                </div>


            </div>

            <Footer />
        </Fragment>
    )
}

export default Dashboard;