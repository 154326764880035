import React, { Fragment, useState, useEffect } from "react";
import "./MakeSale.css";
import Footer from '../../components/footer/Footer';
import NavBar from '../../components/navBar/NavBar';
import Asidebar from '../../components/asidebar/Asidebar';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { saveAs } from 'file-saver';
import swal from 'sweetalert';
import Cookies from 'universal-cookie';
import moment from 'moment-timezone';

const cookies = new Cookies();

const MakeSale = () => {
    const baseUrl = window.$baseUrl;
    let id_sucursal = cookies.get('id_sucursal');
    let nombre_sucursal = cookies.get('nombre_sucursal');
    let telefono_sucursal = cookies.get('telefono_sucursal');
    let direccion_sucursal = cookies.get('direccion_sucursal');

    const [menus, setMenus] = useState([]);
    const [menuBebidas, setMenuBebidas] = useState([]);
    const [menuComplementos, setMenuComplementos] = useState([]);
    const [nota, setNota] = useState("");

    const handleChangeNota = (e) => {
        setNota(e.target.value)
    }

    const findmenus = async () => {

        try {
            await axios.get(baseUrl + "/api/comida/suc/cantidad/" + id_sucursal)
                .then(response => {
                    setMenus(response.data);
                })

        } catch (e) {
            console.log(e)
        }


    }

    const findmenuBebidas = async () => {

        try {
            await axios.get(baseUrl + "/api/bebida/suc/cantidad/" + id_sucursal)
                .then(response => {
                    setMenuBebidas(response.data);
                })

        } catch (e) {
            console.log(e)
        }


    }

    const findmenuComplementos = async () => {

        try {
            await axios.get(baseUrl + "/api/complemento/suc/cantidad/" + id_sucursal)
                .then(response => {
                    setMenuComplementos(response.data);
                })

        } catch (e) {
            console.log(e)
        }


    }



    useEffect(() => {
        findmenus();
        obtenerCorteDatos();
        obtener_datos_de_corteCaja();
        obtenerCorteCaja();
    }, [])

    let id = cookies.get('id_usuario');
    let correo = cookies.get('correo');
    let permisos = cookies.get('id_permiso_usuario');
    let nombreperfil = cookies.get('nombre_completo_usuario') || "user";
    let primernombre = nombreperfil.split(" ", 1);


    let fecha_actual = moment().tz('America/Merida').format('DD/MM/YYYY');


    const [menu, setMenu] = useState(true);

    const abrirmenu = () => {
        setMenu(!menu);
    }

    const styleagrandardashboard = {
        transitionDuration: "0.3s"
    };

    const stylencogerdashboard = {};


    const [cartlist, setCartlist] = useState(false);

    const abrircartlist = () => {
        setCartlist(true);
    }


    const agregarcarrito = (productos) => {

        const produc = {
            id_compra: productos._id + productos.nombre_comida || productos.id_bebida + productos.nombre_bebida || productos.id_complemento + productos.nombre_complemento,
            nombre_producto: productos.nombre_comida + productos.presentacion_comida || productos.nombre_bebida + productos.tamano || productos.nombre_complemento,
            precio_producto: productos.precio || productos.precio_complemento,
            cantidad: 1
        };


        let compra = (produc);

        console.log(compra)

        agregar(compra);
        setClics(!clics);
        abrircartlist();

    }




    const agregar = (compra) => {

        obtener_datos_de_corteCaja();

        if (listacarrito.length === 0) {
            compra.cantidad = 0;
            listacarrito.push(compra);
            // console.log(listacarrito);

        }


        const producto = listacarrito.find(compras => compras.id_compra === compra.id_compra);

        if (producto) {
            producto.cantidad = producto.cantidad + 1;
        }
        else {
            listacarrito.push(compra);
        }

        total.push(Object.values(listacarrito).reduce((acc, { cantidad, precio_producto }) => acc + cantidad * precio_producto, 0));
    }

    const [clics, setClics] = useState(false);


    const [listacarrito, setListacarrito] = useState([]);

    const [total, setTotal] = useState([]);


    const [comidas, setComidas] = useState(true);
    const [bebidas, setBebidas] = useState(false);
    const [complementos, setComplementos] = useState(false);

    const precioTotal = total[total.length - 1];
    const [pagos, setPagos] = useState("0");

    const cambiaracomidas = () => {
        window.location.href = "makesale#comid";

        setComidas(true);
        setComplementos(false);
        setBebidas(false);

    }


    const cambiarabebidas = () => {
        window.location.href = "makesale#bebid";

        findmenuBebidas();
        setComidas(false);
        setComplementos(false);
        setBebidas(true);

    }

    const cambiaracomplementos = () => {
        window.location.href = "makesale#complement";

        findmenuComplementos();
        setBebidas(false);
        setComidas(false);
        setComplementos(true);

    }


    const styleaparecercartlist = {

    };

    const styledesaparecercartlist = {
        display: "none"
    };


    const datoscomidas = menus.length;
    const datosbebidas = menuBebidas.length;
    const datoscomplementos = menuComplementos.length;
    const vaciar_cartList = () => {
        setListacarrito([]);
        setTotal([0]);
        document.querySelector("#numbre_more").value = "";
        document.querySelector("#cambio").value = "";
        obtener_datos_de_corteCaja();
    }

    const alertFaltaPago = () => {
        swal({
            title: "Advertencia",
            text: "Es necesario ingresar la cantidad con la que pagó el cliente",
            icon: "warning",
            button: "Aceptar",
            timer: "4000"

        });
    }
    const [cargando, serCargando] = useState(null);

    const imprimirNota = async () => {

        swal({
            title: "Imprimiendo Ticket",
            text: "Espere un momento...",
            icon: "https://i.pinimg.com/originals/fe/f1/f2/fef1f20aa76540808c4f5b004bfe49bd.gif",
            buttons: false,

            // button: "Aceptar",
            // timer: "1500"

        });


        let dataTicket = {
            compra: listacarrito,
            datos: {
                sucursal: nombre_sucursal,
                folio: "xxxxxxxxxx",
                numero: telefono_sucursal,
                direccion: direccion_sucursal,
                efectivo: 0,
                cambio: 0,
                nota: nota,
            }
        }

        serCargando(true)

        try {

            await axios.post(baseUrl + '/api/reporte/create/pdf/preliminar', dataTicket)
                .then(async () => {
                    await axios.get(baseUrl + '/api/reporte/fetch-pdf-ticket/preliminar', { responseType: 'blob' })
                        .then((res) => {
                            swal.close()
                            const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
                            saveAs(pdfBlob, 'TicketPreliminar.pdf');
                        })
                        .catch((err) => {
                            swal.close()
                        })
                })
            serCargando(false)
        } catch (error) {
            console.error(error);
            serCargando(false)
        }

    }

    const terminarVenta = () => {

        if (parseFloat(document.querySelector("#cambio").value) < 0) {
            swal({
                title: "Advertencia",
                text: "No puede pagar menos del total de la venta",
                icon: "warning",
                button: "Aceptar",
                timer: "10000"
            })
            return
        } else {
            obtener_datos_de_corteCaja();
            peticionPut()


            swal({
                title: "Imprimiendo Ticket",
                text: "Espere un momento...",
                icon: "https://i.pinimg.com/originals/fe/f1/f2/fef1f20aa76540808c4f5b004bfe49bd.gif",
                buttons: false,

                // button: "Aceptar",
                // timer: "1500"

            });


            setBtnF(true)
            peticionPost();
            vaciar_cartList();
        }
    }

    const handleChange = e => {

        cambio();

        const { name, value } = e.target;
        setPagos((prevState) => ({
            ...prevState,
            [name]: value

        }))

    }



    const [btnF, setBtnF] = useState(true);


    const cambio = () => {

        if (pagos.pago === '') {
            document.querySelector("#cambio").value = 0;
            setBtnF(true)
        } else {
            if (parseInt(pagos.pago) - precioTotal < 0) {
                document.querySelector("#cambio").value = `Falta $${precioTotal - parseInt(pagos.pago)} Peso(s)`;
                setBtnF(true)
            } else if (parseInt(pagos.pago) - precioTotal === 0) {
                document.querySelector("#cambio").value = parseInt(pagos.pago) - precioTotal;
                setBtnF(false)
            }
            else {
                document.querySelector("#cambio").value = parseInt(pagos.pago) - precioTotal;
                setBtnF(false)

            }
        }

    }

    const borarItem = (i) => {

        let busqueda = JSON.stringify(i);


        let indice = listacarrito.findIndex(articulo => JSON.stringify(articulo) === busqueda);
        //    console.log(indice);


        let eliminacion = listacarrito.splice(indice, 1);
        let nuevaLista = listacarrito;

        //   console.log(nuevaLista);

        setListacarrito([...listacarrito]);
        let nPrecio = (Object.values(listacarrito).reduce((acc, { cantidad, precio_producto }) => acc + cantidad * precio_producto, 0))
        total.push(nPrecio);

        //    console.log(listacarrito);

    }



    const [corteCajaSeleccionado, setCorteCajaSeleccionado] = useState({
        ultimo_id: ""
    });

    const obtenerCorteCaja = async () => {

        try {
            await axios.get(baseUrl + "/api/corte/ultimo_corte/user/" + id)
                .then(response => {
                    setCorteCajaSeleccionado({
                        ultimo_id: response.data
                    });
                }).catch(error => {
                    // console.log("error: " + error)
                    console.log(error);
                })
        } catch (e) {
            console.log(e)
        }
    }

    // console.log(corteCajaSeleccionado)

    const peticionPost = async () => {

        let HoraActual = moment().tz('America/Merida').format();
        serCargando(true)

        try {

            await axios.post(baseUrl + "/api/venta/new/" + id_sucursal, {
                user: cookies.get('id_usuario'),
                sucursal: cookies.get('id_sucursal'),
                fecha_venta: HoraActual,
                forma_pago: "Efectivo",
                total_venta: precioTotal,
                efectivo: parseInt(pagos.pago),
                cambio: parseInt(pagos.pago) - precioTotal,
                corte: corteCajaSeleccionado.ultimo_id,
                nota: nota,
                status: true

            }).then(response => {

                try {
                    axios.get(baseUrl + "/api/venta/ultimo/v2/" + id_sucursal)
                        .then(response => {


                            let datos = response.data;

                            axios.post(baseUrl + '/api/reporte/create-pdf/' + datos.folio + "/" + datos.sucursal.name + "/" + datos.sucursal.telefono_usuario + "/" + datos.sucursal.direccion + "/" + parseInt(pagos.pago) + "/" + (parseInt(pagos.pago) - precioTotal) + "/" + nota, listacarrito)
                                .then(() => axios.get(baseUrl + '/api/reporte/fetch-pdf-ticket', { responseType: 'blob' }))
                                .then((res) => {

                                    swal.close()
                                    const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
                                    saveAs(pdfBlob, 'Ticket.pdf');

                                    axios.post(baseUrl + "/api/ticket/new", {
                                        folio: datos.folio,
                                        sucursal: id_sucursal,
                                        nota: nota,
                                        hora_actual: moment().format(),
                                        compras: listacarrito,
                                        efectivo: parseInt(pagos.pago),
                                        cambio: (parseInt(pagos.pago) - precioTotal),
                                        status: true,
                                    })
                                        .then(response => {
                                            console.log(response.data)
                                        }).catch(error => {
                                            console.log(error);
                                        })
                                })


                            setNota("")

                        }).catch(error => {
                            console.log(error);
                        })
                } catch (e) {
                    console.log(e)
                }

                altadetalleventa(response.data.id, HoraActual)

            }).catch(error => {
                console.log(error);
            })


        } catch (e) {
            console.log(e)
        }

        swal.close()
        setNota("")
        serCargando(false)
    }

    // console.log(peticionPost())

    // console.log(listacarrito)

    const altadetalleventa = async (id, HoraActual) => {

        listacarrito.forEach(element =>

            axios.post(baseUrl + "/api/detalle/new", {
                // id_detalle_venta: 0,
                nombre_producto: element.nombre_producto,
                cantidad: element.cantidad,
                precio: element.precio_producto,
                corte: corteCajaSeleccionado.ultimo_id,
                venta: id,
                fecha_venta: HoraActual,
                status: true
            }).then(response => {
                console.log(response)
            }).catch(error => {
                console.log(error);
            })


        )

    }

    const [corteCajaDatos, setCorteCajaDatos] = useState({
        id_corte_caja: "",
        inicio_caja: "",
        total_venta_periodo: "",
        fecha_venta_incio_caja: "",
        fecha_venta_cierre_caja: "",
        id_usuario: "",
        status: ""
    });

    const obtenerCorteDatos = async () => {

        try {
            await axios.get(baseUrl + "/api/corte/corr/" + correo)
                .then(response => {
                    // console.log(response.data)
                    setCorteCajaDatos(response.data);
                }).catch(error => {
                    console.log(error);
                })
        } catch (e) {
            console.log(e)
        }


    }

    // console.log(corteCajaDatos)

    const [corteCajaActualizar, setCorteCajaActualizar] = useState({
        id_corte_caja: "",
        inicio_caja: "",
        total_venta_periodo: "",
        fecha_venta_incio_caja: "",
        fecha_venta_cierre_caja: "",
        id_usuario: "",
        status: ""
    });

    const obtener_datos_de_corteCaja = async () => {

        try {
            await axios.get(baseUrl + "/api/corte/permiso/" + correo)
                .then(response => {
                    setCorteCajaActualizar(response.data);
                }).catch(error => {
                    console.log(error);
                })
        } catch (e) {
            console.log(e)
        }


    }

    // console.log(corteCajaActualizar)


    const peticionPut = async () => {

        // console.log("first")

        try {
            await axios.put(baseUrl + "/api/corte/" + corteCajaActualizar._id, {
                total_venta_periodo: corteCajaActualizar.total_venta_periodo + precioTotal,
            })
                .then(response => {
                    console.log(response.data)
                })
                .catch(error => {
                    console.log(error);
                });
        } catch (e) {
            console.log(e)
        }


    }



    return (
        <Fragment>
            <div id="arriba"></div>
            {!cookies.get('correo') && <Redirect to="./" />}
            {permisos !== "628c0a94718beafc1c5ca269" && <Redirect to="./" />}
            <NavBar estado={menu} abrirmenu={abrirmenu} primernombre={primernombre} />
            <div className="fondo-makesale">
                <Asidebar estado={menu} />
                <div style={menu ? styleagrandardashboard : stylencogerdashboard} className="content-makesale-main">


                    {Object.keys(corteCajaDatos).length <= 0 && <Redirect to="./cutbox" />}
                    <div className="content-data-makesale">
                        <label>Realizar venta</label>

                        <div className="content-menus-data">
                            <div onClick={cambiaracomidas} className="content-data-comida">
                                <h2>Comidas</h2>
                            </div>
                            <div onClick={cambiarabebidas} className="content-data-bebida">
                                <h2>Bebidas</h2>
                            </div>
                            <div onClick={cambiaracomplementos} className="content-data-complemento">
                                <h2>Complementos</h2>
                            </div>
                        </div>


                        <div className="content-data-main">

                            {comidas ?
                                <div id="comid" className="content-makesale-products">
                                    <h2 align="center">Comidas</h2>

                                    <div className="content-makesale-table">

                                        <table className="table">

                                            <thead className="thead">
                                                <tr>
                                                    {/* <th>#</th> */}
                                                    <th>Nombre</th>
                                                    <th>Presentación</th>
                                                    <th>Precio</th>
                                                    <th>Acciones</th>
                                                </tr>
                                            </thead>


                                            <tbody className="tbody">


                                                {menus.map(items =>

                                                    <tr key={items._id}>
                                                        {/* <td id="id_v">{items._id.slice(1, 3)}</td> */}
                                                        <td id="nom_v">{items.nombre_comida}</td>
                                                        <td>{items.presentacion_comida}</td>
                                                        <td>{`$${items.precio}.00`}</td>
                                                        <td>
                                                            <button onClick={() => agregarcarrito(items)} id="add" className="btn_add"><i className="fas fa-plus"></i></button>
                                                        </td>
                                                    </tr>
                                                )}

                                            </tbody>

                                        </table>

                                    </div>
                                    <br />
                                    {`Mostrando registros del 1 al ${datoscomidas} de un total de ${datoscomidas}`}
                                </div>
                                : bebidas ?

                                    <div id="bebid" className="content-makesale-products">
                                        <h2 align="center">Bebidas</h2>

                                        <div className="content-makesale-table">

                                            <table className="table">

                                                <thead className="thead">
                                                    <tr>
                                                        {/* <th>#</th> */}
                                                        <th>Nombre</th>
                                                        <th>Clasificación</th>
                                                        <th>Presentación</th>
                                                        <th>Tamaño</th>
                                                        <th>Precio</th>
                                                        <th>Acciones</th>
                                                    </tr>
                                                </thead>


                                                <tbody className="tbody">


                                                    {menuBebidas.map(items =>

                                                        <tr key={items._id}>
                                                            {/* <td id="id_v">{items._id.slice(1, 3)}</td> */}
                                                            <td id="nom_v">{items.nombre_bebida}</td>
                                                            <td>{items.clasificacion.name}</td>
                                                            <td>{items.presentacion.name}</td>
                                                            <td>{items.tamano}</td>
                                                            <td>{`$${items.precio}.00`}</td>
                                                            <td>
                                                                <button onClick={() => agregarcarrito(items)} id="add" className="btn_add"><i className="fas fa-plus"></i></button>
                                                            </td>
                                                        </tr>
                                                    )}

                                                </tbody>

                                            </table>

                                        </div>
                                        <br />
                                        {`Mostrando registros del 1 al ${datosbebidas} de un total de ${datosbebidas}`}
                                    </div>

                                    : complementos ?
                                        <div id="complement" className="content-makesale-products">
                                            <h2 align="center">Complementos extra</h2>

                                            <div className="content-makesale-table">

                                                <table className="table">

                                                    <thead className="thead">
                                                        <tr>
                                                            {/* <th>#</th> */}
                                                            <th>Nombre</th>
                                                            <th>Precio</th>
                                                            <th>Acciones</th>
                                                        </tr>
                                                    </thead>


                                                    <tbody className="tbody">


                                                        {menuComplementos.map(items =>

                                                            <tr key={items._id}>
                                                                {/* <td id="id_v">{items._id}</td> */}
                                                                <td id="nom_v">{items.nombre_complemento}</td>
                                                                <td>{`$${items.precio_complemento}.00`}</td>
                                                                <td>
                                                                    <button onClick={() => agregarcarrito(items)} id="add" className="btn_add"><i className="fas fa-plus"></i></button>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>

                                                </table>

                                            </div>
                                            <br />
                                            {`Mostrando registros del 1 al ${datoscomplementos} de un total de ${datoscomplementos}`}
                                        </div>
                                        : ""}



                            <div style={cartlist ? styleaparecercartlist : styledesaparecercartlist} className="content-makesale-description">
                                <h2 align="center">Detalles compra</h2>
                                <div className="content-makesale-cartlist">
                                    <div className="content-description-date">
                                        <i className="fas fa-calendar-day"></i><input type="text" value={fecha_actual} disabled />
                                    </div>

                                    <div className="content-description-cajero">
                                        <i className="fas fa-user"></i><input type="text" value={primernombre} disabled />
                                    </div>


                                </div>


                                <div className="content-makesale-cartlist-desc">
                                    <div className="content-detalle">
                                        <h3>Detalle</h3>
                                    </div>

                                    <div className="content-cantidad">
                                        <h3>Cant</h3>
                                    </div>

                                    <div className="content-subtotal">
                                        <h3>SubTo</h3>
                                    </div>

                                </div>
                                {listacarrito.map(items =>
                                    <div key={items.id_compra} className="content-makesale-cartlist-art">
                                        <div className="content-more">

                                            <div className="content-nom-art">
                                                <button onClick={() => borarItem(items)} id="btn_remove"><i className="fas fa-times"></i></button>
                                                <input type="text" value={items.nombre_producto} disabled />

                                            </div>

                                        </div>


                                        <div className="content-more">

                                            <div className="content-art-cantidad">
                                                <input id="numbre_moreuno" type="number" min="1" max="10" value={items.cantidad} disabled />

                                            </div>

                                        </div>

                                        <div className="content-more">
                                            <div className="content-art-subtotal">
                                                <input type="text" id="precio" value={`$${items.cantidad * items.precio_producto} MXN`} disabled />
                                            </div>

                                        </div>
                                    </div>
                                )}
                                <div className="content-makesale-cartlist-desglose">

                                    <div className="content-desglose-cantidad-products">
                                        <input type="text" value={`No° productos: ${listacarrito.length}`} disabled />

                                    </div>

                                    <div className="content-desglose-vaciar-products">
                                        <button id="btn_vaciarlista" onClick={vaciar_cartList} ><i className="far fa-trash-alt"></i> Limpiar lista</button>
                                    </div>

                                </div>


                                <div className="content-makesale-total">
                                    <h4>Total</h4>
                                    <input className="input-pago" type="text" placeholder="0000" defaultValue={precioTotal} disabled />
                                </div>


                                <div className="content-makesale-cartlist-pago">

                                    <div className="content-detalle-pago">
                                        <h4>Forma de pago</h4>
                                        <select id="select" name="select">
                                            <option value="efectivo">Efectivo</option>
                                        </select>
                                        <h4>Pagó con</h4>
                                        <input id="numbre_more" min="0" onChange={handleChange} onKeyUp={cambio} name="pago" className="input-pago" type="number" placeholder="Pagó" />
                                        <h4>Su cambio</h4>
                                        <input type="text" id="cambio" className="input-pago" onChange={cambio} placeholder="Cambio" disabled />
                                        <h4 style={{ marginTop: '5px', marginBottom: '10px' }}>Nota Venta</h4>
                                        <textarea value={nota} onChange={handleChangeNota} id="" name="nota" rows="4" ></textarea>
                                    </div>


                                </div>


                                <div className="content-makesale-cartlist-finalizar">
                                    {btnF ? <div className="content-desglose-finalizado">
                                        <button id="btn_finalizar-venta" onClick={alertFaltaPago}>Terminar venta</button>
                                        <button id="btn_finalizar-venta-preliminar" onClick={imprimirNota}>Preliminar</button>
                                    </div>
                                        :
                                        <div className="content-desglose-finalizado">
                                            <button id="btn_finalizar-venta" onClick={terminarVenta}>Terminar venta</button>
                                            <button id="btn_finalizar-venta-preliminar" onClick={imprimirNota}>Preliminar</button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
            <Footer />
        </Fragment >
    )
}

export default MakeSale;