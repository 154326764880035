import React, { Fragment, useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import "./Login.css";
import cargando from "../../images/cargando.gif";
import logo from "../../images/logo.png";
import swal from 'sweetalert';
import axios from 'axios';
import Cookies from 'universal-cookie';



const Login = () => {


  const baseUrl = window.$baseUrl;


  const cookies = new Cookies();

  const [data, setData] = useState([]);

  const peticionGet = async () => {
    await axios.get(baseUrl + "/api/cortecaja/exist/" + credenciales.email)
      .then(response => {
        setData(response.data);
      }).catch(error => {
        console.log(error);
      })
  }



  const [credenciales, setCredenciales] = useState({
    email: "",
    password: "",
  });



  const handleInputChange = (event) => {

    setCredenciales({
      ...credenciales,
      [event.target.name]: event.target.value,
    });
  };

  const [enviado, setEnviado] = useState(true);

  const iniciarSesion = async (e) => {

    e.preventDefault();

    if (credenciales.email.length <= 0) {
      swal({
        title: "¡Campo vacio!",
        text: "Ingresa tu correo electrónico",
        icon: "warning",
        button: "Aceptar",
        timer: "2000"

      });
    } else if (credenciales.password.length <= 0) {
      swal({
        title: "¡Campo vacio!",
        text: "Ingresa tu contraseña",
        icon: "warning",
        button: "Aceptar",
        timer: "2000"
      });
      setEnviado(true);
    } else {
      setEnviado(false);

      try {
        await axios.post(baseUrl + '/api/user/login', {
          correo: credenciales.email,
          contrasena: credenciales.password
        }).then(response => {

          if (response.data.ok === false) {
            setEnviado(true);
            swal({
              title: "¡Aviso!",
              text: `${response.data.msg}`,
              icon: "warning",
              button: "Aceptar",
              timer: "4000"
            });
          } else {
            setEnviado(true);

            const savedata = response.data;

            cookies.set('id_usuario', savedata.id_usuario, { path: "/" });
            cookies.set('id_sucursal', savedata.id_sucursal, { path: "/" });
            cookies.set('nombre_sucursal', savedata.nombre_sucursal, { path: "/" });
            cookies.set('telefono_sucursal', savedata.telefono_sucursal, { path: "/" });
            cookies.set('direccion_sucursal', savedata.direccion_sucursal, { path: "/" });
            cookies.set('nombre_completo_usuario', savedata.nombre_completo_usuario, { path: "/" });
            cookies.set('edad_usuario', savedata.edad_usuario, { path: "/" });
            cookies.set('telefono_usuario', savedata.telefono_usuario, { path: "/" });
            cookies.set('correo', savedata.correo, { path: "/" });
            cookies.set('nombre_cargo', savedata.nombre_cargo, { path: "/" });
            cookies.set('id_permiso_usuario', savedata.id_permiso_usuario, { path: "/" });
            cookies.set('token', savedata.token, { path: "/" });

            window.location.href = "./";
          }


        })
      } catch (error) {
        setEnviado(true);
        console.log(error.status)
        swal({
          title: "¡Aviso!",
          text: `Un error fue detectado, por favor habla con el administrador`,
          icon: "warning",
          button: "Aceptar",
          timer: "4000"
        });
        // console.log(error.response.data.msg)
        // .data.msg
      }
    }
  }


  return (
    <Fragment>

      {cookies.get('correo') && <Redirect to="./dashboard" />}
      <div className="fondo-login">
        <div className="content-login">
          <img src={logo} alt="Logo pollo rico" />
          <h1>Iniciar Sesión</h1>
          <form onSubmit={iniciarSesion}>
            <label id="label">Correo</label>
            <input
              id="email"
              name="email"
              type="email"
              placeholder="Correo electrónico"
              onChange={handleInputChange}
            // onKeyUp={() => { peticionGet(); }}
            ></input>
            <label id="label">Contraseña</label>
            <input
              id="password"
              name="password"
              type="password"
              placeholder="Contraseña"
              onChange={handleInputChange}
            ></input>
            {/* {existUser.length === 1 ?

              data === 0 ?
              ""
              : 

              <input id="money" name="money" type="number" placeholder="Dinero para iniciar caja" onChange={handleInputChange} required></input>
           
           
              :
            ""
            } */}


            {
              //   credenciales.email.length <= 0 ?
              //  ""
              //   :
              enviado ?
                <input

                  id="send"
                  type="submit"
                  value="Ingresar"
                ></input>
                :

                <img src={cargando} id="cargando" alt="Loading..." />
            }

            <Link id="reset-password" to="/ForgotPassword">
              Olvidé mi contraseña
            </Link>

          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
