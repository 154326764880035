import ReactDOM from "react-dom";
import App from "./App";
import ForgotPassword from "./views/forgotPassword/ForgotPassword";
import Dashboard from "./views/dashboard/Dashboard";
import AddUser from "./views/addUsers/AddUser";
import MakeSale from "./views/makeSale/MakeSale";
import Sales from "./views/sales/Sales";
import CutBox from "./views/cutBox/CutBox";
import Products from "./views/products/Products";
import Complements from "./views/complements/Complements";
import Drinks from "./views/drinks/Drinks";
import BranchOffice from "./views/branchOffice/BranchOffice";
import Providers from "./views/providers/Providers";
import Reports from "./views/reports/Reports";
import ReportsRoot from "./views/reportsRoot/ReportsRoot";

import Pruebas from "./views/pruebas/prueba";

import ManageSales from "./views/manageSales/ManageSales";
import SalesQuantity from "./views/salesQuantity/SalesQuantity";
import SalesQuantityAdmin from "./views/salesQuantityAdmin/SalesQuantityAdmin";


import Profile from "./views/profile/Profile";

import { BrowserRouter as Router, Route } from "react-router-dom";


//local
// window.$baseUrl = "http://192.168.1.110:4000";

//producción hosting
window.$baseUrl = "https://api-pollorico-mongo.herokuapp.com";
// window.$baseUrl = "https://backend-pollo-rico-mongodb-production.up.railway.app";



const Index = () => {

  return (

    <Router>
      <Route exact path="/" component={App} />
      <Route path="/login" component={App} />
      <Route path="/forgotpassword" component={ForgotPassword} />
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/adduser" component={AddUser} />
      <Route path="/makesale" component={MakeSale} />
      <Route path="/sales" component={Sales} />
      <Route path="/cutbox" component={CutBox} />
      <Route path="/products" component={Products} />
      <Route path="/complements" component={Complements} />
      <Route path="/drinks" component={Drinks} />
      <Route path="/branchoffice" component={BranchOffice} />
      <Route path="/reports" component={Reports} />
      <Route path="/reportsroot" component={ReportsRoot} />

      <Route path="/pruebas" component={Pruebas} />

      <Route path="/managesales" component={ManageSales} />
      <Route path="/salesquantity" component={SalesQuantity} />
      <Route path="/salesquantityadmin" component={SalesQuantityAdmin} />
      <Route path="/providers" component={Providers} />
      <Route path="/profile" component={Profile} />
    </Router>

  );
}

ReactDOM.render(<Index />, document.getElementById("root"));
