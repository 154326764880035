import React, { Fragment, useState, useEffect } from 'react';
import "./Complements.css";
import NavBar from '../../components/navBar/NavBar';
import Asidebar from '../../components/asidebar/Asidebar';
import ModalAltaComplementos from '../../components/commons/modalComplementos/ModalAltaComplementos';
import ModalEditarComplementos from '../../components/commons/modalComplementos/ModalEditarComplementos';
import Footer from '../../components/footer/Footer';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import swal from 'sweetalert';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const Complements = () => {

    const baseUrl = window.$baseUrl;

    let permisos = cookies.get('id_permiso_usuario');
    let nombreperfil = cookies.get('nombre_completo_usuario') || 'user';
    let primernombre = nombreperfil.split(" ", 1);
    let id_sucursal = cookies.get('id_sucursal');

    const [data, setData] = useState([]);
    const [complementos, setComplementos] = useState([]);
    const [modalInsertar, setModalInsertar] = useState(false);
    const [modalEditar, setModalEditar] = useState(false);
    const [modalEliminar, setModalEliminar] = useState(false);
    const [complementoSeleccionado, setComplementoSeleccionado] = useState({
        id_complemento: "",
        id_sucursal: "",
        nombre_complemento: "",
        precio_complemento: ""

    });

    const handleChange = e => {
        const { name, value } = e.target;
        setComplementoSeleccionado((prevState) => ({
            ...prevState,
            [name]: value
        }))

    }


    const abrirCerrarModalInsertar = () => {
        document.getElementById("Form-insertar").reset();
        setModalInsertar(!modalInsertar);

    }

    const abrirCerrarModalEditar = () => {
        setModalEditar(!modalEditar);
    }

    const abrirCerrarModalEliminar = () => {
        setModalEliminar(!modalEliminar);
    }

    const peticionGet = async () => {
        await axios.get(baseUrl + "/api/complemento/suc/cantidad/" + id_sucursal)
            .then(response => {
                setData(response.data);
            }).catch(error => {
                console.log(error);
            })
    }

    const peticionAllGet = async () => {
        await axios.get(baseUrl + "/api/complemento/cantidad/root")
            .then(response => {
                setComplementos(response.data);
            }).catch(error => {
                console.log(error);
            })
    }


    const peticionPost = async (e) => {
        e.preventDefault();
        if (permisos === "628c073747bc232d04b1b5f4") {
            await axios.post(baseUrl + "/api/complemento/new", {
                sucursal: id_sucursal,
                complemento: complementoSeleccionado.id_complemento,
                nombre_complemento: complementoSeleccionado.nombre_complemento,
                precio_complemento: complementoSeleccionado.precio_complemento,
                status: true
            }).then(response => {
                console.log(response);
            }).catch(error => {
                console.log(error);
            })
        } else if (permisos === "628c0ab8718beafc1c5ca26b") {
            await axios.post(baseUrl + "/api/complemento/new", {
                sucursal: complementoSeleccionado.id_sucursal,
                complemento: complementoSeleccionado.id_complemento,
                nombre_complemento: complementoSeleccionado.nombre_complemento,
                precio_complemento: complementoSeleccionado.precio_complemento,
                status: true
            }).then(response => {
                console.log(response);
            }).catch(error => {
                console.log(error);
            })
        }


        peticionGet();
        peticionAllGet();
        abrirCerrarModalInsertar();
    }

    const peticionPut = async (e) => {
        e.preventDefault();

        if (permisos === "628c073747bc232d04b1b5f4") {
            await axios.put(baseUrl + "/api/complemento/" + complementoSeleccionado._id, {

                _id: complementoSeleccionado._id,
                sucursal: id_sucursal,
                nombre_complemento: complementoSeleccionado.nombre_complemento,
                precio_complemento: complementoSeleccionado.precio_complemento
            })
                .then(response => {
                    console.log(response);
                })
                .catch(error => {
                    console.log(error);
                });
        } else if (permisos === "628c0ab8718beafc1c5ca26b") {
            await axios.put(baseUrl + "/api/complemento/" + complementoSeleccionado._id, {

                _id: complementoSeleccionado._id,
                sucursal: complementoSeleccionado.id_sucursal,
                nombre_complemento: complementoSeleccionado.nombre_complemento,
                precio_complemento: complementoSeleccionado.precio_complemento

            })
                .then(response => {
                    console.log(response);
                })
                .catch(error => {
                    console.log(error);
                });
        }


        peticionGet();
        peticionAllGet();
        abrirCerrarModalEditar();

    }


    const peticionDelete = (id) => {
        swal({
            title: "¿Estás seguro?",
            text: "¡Una vez eliminado no podrás recuperar la información!",
            icon: "warning",
            buttons: ["Cancelar", "Aceptar"],
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                    axios.put(baseUrl + '/api/complemento/delete/' + id, {

                    }).then(response => {



                        swal("El complemento fue borrado correctamente", {
                            icon: "success",
                            button: "Aceptar",
                            timer: "2000"
                        });

                        peticionGet();
                        peticionAllGet();

                    }).catch(e => {
                        console.log(e);
                    });

                }
            });

    }

    const seleccionarComplemento = (complemento, caso) => {
        window.location.href = "complements#arriba";
        setComplementoSeleccionado(complemento);

        (caso === "Editar") ?
            abrirCerrarModalEditar() :
            abrirCerrarModalEliminar()
    }


    const buscarUsuarioSucursal = async () => {
        let valor = document.getElementById("filtro").value;

        if (valor === "") {
            try {
                await axios.get(baseUrl + "/api/complemento/cantidad/root")
                    .then(response => {
                        setComplementos(response.data);
                    }).catch(error => {
                        console.log(error);
                    })
            } catch (error) {
                console.log(error)
            }

        } else {
            try {
                await axios.get(baseUrl + "/api/complemento/suc/cantidad/" + valor)
                    .then(response => {
                        setComplementos(response.data);
                    }).catch(error => {
                        console.log(error);
                    })
            } catch (error) {
                console.log(error)
            }
        }


    }


    const [sucursalSeleccionadoRoot, setSucursalSeleccionadoRoot] = useState([]);

    const peticionS_Root = async () => {
        await axios.get(baseUrl + "/api/sucursal")
            .then(response => {
                setSucursalSeleccionadoRoot(response.data);
            }).catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {
        peticionS_Root();
        peticionGet();
        peticionAllGet();
    }, [])


    const [menu, setMenu] = useState(true);

    const abrirmenu = () => {
        setMenu(!menu);

    }

    const styleagrandardashboard = {
        transitionDuration: "0.3s"
    };

    const stylencogerdashboard = {};

    const datos = data.length;
    const comp = complementos.length;


    return (
        <Fragment>
            <div id="arriba"></div>

            {!cookies.get('correo') && <Redirect to="./" />}
            {permisos === "628c0a94718beafc1c5ca269" && <Redirect to="./" />}

            {modalEditar ?
                <ModalEditarComplementos complementoSeleccionado={complementoSeleccionado} seleccionarComplemento={seleccionarComplemento} peticionPut={peticionPut} modalInsertar={modalInsertar} peticionPost={peticionPost} modalEditar={modalEditar} abrirCerrarModalEditar={abrirCerrarModalEditar} abrirCerrarModalInsertar={abrirCerrarModalInsertar} handleChange={handleChange} />
                :
                <ModalAltaComplementos complementoSeleccionado={complementoSeleccionado} seleccionarComplemento={seleccionarComplemento} peticionPut={peticionPut} modalInsertar={modalInsertar} peticionPost={peticionPost} modalEditar={modalEditar} abrirCerrarModalEditar={abrirCerrarModalEditar} abrirCerrarModalInsertar={abrirCerrarModalInsertar} handleChange={handleChange} />
            }



            <NavBar estado={menu} abrirmenu={abrirmenu} primernombre={primernombre} />

            <div className="fondo-addcomidas">

                <Asidebar estado={menu} />

                <div style={menu ? styleagrandardashboard : stylencogerdashboard} className="content-addcomidas-main">

                    <label>Administrar complementos</label>
                    <div className="btn_addcomplementos">
                        <button onClick={() => abrirCerrarModalInsertar()} id="btn_addcomplementos">Agregar complemento <i className="fas fa-plus"></i></button>
                    </div>

                    {permisos !== "628c0ab8718beafc1c5ca26b" ?
                        ""
                        :
                        <div className="select_table">

                            <select onChange={buscarUsuarioSucursal} id="filtro" name="filtro">
                                <option value="" defaultValue>Seleccione una opción</option>

                                {sucursalSeleccionadoRoot.map(sucursales =>

                                    <option key={sucursales._id} value={sucursales._id}>{sucursales.name}</option>

                                )}

                            </select>
                        </div>
                    }

                    {permisos === "628c073747bc232d04b1b5f4" ?
                        <div className="content-addcomidas-table">
                            <table className="table">
                                <thead className="thead">
                                    <tr>
                                        <th>#</th>
                                        <th>Nombre complemento</th>
                                        <th>Precio</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>

                                {datos.length < 0 ? (
                                    <tbody className="tbody">



                                    </tbody>
                                ) : (
                                    <tbody className="tbody">


                                        {data.map(complemento =>

                                            <tr id="id_comida" key={complemento._id}>
                                                <td id="prod">{complemento._id}</td>
                                                <td id="nom_prod">{complemento.nombre_complemento}</td>
                                                <td>{`$${complemento.precio_complemento}.00`}</td>
                                                <td>
                                                    <button onClick={() => seleccionarComplemento(complemento, "Editar")} id="edit" className="btn_edit"><i className="fas fa-pen"></i></button>

                                                    <button onClick={e => peticionDelete(complemento._id)} id="remove" className="btn_remove"><i className="fas fa-trash"></i></button>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                )}
                            </table>

                        </div>
                        :
                        permisos === "628c0ab8718beafc1c5ca26b" ?
                            <div className="content-addcomidas-table">
                                <table className="table">
                                    <thead className="thead">
                                        <tr>
                                            <th>#</th>
                                            <th>Nombre complemento</th>
                                            <th>Precio</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>

                                    {comp.length < 0 ? (
                                        <tbody className="tbody">



                                        </tbody>
                                    ) : (
                                        <tbody className="tbody">


                                            {complementos.map(complemento =>

                                                <tr id="id_comida" key={complemento._id}>
                                                    <td id="prod">{complemento._id}</td>
                                                    <td id="nom_prod">{complemento.nombre_complemento}</td>
                                                    <td>{`$${complemento.precio_complemento}.00`}</td>
                                                    <td>
                                                        <button onClick={() => seleccionarComplemento(complemento, "Editar")} id="edit" className="btn_edit"><i className="fas fa-pen"></i></button>

                                                        <button onClick={e => peticionDelete(complemento._id)} id="remove" className="btn_remove"><i className="fas fa-trash"></i></button>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    )}
                                </table>

                            </div>
                            :

                            ""
                    }
                    <br />

                    {permisos === "628c073747bc232d04b1b5f4" ?
                        `Mostrando registros del ${datos / datos} al ${datos} de un total de ${datos}`
                        :
                        `Mostrando registros del ${comp / comp} al ${comp} de un total de ${comp}`
                    }

                </div>

            </div>


            <Footer />

        </Fragment>
    )
}

export default Complements;